import React, { useMemo } from "react";
import { ListPage } from "../ListPage";
import { useUser } from "../../helpers/userContext";
import { useLang } from "../../helpers/language";
import parse from "html-react-parser";
import config from "../../config";

export default function SMBList() {
  const { user } = useUser();
  const { lang } = useLang();
  const columns = [
    {
      accessorFn: (row) => `${row?.name}`,
      permissionModel: "name",
      header: config.translate.name[lang],
      enableClickToCopy: true,
      grow: true,
      Cell: (
        RecievedObject,
        cell = RecievedObject.renderedCellValue,
        row = RecievedObject.row.original
      ) => {
        let result = parse(row.name ? row.name : "...");
        return result;
      },
    },
  ];
  return (
    <ListPage
      route={config.api.smb}
      query={{}}
      sorting={{ index: "asc" }}
      permissionModel={"smb"}
      uploadType={true}
      limit={null}
      columns={columns}
    />
  );
}
