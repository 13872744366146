import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { useAuth } from "../helpers/auth";
import {
  TextInput,
  PasswordInput,
  Checkbox,
  Anchor,
  Paper,
  Title,
  Text,
  Container,
  Group,
  Button,
} from "@mantine/core";
import { notifications } from "@mantine/notifications";
import config from "../config";
import { useLang } from "../helpers/language";

export default function Login() {
  let [username, setUsername] = useState("");
  let [password, setPassword] = useState("");
  let { lang } = useLang();
  const { loggedIn, login } = useAuth();
  const [open, setopen] = useState(false);

  const toggleAlert = () => {
    setopen(true);
    setTimeout(() => {
      setopen(false);
    }, 3000);
  };
  function handleUsernameChange(event) {
    setUsername(event.target.value);
  }
  function handlePasswordChange(event) {
    setPassword(event.target.value);
  }
  async function handleSubmit(event) {
    event.preventDefault();
    let result = await login(username, password);
    if (result === false) {
      notifications.show({
        title: config.translate.loginFailed[lang],
        message: config.translate.loginFailedMessage[lang],
        autoClose: 2500,
        color: "red",
      });
    } else {
      notifications.show({
        title: config.translate.loginSuccess[lang],
        message: config.translate.loginSuccessMessage[lang],
        autoClose: 2500,
      });
    }
  }

  return (
    <Container
      size={420}
      my={40}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <Title ta="center">
        <img src="/logo.svg" alt="main page logo" />
      </Title>
      <Text c="dimmed" size="sm" ta="center" mt={5}>
        {config.translate.writeUsIfThereAreAnyQuestions[lang]}{" "}
        <Anchor
          href="mainlto:support@deepmindsystems.com"
          size="sm"
          component="button"
        >
          support@deepmindsystems.com
        </Anchor>
      </Text>

      <Paper
        withBorder
        shadow="md"
        p={30}
        mt={30}
        style={{
          width: "350px",
        }}
        radius="md"
      >
        <TextInput
          value={username}
          onChange={handleUsernameChange}
          label={config.translate.username[lang]}
          placeholder="username"
          required
        />
        <PasswordInput
          label={config.translate.password[lang]}
          placeholder={config.translate.password[lang]}
          required
          mt="md"
          value={password}
          onChange={handlePasswordChange}
          visible={false}
        />
        <Button onClick={handleSubmit} fullWidth mt="xl">
          {config.translate.login[lang]}
        </Button>
      </Paper>
      <Text c="dimmed" size="sm" ta="center" mt={15}>
        {config.translate.developedBy[lang]}{" "}
        <a href="https://www.deepmindsystems.com">www.deepmindsystems.com</a>
      </Text>
    </Container>
  );
}
