import React, { useState, useEffect } from "react";
import SinglePage from "../SinglePage";
import { useParams } from "react-router-dom";
import * as API from "../../helpers/api";
import config from "../../config";
import Loading from "../modules/loading";
import { useLang } from "../../helpers/language";
import { useUser } from "../../helpers/userContext";
import NProgress from "nprogress";

export default function Pc() {
  const [isBusy, setBusy] = useState(true);
  let id = useParams().id;
  const route = config.api.pc;
  const { lang } = useLang();
  const { user } = useUser();
  const [index, setindex] = useState("");
  const [name, setName] = useState("");
  const [mac, setmac] = useState("");
  const [ip, setip] = useState("");
  const [cpu, setcpu] = useState("");
  const [gpu, setgpu] = useState("");
  const [rom, setrom] = useState("");
  const [rooms, setrooms] = useState("");
  const [room, setroom] = useState("");
  const [ram, setram] = useState("");
  const [teamviewerlogin, setteamviewerlogin] = useState("");
  const [teamviewerpassword, setteamviewerpassword] = useState("");
  const [anydesklogin, setanydesklogin] = useState("");
  const [anydeskpassword, setanydeskpassword] = useState("");
  const [connected, setconnected] = useState(["1", "2"]);
  const [image, setimage] = useState("");
  const [file, setfile] = useState("");
  const [permissions, setPermissions] = useState([]);

  const cpus = [
    {
      name: {
        en: "Intel Core i3 7100",
        ru: "Intel Core i3 7100",
        hy: "Intel Core i3 7100",
      },
    },
    {
      name: {
        en: "Intel Core i3 6006U",
        ru: "Intel Core i3 6006U",
        hy: "Intel Core i3 6006U",
      },
    },
    {
      name: {
        en: "Intel Core i5 7200",
        ru: "Intel Core i5 7200",
        hy: "Intel Core i5 7200",
      },
    },
    {
      name: {
        en: "Intel Core i5 7400",
        ru: "Intel Core i5 7400",
        hy: "Intel Core i5 7400",
      },
    },
    {
      name: {
        en: "Intel Core i5 10400",
        ru: "Intel Core i5 10400",
        hy: "Intel Core i5 10400",
      },
    },
    {
      name: {
        en: "Intel Core i7 7700k",
        ru: "Intel Core i7 7700k",
        hy: "Intel Core i7 7700k",
      },
    },
    {
      name: {
        en: "Intel Core i7 8600k",
        ru: "Intel Core i7 8600k",
        hy: "Intel Core i7 8600k",
      },
    },
    {
      name: {
        en: "Core i7 11800H",
        ru: "Core i7 11800H",
        hy: "Core i7 11800H",
      },
    },
    {
      name: {
        en: "Core i7 12700K",
        ru: "Core i7 12700K",
        hy: "Core i7 12700K",
      },
    },
    {
      name: {
        en: "ADM Ryzen 7 5600",
        ru: "ADM Ryzen 7 5600",
        hy: "ADM Ryzen 7 5600",
      },
    },
    {
      name: {
        en: "AMD Ryzen 5 4800 G",
        ru: "AMD Ryzen 5 4800 G",
        hy: "AMD Ryzen 5 4800 G",
      },
    },
    {
      name: {
        en: "AMD Ryzen 7 2700X",
        ru: "AMD Ryzen 7 2700X",
        hy: "AMD Ryzen 7 2700X",
      },
    },
    {
      name: {
        en: "AMD Ryzen 7 2700",
        ru: "AMD Ryzen 7 2700",
        hy: "AMD Ryzen 7 2700",
      },
    },
  ];
  const rams = [
    {
      name: {
        en: "2GB",
        ru: "2GB",
        hy: "2GB",
      },
    },
    {
      name: {
        en: "4GB",
        ru: "4GB",
        hy: "4GB",
      },
    },
    {
      name: {
        en: "8GB",
        ru: "8GB",
        hy: "8GB",
      },
    },
    {
      name: {
        en: "16GB",
        ru: "16GB",
        hy: "16GB",
      },
    },
    {
      name: {
        en: "32GB",
        ru: "32GB",
        hy: "32GB",
      },
    },
  ];
  const gpus = [
    {
      name: {
        en: "Nvidia GeForce GT 610",
        ru: "Nvidia GeForce GT 610",
        hy: "Nvidia GeForce GT 610",
      },
    },
    {
      name: {
        en: "Nvidia GeForce GT 710",
        ru: "Nvidia GeForce GT 710",
        hy: "Nvidia GeForce GT 710",
      },
    },
    {
      name: {
        en: "Nvidia GeForce GT 730",
        ru: "Nvidia GeForce GT 730",
        hy: "Nvidia GeForce GT 730",
      },
    },
    {
      name: {
        en: "Nvidia GeForce GTx 750 Ti",
        ru: "Nvidia GeForce GTx 750 Ti",
        hy: "Nvidia GeForce GTx 750 Ti",
      },
    },
    {
      name: {
        en: "Nvidia GeForce GTX 750",
        ru: "Nvidia GeForce GTX 750",
        hy: "Nvidia GeForce GTX 750",
      },
    },
    {
      name: {
        en: "Nvidia GeForce GTX 1050",
        ru: "Nvidia GeForce GTX 1050",
        hy: "Nvidia GeForce GTX 1050",
      },
    },
    {
      name: {
        en: "Nvidia GeForce GTX 1050 TI",
        ru: "Nvidia GeForce GTX 1050 TI",
        hy: "Nvidia GeForce GTX 1050 TI",
      },
    },
    {
      name: {
        en: "Nvidia GeForce GTX 1060",
        ru: "Nvidia GeForce GTX 1060",
        hy: "Nvidia GeForce GTX 1060",
      },
    },
    {
      name: {
        en: "Nvidia GeForce GTX 1060 TI",
        ru: "Nvidia GeForce GTX 1060 TI",
        hy: "Nvidia GeForce GTX 1060 TI",
      },
    },
    {
      name: {
        en: "Nvidia GeForce GTX 1080",
        ru: "Nvidia GeForce GTX 1080",
        hy: "Nvidia GeForce GTX 1080",
      },
    },
    {
      name: {
        en: "Nvidia GeForce GTX 1080 TI",
        ru: "Nvidia GeForce GTX 1080 TI",
        hy: "Nvidia GeForce GTX 1080 TI",
      },
    },
    {
      name: {
        en: "Nvidia GeForce GTX 1650",
        ru: "Nvidia GeForce GTX 1650",
        hy: "Nvidia GeForce GTX 1650",
      },
    },
    {
      name: {
        en: "Nvidia GeForce RTX 3050 Ti",
        ru: "Nvidia GeForce RTX 3050 Ti",
        hy: "Nvidia GeForce RTX 3050 Ti",
      },
    },
    {
      name: {
        en: "Nvidia GeForce RTX 3060",
        ru: "Nvidia GeForce RTX 3060",
        hy: "Nvidia GeForce RTX 3060",
      },
    },
    {
      name: {
        en: "Nvidia GeForce RTX 3060 Ti",
        ru: "Nvidia GeForce RTX 3060 Ti",
        hy: "Nvidia GeForce RTX 3060 Ti",
      },
    },
  ];
  const roms = [
    {
      name: {
        en: "SSD 120GB",
        ru: "SSD 120GB",
        hy: "SSD 120GB",
      },
    },
    {
      name: {
        en: "SSD 240GB",
        ru: "SSD 240GB",
        hy: "SSD 240GB",
      },
    },
    {
      name: {
        en: "SSD 500GB",
        ru: "SSD 500GB",
        hy: "SSD 500GB",
      },
    },
    {
      name: {
        en: "SSD 1TB",
        ru: "SSD 1TB",
        hy: "SSD 1TB",
      },
    },
    {
      name: {
        en: "HDD 250GB",
        ru: "HDD 250GB",
        hy: "HDD 250GB",
      },
    },
    {
      name: {
        en: "HDD 320GB",
        ru: "HDD 320GB",
        hy: "HDD 320GB",
      },
    },
    {
      name: {
        en: "HDD 500GB",
        ru: "HDD 500GB",
        hy: "HDD 500GB",
      },
    },
    {
      name: {
        en: "HDD 1TB",
        ru: "HDD 1TB",
        hy: "HDD 1TB",
      },
    },
    {
      name: {
        en: "HDD 2TB",
        ru: "HDD 2TB",
        hy: "HDD 2TB",
      },
    },
  ];

  const modelSendToServer = {
    index,
    name,
    mac,
    ip,
    cpu,
    gpu,
    ram,
    rom,
    room,
    // connected,
  };

  useEffect(() => {
    (async () => {
      NProgress.start();
      let rawRooms = await API.get(config.api.room);
      if (rawRooms) {
        setrooms(rawRooms);
      }
      if (id !== "add") {
        let currentItem = await API.get(
          route,
          {
            _id: id,
          },
          {},
          true,
          1
        );
        setindex(currentItem[0]?.index);
        setName(currentItem[0]?.name);
        setcpu(currentItem[0]?.cpu);
        setram(currentItem[0]?.ram);
        setrom(currentItem[0]?.rom);
        setroom(currentItem[0]?.room);
        setgpu(currentItem[0]?.gpu);
        setip(currentItem[0]?.ip);
        setmac(currentItem[0]?.mac);
        // setconnected(currentItem[0]?.connected);
        setimage(undefined);
        setfile(undefined);
        currentItem[0]?.uploads?.map((item) => {
          if (item.destiny === "image") setimage(item);
          if (item.destiny === "file") setfile(item);
          return item;
        });
      }
      setBusy(false);
    })();
    // eslint-disable-next-line
  }, [isBusy]);

  useEffect(() => {
    let tempPermissions = [];
    user?.role?.permissions?.forEach((permission) => {
      if (permission.name === "ticket") {
        permission.inputs.forEach((input) => {
          tempPermissions.push(input);
        });
      }
    });
    setPermissions([...tempPermissions]);
  }, []);

  if (isBusy) return <Loading />;
  else
    return (
      <SinglePage
        isBusy={isBusy}
        setBusy={setBusy}
        route={route}
        id={id}
        permissions={permissions}
        modelSendToServer={modelSendToServer}
        permissionModel="pc"
        inputs={[
          {
            value: index,
            setter: setindex,
            type: "number",
            usageType: "number",
            label: config.translate.index[lang],
            placeholder: config.translate.index[lang],
            permissionModel: "index",
          },
          {
            value: ip,
            setter: setip,
            type: "text",
            usageType: "textfield",
            label: "IP",
            placeholder: "IP",
            permissionModel: "ip",
          },
          {
            value: mac,
            setter: setmac,
            type: "text",
            usageType: "textfield",
            label: "MAC",
            placeholder: "MAC",
            permissionModel: "mac",
          },
          {
            value: cpu,
            setter: setcpu,
            optionListValue: cpus,
            type: "optionlist",
            usageType: "optionlist",
            label: "CPU",
            placeholder: "CPU",
            permissionModel: "cpu",
          },
          {
            value: gpu,
            setter: setgpu,
            optionListValue: gpus,
            type: "optionlist",
            usageType: "optionlist",
            label: "GPU",
            placeholder: "GPU",
            permissionModel: "gpu",
          },
          {
            value: ram,
            setter: setram,
            optionListValue: rams,
            type: "optionlist",
            usageType: "optionlist",
            label: "RAM",
            placeholder: "RAM",
            permissionModel: "ram",
          },
          {
            value: rom,
            setter: setrom,
            optionListValue: roms,
            type: "optionlist",
            usageType: "optionlist",
            label: "ROM",
            placeholder: "ROM",
            permissionModel: "rom",
          },
          {
            value: room,
            setter: setroom,
            optionListValue: rooms,
            type: "optionlist",
            usageType: "optionlist",
            label: config.translate.room[lang],
            permissionModel: "room",
          },
          {
            value: name,
            setter: setName,
            type: "text",
            usageType: "textfield",
            label: config.translate.name[lang],
            placeholder: config.translate.inputInLatin[lang],
            permissionModel: "name",
          },
        ]}
        filesComponent={[
          {
            destiny: "image",
            file: image,
            setFile: setimage,
            filesUploadLimit: 1,
            filesMaxSize: 5,
            adminMode: user.role !== "editor",
            height: 500,
            width: 500,
            permissionModel: "image",
          },
          {
            destiny: "file",
            file: file,
            setFile: setfile,
            filesUploadLimit: 1,
            filesMaxSize: 5,
            adminMode: user.role !== "editor",
            height: 500,
            width: 500,
            permissionModel: "file",
          },
        ]}
      />
    );
}
