import React, { useState, useEffect, useMemo } from "react";
import SinglePage from "../SinglePage";
import { useParams } from "react-router-dom";
import * as API from "../../helpers/api";
import config from "../../config";
import Loading from "../modules/loading";
import { useUser } from "../../helpers/userContext";
import moment from "moment";
import parse from "html-react-parser";
import { useLang } from "../../helpers/language";
import NProgress from "nprogress";

export default function Chat() {
  const [isBusy, setBusy] = useState(true);
  let id = useParams().id;
  const route = config.api.chat;
  const { lang } = useLang();
  const { user } = useUser();
  const [permissions, setPermissions] = useState([]);

  const [index, setindex] = useState("");
  const [users, setUsers] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [messages, setMessages] = useState([]);
  const [groupChat, setGroup] = useState(false);
  const [privateChat, setPrivate] = useState(false);

  const modelSendToServer = {
    index,
    users,
    messages,
    groupChat,
    privateChat,
  };

  useEffect(() => {
    (async () => {
      NProgress.start();
      // all users
      let rawusers = await API.get(config.api.user);
      if (rawusers) setAllUsers(rawusers);
      if (id !== "add") {
        let currentItem = await API.get(
          route,
          {
            _id: id,
          },
          null,
          null,
          null,
          ["users"]
        );
        setindex(currentItem[0]?.index);
        setMessages(currentItem[0]?.messages);
        setUsers(currentItem[0]?.users);
        setGroup(currentItem[0]?.groupChat);
        setPrivate(currentItem[0]?.privateChat);
        setAllUsers(rawusers);
      }
      setBusy(false);
    })();
    // eslint-disable-next-line
  }, [isBusy]);

  useEffect(() => {
    let tempPermissions = [];
    user?.role?.permissions?.forEach((permission) => {
      if (permission.name === "ticket") {
        permission.inputs.forEach((input) => {
          tempPermissions.push(input);
        });
      }
    });
    setPermissions([...tempPermissions]);
  }, []);

  if (isBusy) return <Loading />;
  else
    return (
      <SinglePage
        isBusy={isBusy}
        setBusy={setBusy}
        route={route}
        id={id}
        permissions={permissions}
        permissionModel="city"
        modelSendToServer={modelSendToServer}
        inputs={[
          {
            value: index,
            setter: setindex,
            type: "number",
            usageType: "number",
            label: config.translate.index[lang],
            placeholder: config.translate.index[lang],
            permissionModel: "index",
          },
          {
            value: users,
            setter: setUsers,
            type: "select",
            usageType: "select",
            label: config.translate.users[lang],
            placeholder: config.translate.users[lang],
            options: allUsers,
            permissionModel: "users",
          },
          {
            value: messages,
            setter: setMessages,
            type: "text",
            usageType: "textfield",
            label: config.translate.messages[lang],
            placeholder: config.translate.messages[lang],
            permissionModel: "messages",
          },
          {
            value: groupChat,
            setter: setGroup,
            type: "boolean",
            usageType: "boolean",
            label: config.translate.group[lang],
            placeholder: config.translate.group[lang],
            permissionModel: "groupChat",
          },
          {
            value: privateChat,
            setter: setPrivate,
            type: "boolean",
            usageType: "boolean",
            label: config.translate.private[lang],
            placeholder: config.translate.private[lang],
            permissionModel: "privateChat",
          },
        ]}
      />
    );
}
