import React, { useState, useEffect } from "react";
import StepPage from "../StepPage";
import SinglePage from "../../SinglePage";
import { useParams } from "react-router-dom";
import * as API from "../../../helpers/api";
import config from "../../../config";
import Loading from "../../modules/loading";
// import getUploads from "../../../helpers/getUploads";
import { useUser } from "../../../helpers/userContext";
import moment from "moment";
import parse from "html-react-parser";
import { useLang } from "../../../helpers/language";
import Swal from "sweetalert2";
import { IconCalendarClock, IconCalendarWeek } from "@tabler/icons-react";
import { IconTimeline } from "@tabler/icons-react";
import LoadingSceleton from "./loadingSceleton";
import { parsePhoneNumber } from "../../../helpers/parsePhoneNumber";
import { Image, useMantineColorScheme } from "@mantine/core";

export default function Step12({
  route,
  id,
  ID,
  setID,
  modelSendToServer,
  destiny,
  lang,
  history,
  step,
  UpdateStep,
  steps,
  setStep,
  localSteps,
  activeStep,
  setActiveStep,
  isBusy,
  setBusy,
  priority,
  setPriority,
  projectType,
  setProjectType,
  priorities,
  projectTypes,
  setProjectTypes,
  projectStartDate,
  setProjectStartDate,
  handleStepChange,
  projectEndDate,
  setProjectEndDate,
  voltage,
  permissions,
  openedInner,
}) {
  //! Step 12
  const [isComponentBusy, setComponentBusy] = useState(true);
  const [Id, setCurrentStepId] = useState("");
  const [currentSTEP, setCurrentSTEP] = useState({});
  const [startDate, setStartDate] = useState([]);
  const [endDate, setEndDate] = useState([]);
  const [deadline, setDeadline] = useState("");
  const [status, setStatus] = useState("notStarted");
  // queue is set automatically when sending to server
  const { user } = useUser();
  const [History, setHistory] = useState([{}]);
  const [selectedTimeInterval, setSelectedTimeInterval] = useState({});
  const [timeLineSelected, setTimeLineSelected] = useState(1);
  // upload specific variables
  const [excel, setExcel] = useState(undefined);
  const [historySteps, setHistorySteps] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [users, setUsers] = useState([]);
  const [descEn, setDescEn] = useState("");
  const [descRu, setDescRu] = useState("");
  const [descHy, setDescHy] = useState("");
  const { colorScheme } = useMantineColorScheme();

  let otherFields = {
    users: users ? users.map((item) => item._id) : [],
    desc: { en: descEn, ru: descRu, hy: descHy },
  };

  const columns = [
    {
      accessorFn: (row) =>
        `${row.firstName.en} ${row.firstName.ru} ${row.firstName.hy}`,
      header: config.translate.firstName[lang],
      filterVariant: "multi-select",
      Cell: (
        RecievedObject,
        cell = RecievedObject.renderedCellValue,
        row = RecievedObject.row.original
      ) => {
        let result = parse(row.firstName[lang] ? row.firstName[lang] : "...");
        return result;
      },
    },
    {
      accessorFn: (row) =>
        `${row.lastName.en} ${row.lastName.ru} ${row.lastName.hy}`,
      header: config.translate.lastName[lang],
      filterVariant: "multi-select",
      Cell: (
        RecievedObject,
        cell = RecievedObject.renderedCellValue,
        row = RecievedObject.row.original
      ) => {
        let result = parse(row.lastName[lang] ? row.lastName[lang] : "...");
        return result;
      },
    },
    {
      accessorKey: "phone",
      enableColumnActions: false,
      enableColumnDragging: false,
      header: config.translate.more[lang],
      Cell: (
        RecievedObject,
        cell = RecievedObject.renderedCellValue,
        row = RecievedObject.row.original
      ) => {
        let result = "";
        if (row.phone && row.phone.length > 0) {
          result = (
            <div
              className="icon-comment-wrapper"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <a href={`tel:${parsePhoneNumber(row.phone)}`}>
                <Image
                  w="20"
                  h="20"
                  className="icon-comment"
                  src="/assets/phone-call-svgrepo-com.svg"
                  alt="phone"
                  style={{
                    filter: colorScheme === "dark" ? "invert(1)" : "",
                  }}
                />
              </a>
              <a href={`mailto:${row.email}`}>
                <Image
                  w="20"
                  h="20"
                  className="icon-comment"
                  src="/assets/email-svgrepo-com.svg"
                  alt="email"
                  style={{
                    filter: colorScheme === "dark" ? "invert(1)" : "",
                  }}
                />
              </a>
              <a
                href={`https://chat.apit.am/direct/${row.chatID}`}
                target="_blank"
                rel="noreferrer"
              >
                <Image
                  w="20"
                  h="20"
                  className="icon-comment"
                  src="/assets/message-circle-chat-svgrepo-com.svg"
                  alt="chat"
                  style={{
                    filter: colorScheme === "dark" ? "invert(1)" : "",
                  }}
                />
              </a>
            </div>
          );
        }
        return result;
      },
    },
  ];

  const setUploadsByTimeInterval = async (item) => {
    if (item.destiny === "excel") {
      if (
        moment(item.date) > moment(selectedTimeInterval.startDate) &&
        moment(item.date) < moment(selectedTimeInterval.endDate)
      ) {
        setExcel(item);
      }
    }
    return item;
  };

  useEffect(() => {
    (async () => {
      const usersQuery = voltage?.users
        ?.filter((items) => items.step === step)
        .flatMap((item) => item.users)
        .map((item) => ({ _id: item }));

      if (usersQuery && usersQuery?.length > 0) {
        let users = await API.get(config.api.user, {
          $or: usersQuery,
        });
        setAllUsers(users);
      }

      let currentStep = await API.get(
        config.api.step,
        {
          project_id: id,
          queue: step,
        },
        {},
        true,
        1,
        ["users"]
      );
      // set all states from server
      currentStep[0] && setCurrentSTEP(currentStep[0]);
      currentStep[0]?._id && setCurrentStepId(currentStep[0]?._id);
      currentStep[0]?.startDate && setStartDate(currentStep[0]?.startDate);
      currentStep[0]?.endDate && setEndDate(currentStep[0]?.endDate);
      currentStep[0]?.status && setStatus(currentStep[0]?.status);
      currentStep[0]?.history && setHistory(currentStep[0]?.history);
      currentStep[0]?.desc && setDescEn(currentStep[0]?.desc.en);
      currentStep[0]?.desc && setDescRu(currentStep[0]?.desc.ru);
      currentStep[0]?.desc && setDescHy(currentStep[0]?.desc.hy);
      currentStep[0]?.users && setUsers(currentStep[0]?.users);

      let historySteps = [];
      if (currentStep[0]?.history) {
        for (let i = 0; i < currentStep[0]?.history.length; i++) {
          if (currentStep[0]?.history[i].status === "inProgress") {
            // historySteps.push(currentStep[0]?.history[i]);
            historySteps.push({
              startDate: currentStep[0]?.history?.[i].date,
              endDate: currentStep[0]?.history?.[i + 1]?.date,
              changes: {},
            });
          }
        }
      }

      setHistorySteps(historySteps);
      setTimeLineSelected(historySteps.length - 1);
      setSelectedTimeInterval({
        startDate: historySteps[historySteps.length - 1]?.startDate,
        endDate: historySteps[historySteps.length - 1]?.endDate,
      });

      setExcel(undefined);
      currentStep[0]?.uploads?.map((item) => {
        setUploadsByTimeInterval(item);
      });

      setDeadline(voltage?.deadline?.step12);

      // eslint-disable-next-line
      setBusy(false);
      setComponentBusy(false);
    })();
    // eslint-disable-next-line
  }, [isComponentBusy]);

  useEffect(() => {
    // change deafault files on selectedTimeinterval change
    setExcel(undefined);
    currentSTEP?.uploads?.map((item) => {
      setUploadsByTimeInterval(item);
    });
  }, [selectedTimeInterval]);

  if (isComponentBusy) return <LoadingSceleton />;
  return (
    <StepPage
      isBusy={isBusy}
      setBusy={setBusy}
      route={route}
      projectId={id}
      stepId={Id}
      handleStepChange={handleStepChange}
      lang={lang}
      History={History}
      modelSendToServer={modelSendToServer}
      pageTitle={localSteps?.[step - 1]?.name[lang]}
      step={step}
      UpdateStep={UpdateStep}
      activeStep={activeStep}
      setActiveStep={setActiveStep}
      steps={steps}
      setStep={setStep}
      localSteps={localSteps}
      startDate={startDate}
      endDate={endDate}
      selectedTimeInterval={selectedTimeInterval}
      setSelectedTimeInterval={setSelectedTimeInterval}
      timeLineSelected={timeLineSelected}
      setTimeLineSelected={setTimeLineSelected}
      historySteps={historySteps}
      otherFields={otherFields}
      permissions={permissions}
      setComponentBusy={setComponentBusy}
      isComponentBusy={isComponentBusy}
      openedInner={openedInner}
      inputs={[
        historySteps[0]?.startDate && {
          value: moment(historySteps[0]?.startDate).format(
            "DD-MM-YYYY, HH:mm:ss"
          ),
          type: "text",
          usageType: "textfield",
          label: config.translate.startDate[lang],
          permissionModel: "step10-startDate",
          disabled: true,
          rightIcon: <IconTimeline />,
        },
        historySteps[0]?.startDate &&
        historySteps[historySteps.length - 1]?.endDate
          ? {
              value:
                "It lasts " +
                moment(historySteps[historySteps.length - 1]?.endDate).diff(
                  historySteps[historySteps.length - 1]?.startDate,
                  "minutes"
                ) +
                " working minutes",
              type: "text",
              permissionModel: "step10-processTime",
              usageType: "textfield",
              label: config.translate.processTime[lang],
              disabled: true,
              rightIcon: <IconCalendarClock />,
            }
          : {
              value:
                "Still going on " +
                moment(new Date()).diff(
                  historySteps[historySteps.length - 1]?.startDate,
                  "minutes"
                ) +
                " working minutes",
              type: "text",
              usageType: "textfield",
              label: config.translate.processTime[lang],
              disabled: true,
              rightIcon: <IconCalendarClock />,
            },
        historySteps[historySteps.length - 1]?.endDate && {
          value: moment(historySteps[historySteps.length - 1]?.endDate).format(
            "DD-MM-YYYY, HH:mm:ss"
          ),
          type: "text",
          usageType: "textfield",
          permissionModel: "step10-endDate",
          label: config.translate.endDate[lang],
          disabled: true,
          rightIcon: <IconCalendarClock />,
        },
        deadline && {
          value: moment(historySteps[0]?.startDate)
            .add(deadline, "days")
            .format("DD-MM-YYYY"),
          type: "text",
          usageType: "textfield",
          permissionModel: "step10-deadline",
          label: config.translate.deadline[lang],
          disabled: true,
          rightIcon: <IconCalendarWeek />,
        },
        {
          value: descEn,
          setter: setDescEn,
          type: "text",
          usageType: "textarea-simple",
          label: config.translate.description[lang],
          placeholder: config.translate.inputInLatin[lang],
          permissionModel: "step10-desc",
          lang: "en",
          width: 14,
        },
        {
          value: descRu,
          setter: setDescRu,
          type: "text",
          usageType: "textarea-simple",
          label: config.translate.description[lang],
          placeholder: config.translate.inputInRussian[lang],
          permissionModel: "step10-desc",
          lang: "ru",
          width: 14,
        },
        {
          value: descHy,
          setter: setDescHy,
          type: "text",
          usageType: "textarea-simple",
          label: config.translate.description[lang],
          placeholder: config.translate.inputInArmenian[lang],
          permissionModel: "step10-desc",
          lang: "hy",
          width: 14,
        },
      ]}
      filesComponent={[
        {
          destiny: "excel",
          file: excel,
          setFile: setExcel,
          filesUploadLimit: 1,
          filesMaxSize: 5,
          permissionModel: "step10-excel",
          acceptedFiles: [
            "application/vnd.ms-excel",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          ],
          adminMode: user.role !== "editor",
          height: 500,
        },
      ]}
      cartComponent={[
        {
          placeholder:
            config.translate.users[lang] +
            " - " +
            config.steps[step - 1].name[lang],
          value: users,
          setter: setUsers,
          data: allUsers,
          columns: columns,
          permissionModel: "step10-users",
          width: "220px",
        },
      ]}
    />
  );
}
