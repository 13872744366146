import axios from "axios";
import { cookies } from "./cookies";
import config from "../config";
import "axios-progress-bar/dist/nprogress.css";
import md5 from "md5";
import NProgress from "nprogress";

const API = axios.create({
  baseURL: `${config.api.API_URL}/${config.api.API_Version}/`,
  maxContentLength: 1000000000,
  maxBodyLength: 10000000000,
  timeout: 10000,
});

const settings = {
  headers: {
    "private-token": cookies.get(config.authentification.tokenAddress),
  },
};

function animateValue(setProgress = () => {}, start, end, duration) {
  let startTimestamp = null;
  const step = (timestamp) => {
    if (!startTimestamp) startTimestamp = timestamp;
    const progress = Math.min((timestamp - startTimestamp) / duration, 1);
    setProgress(Math.floor(progress * (end - start) + start));
    if (progress < 1) {
      window.requestAnimationFrame(step);
    }
  };
  window.requestAnimationFrame(step);
}

export function get(
  route,
  query,
  sort,
  uploadType,
  limit,
  populate,
  skip,
  count = false
) {
  settings.headers["Content-Type"] = "application/json";
  settings.headers["private-token"] = cookies.get(
    config.authentification.tokenAddress
  );
  return new Promise((resolve, reject) => {
    let Query = { ...query };
    if (Object.values(Query).includes("temprorary")) {
    } else {
      Query.temprorary = { $ne: true };
    }
    let completeRoute = `${route}${Query ? `?q=${JSON.stringify(Query)}` : ""}${
      sort ? `&sort=${JSON.stringify(sort)}` : ""
    }${uploadType ? `&uploadType=${JSON.stringify(uploadType)}` : ""}${
      skip ? `&skip=${JSON.stringify(skip)}` : ""
    }${limit ? `&limit=${JSON.stringify(limit)}` : ""}${
      populate ? `&populate=${JSON.stringify(populate)}` : ""
    }${count ? `&count=${JSON.stringify(count)}` : ""}`;
    if (route && completeRoute && completeRoute.length !== 0) {
      API.get(completeRoute, settings)
        .then(({ data }) => {
          NProgress.done();
          resolve(data);
        })
        .catch((error) => reject(error));
    }
  });
}
export function post(route, data) {
  settings.headers["Content-Type"] = "application/json";
  settings.headers["private-token"] = cookies.get(
    config.authentification.tokenAddress
  );
  return new Promise((resolve, reject) => {
    API.post(route, data, settings)
      .then(({ data }) => resolve(data))
      .catch((error) => reject(error));
  });
}
export function update(route, id, data) {
  settings.headers["Content-Type"] = "application/json";
  settings.headers["private-token"] = cookies.get(
    config.authentification.tokenAddress
  );
  return new Promise((resolve, reject) => {
    API.put(`${route}/${id}`, data, settings)
      .then(({ data }) => resolve(data))
      .catch((error) => reject(error));
  });
}
export function remove(route, id) {
  settings.headers["Content-Type"] = "application/json";
  settings.headers["private-token"] = cookies.get(
    config.authentification.tokenAddress
  );
  return new Promise((resolve, reject) => {
    API.delete(`${route}/${id}`, settings)
      .then(({ data }) => resolve(data))
      .catch((error) => reject(error));
  });
}
export const upload = (
  id,
  files,
  lang,
  destiny,
  thumbSizeWidth,
  thumbSizeHeight,
  responsiveImages,
  alt,
  setProgress = () => {},
  date = new Date()
) => {
  let CHUNK_SIZE = 0; // 1MB chunk size (adjust as needed)
  settings.headers["private-token"] = cookies.get(
    config.authentification.tokenAddress
  );
  settings.headers["Content-Type"] = "multipart/form-data";
  return new Promise(async (resolve, reject) => {
    let information,
      BREAK,
      counter = 0;
    if (files?.length > 0) {
      while (counter < files?.length && !BREAK) {
        let file = files[counter];
        CHUNK_SIZE = Math.ceil(file.size / 10);
        const totalChunks = Math.ceil(file.size / CHUNK_SIZE);
        settings.headers["x-total-chunks"] = totalChunks;
        const formData = new FormData();
        formData.append("obj_id", id);
        formData.append("lang", lang);
        formData.append("destiny", destiny);
        formData.append("type", file.type);
        formData.append("date", file.date ? file.date : date);
        formData.append("alt", alt);
        formData.append(
          "name",
          file.name ? file.name.slice(0, file.name.length - 4) : null
        );
        formData.append(
          "extension",
          file.name
            ? file.name.slice(file.name.length - 4, file.name.length)
            : null
        );
        formData.append("thumbSizeWidth", thumbSizeWidth);
        formData.append("thumbSizeHeight", thumbSizeHeight);
        formData.append("responsiveImages", responsiveImages);

        function pause(milliseconds) {
          return new Promise((resolve3) => setTimeout(resolve3, milliseconds));
        }

        let chunkNumber = 0;
        let Break = false;

        while (chunkNumber < totalChunks && !Break) {
          const start = Math.ceil(chunkNumber * CHUNK_SIZE);
          const end = Math.ceil(start + CHUNK_SIZE);
          const chunk = file.slice(start, end);

          animateValue(
            setProgress,
            parseInt((100 * Number(chunkNumber)) / Number(totalChunks)),
            parseInt((100 * (Number(chunkNumber) + 1)) / Number(totalChunks)),
            100
          );

          settings.headers["x-current-chunk"] = chunkNumber;
          formData.append("chunks", chunk, `chunk_${chunkNumber}`);
          // eslint-disable-next-line no-loop-func
          await new Promise((resolve2, reject2) => {
            API.post(config.api.upload, formData, settings)
              .then(({ data }) => {
                if (data?.message && data?.message === "#39685") {
                  information = data.upload;
                  resolve2(information);
                  Break = true;
                } else {
                  information = data;
                  resolve2(data);
                }
              })
              .catch((error) => {
                reject(error);
                reject2(error);
              });
          });
          ++chunkNumber;
          // await pause(1); // Pause for 1 second (1000 milliseconds)
        }
        ++counter;
      }
      resolve(information);
    }
  });
};

export default API;
