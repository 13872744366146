import React, { useState, useEffect, useMemo } from "react";
import SinglePage from "../SinglePage";
import { useParams } from "react-router-dom";
import * as API from "../../helpers/api";
import config from "../../config";
import Loading from "../modules/loading";
import parse from "html-react-parser";
import { useLang } from "../../helpers/language";
import { Image } from "@mantine/core";
import {
  TransformWrapper,
  TransformComponent,
  useControls,
} from "react-zoom-pan-pinch";

export default function OfficeMap() {
  const Controls = () => {
    const { zoomIn, zoomOut, resetTransform } = useControls();
    return (
      <>
        <button onClick={() => zoomIn()}>Zoom In</button>
        <button onClick={() => zoomOut()}>Zoom Out</button>
        <button onClick={() => resetTransform()}>Reset</button>
      </>
    );
  };

  return (
    <TransformWrapper>
      {/* <Controls /> */}
      <TransformComponent>
        <Image radius="md" src="/assets/office-map.jpg" alt="office-map" />;
      </TransformComponent>
    </TransformWrapper>
  );
}
