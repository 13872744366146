import React, { useState, useEffect, Suspense } from "react";
import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import "mantine-react-table/styles.css"; //import MRT styles
import { createTheme, MantineProvider } from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import "@mantine/notifications/styles.css";

// Helpers
import history from "./helpers/history";
import { cookies, setCookies } from "./helpers/cookies";
import { isLoggedIn } from "./helpers/auth";
import { UserContext } from "./helpers/userContext";
import { ConnectedUserContext } from "./helpers/connectedUserContext";
import API from "./helpers/api";
import { useStateWithLocalStorage } from "./helpers/storage";
import config from "./config";
import Loading from "./components/modules/loading.jsx";
import { io } from "socket.io-client";
import { Language } from "./helpers/language";
import { PermissionContext } from "./helpers/permissionsContext.js";

// global components
import Login from "./components/login";
import Main from "./components/main/main";

//Routing
import { Router, Route, Switch } from "react-router-dom";

// Components
import Home from "./components/home";
import Page404 from "./components/Page404";
import Branch from "./components/branch/branch";
import BranchList from "./components/branch/branchList";
import City from "./components/city/city";
import CityList from "./components/city/cityList";
import Voltage from "./components/voltage/voltage";
import VoltageList from "./components/voltage/voltageList";
import ProjectPage from "./components/project/projectPage";
import ProjectList from "./components/project/projectList";
import ProjectAdd from "./components/project/projectAdd";
import ProjectType from "./components/project-type/projectType";
import ProjectTypeList from "./components/project-type/projectTypeList";
import UserPage from "./components/user/user";
import Users from "./components/user/users";
import Chat from "./components/chat/chat";
import ChatList from "./components/chat/chatList";
import Department from "./components/department/department";
import Departments from "./components/department/departments";
import Position from "./components/position/position";
import Positions from "./components/position/positions";
import Status from "./components/status/status";
import Statuses from "./components/status/statuses";
import Ticket from "./components/ticket/ticket";
import Tickets from "./components/ticket/tickets";
import PC from "./components/pc/pc";
import PCs from "./components/pc/pcs";
import RouTer from "./components/router/router";
import RouTers from "./components/router/routers";
import Switcher from "./components/switch/switch";
import Switchs from "./components/switch/switchs";
import Room from "./components/room/room";
import Rooms from "./components/room/rooms";
import Monitor from "./components/monitor/monitor";
import Monitors from "./components/monitor/monitors";
import Printer from "./components/printer/printer";
import Printers from "./components/printer/printers";
import SMB from "./components/smb/smb";
import OfficeMap from "./components/office-map/office_map";
import SMBList from "./components/smb/smbList";
import Software from "./components/software/software";
import Softwares from "./components/software/softwares";
import Problem from "./components/problem/problem";
import Problems from "./components/problem/problems";
import Role from "./components/role/role";
import Roles from "./components/role/roles";
import Permissions from "./components/permissions/permissions";

function RouterComponent({ user }) {
  if (user) {
    return (
      <Suspense fallback={<Loading />}>
        <Switch>
          <Route exact path="/home" component={Home} />
          <Route exact path="/ticket" component={Tickets} />
          <Route exact path="/ticket/:id" component={Ticket} />
          <Route exact path="/projects" component={ProjectList} />
          <Route path="/project/edit/" component={ProjectPage} />
          <Route path="/project/add/" component={ProjectAdd} />
          <Route exact path="/branch" component={BranchList} />
          <Route exact path="/branch/:id" component={Branch} />
          <Route exact path="/city" component={CityList} />
          <Route exact path="/city/:id" component={City} />
          <Route exact path="/chat" component={ChatList} />
          <Route exact path="/chat/:id" component={Chat} />
          <Route exact path="/voltage" component={VoltageList} />
          <Route exact path="/voltage/:id" component={Voltage} />
          <Route exact path="/project-type" component={ProjectTypeList} />
          <Route exact path="/project-type/:id" component={ProjectType} />
          <Route exact path="/users/:userWorkingStatus?" component={Users} />
          <Route exact path="/user/:id" component={UserPage} />
          <Route exact path="/department" component={Departments} />
          <Route exact path="/department/:id" component={Department} />
          <Route exact path="/position" component={Positions} />
          <Route exact path="/position/:id" component={Position} />
          <Route exact path="/status" component={Statuses} />
          <Route exact path="/status/:id" component={Status} />
          <Route exact path="/pc/" component={PCs} />
          <Route exact path="/pc/:id" component={PC} />
          <Route exact path="/router" component={RouTers} />
          <Route exact path="/router/:id" component={RouTer} />
          <Route exact path="/switch" component={Switchs} />
          <Route exact path="/switch/:id" component={Switcher} />
          <Route exact path="/monitor" component={Monitors} />
          <Route exact path="/monitor/:id" component={Monitor} />
          <Route exact path="/printer" component={Printers} />
          <Route exact path="/printer/:id" component={Printer} />
          <Route exact path="/office-map" component={OfficeMap} />
          <Route exact path="/smb" component={SMBList} />
          <Route exact path="/smb/:id" component={SMB} />
          <Route exact path="/software" component={Softwares} />
          <Route exact path="/software/:id" component={Software} />
          <Route exact path="/problem" component={Problems} />
          <Route exact path="/problem/:id" component={Problem} />
          <Route exact path="/room" component={Rooms} />
          <Route exact path="/room/:id" component={Room} />
          <Route exact path="/role" component={Roles} />
          <Route exact path="/role/:id" component={Role} />
          <Route exact path="/permission" component={Permissions} />
        </Switch>
      </Suspense>
    );
  } else {
    return (
      <Suspense>
        <Route path="*" component={Page404} />
      </Suspense>
    );
  }
}

const theme = createTheme(
  {
    shadows: {
      md: "1px 1px 3px rgba(0, 0, 0, .25)",
      xl: "5px 5px 3px rgba(0, 0, 0, .25)",
    },
    primaryColor: "blue",
    fontFamily: "Roboto, sans-serif",
  },
  {
    links: {
      textDecoration: "none",
      color: "inherit",
    },
    a: {
      textDecoration: "none",
      color: "inherit",
    },
  }
);

function App() {
  let [loggedIn, setLoggedIn] = useState(Boolean);
  let [user, setUser] = useState(Boolean);
  const [permissions, setPermissions] = useState([]);
  let [lang, setLang] = useStateWithLocalStorage("language");
  const [colorScheme, setColorScheme] = useStateWithLocalStorage(
    "mantine-color-scheme-value"
  );

  let [connectedUsers, setconnectedUsers] = useState([]);
  let [notifications, setnotifications] = useState([]);
  let [socket, setSocket] = useState(null);

  function ConnectSocket(userFromServer) {
    const socket = io(`${config.api.API_URL}`, {
      transports: ["websocket"],
    });
    setSocket(socket);
    let user = JSON.parse(JSON.stringify(userFromServer));
    socket.on("connect_error", () => {
      setTimeout(() => {
        socket.connect();
      }, 1000);
    });
    socket.on("connect", () => {
      user.socket_id = socket.id;
      socket.emit("registerUser", user);
      setUser(user);
    });
    // socket.on("disconnect", () => {
    //   setconnectedUsers(connectedUsers);
    // });
    // setUser(user);

    socket.on("GetMyNotifications", (notifications) => {
      console.log("🚀 ~ socket.on ~ notifications:", notifications);
      setnotifications(JSON.parse(notifications));
      // showNotification();
    });
    socket.on("createNotification", (notifications) => {
      let Notifications = JSON.parse(notifications);
      let myCurrentNotifications = Notifications.filter((notification) => {
        if (user.role === "admin") {
          return notification.user?._id !== user._id;
        }
      });
      setnotifications(myCurrentNotifications);
    });
    socket.on("updateNotification", (notifications) => {
      let Notifications = JSON.parse(notifications);
      let myCurrentNotifications = Notifications.filter((notification) => {
        if (user.role === "admin") {
          // return notification.admin?._id === user._id;
        } else {
          return notification.user?._id === user._id;
        }
      });
      setnotifications(myCurrentNotifications);
    });

    socket.on("getConnectedUsers", (users) => {
      setconnectedUsers(users);
    });
  }

  const DissconnectSocket = () => {
    console.log("DissconnectSocket");
    socket.disconnect();
  };

  function logout() {
    setCookies("");
    setconnectedUsers("");
    setLoggedIn(false);
    cookies.remove("authorization");
    if (socket) DissconnectSocket();
  }

  function login(username, password) {
    if (username && password) {
      return API.post(config.authentification.login, {
        username,
        password,
      })
        .then((res) => {
          if (res.status === 200) {
            setCookies(res.data.token);
            setLoggedIn(true);
            setUser(res.data.user);
            ConnectSocket(res.data.user);
            setnotifications(res.data.user.notification);
            setPermissions(res.data.permissions);
            console.log("🚀 ~ .then ~ res.data.user:", res.data.user);
          }
        })
        .catch((error) => {
          console.error(error);
          logout();
          return false;
        });
    } else {
      logout();
      console.log("There is no user with provided username & password");
    }
  }

  async function checkToken() {
    let token = await cookies.get(config.authentification.tokenAddress);
    if (token) {
      API.post(config.authentification.verify, {
        token,
      })
        .then((res) => {
          if (res.status === 200) {
            setCookies(token);
            setLoggedIn(true);
            setUser(res.data.user);
            ConnectSocket(res.data.user);
            setnotifications(res.data.user.notification);
            setPermissions(res.data.permissions);
          } else {
            setLoggedIn(false);
          }
        })
        .catch((res) => {
          console.error(res);
          console.error(`Please don't edit cookies manually`);
          logout();
        });
    } else {
      logout();
      console.log(
        "You must provide token for calling login -> veryfyToken functions, Or mongodb database is not running"
      );
      return false;
    }
  }

  const showNotification = () => {
    // create a new notification
    const notification = new Notification("JavaScript Notification API", {
      body: "This is a JavaScript Notification API demo",
      icon: "/assets/black.png",
      vibrate: true,
    });

    // close the notification after 10 seconds
    setTimeout(() => {
      notification.close();
    }, 10 * 1000);

    // history to a URL
    notification.addEventListener("click", () => {
      window.open(
        "https://www.javascripttutorial.net/web-apis/javascript-notification/",
        "_blank"
      );
    });
  };

  let granted = false;
  // show an error message
  const showError = () => {
    const error = document.querySelector(".error");
    if (error) {
      error.style.display = "block";
      error.textContent = "You blocked the notifications";
    }
  };

  useEffect(() => {
    (async () => {
      if (lang === "" || lang === null) {
        setLang("en");
      }
      if (colorScheme === "" || colorScheme === null) {
        setColorScheme("light");
      }
      await checkToken();

      if (Notification.permission === "granted") {
        granted = true;
      } else if (Notification.permission !== "denied") {
        let permission = await Notification.requestPermission();
        granted = permission === "granted" ? true : false;
      }
      granted ? showNotification() : showError();
      // eslint-disable-next-line
    })();
  }, []);

  if (loggedIn && user) {
    return (
      <PermissionContext.Provider value={{ permissions, setPermissions }}>
        <Language.Provider
          value={{ lang, setLang, colorScheme, setColorScheme }}
        >
          <isLoggedIn.Provider value={{ login, logout, loggedIn }}>
            <UserContext.Provider value={{ user, setUser }}>
              <ConnectedUserContext.Provider
                value={{
                  connectedUsers,
                  socket,
                  notifications,
                  setnotifications,
                }}
              >
                <MantineProvider theme={theme} defaultColorScheme="dark">
                  <Notifications position="top-right" zIndex={1000} />
                  <Router history={history}>
                    <Main component={RouterComponent} />
                  </Router>
                </MantineProvider>
              </ConnectedUserContext.Provider>
            </UserContext.Provider>
          </isLoggedIn.Provider>
        </Language.Provider>
      </PermissionContext.Provider>
    );
  } else {
    return (
      <Language.Provider value={{ lang, setLang }}>
        <isLoggedIn.Provider value={{ login, logout, loggedIn }}>
          <MantineProvider theme={theme} defaultColorScheme="dark">
            <Notifications position="top-right" zIndex={1000} />
            <Router history={history}>
              <Route component={Login} />
            </Router>
          </MantineProvider>
        </isLoggedIn.Provider>
      </Language.Provider>
    );
  }
}

export default App;
