import React, { useEffect, useState } from "react";
import { Breadcrumbs } from "@mantine/core";
import { useLocation, Link } from "react-router-dom";
import * as API from "../../helpers/api";
import config from "../../config";
import Loading from "../modules/loading";

export function BreadcrumbsComponent() {
  let location = useLocation();
  let [isBusy, setIsBusy] = useState(true);
  let [crumbs, setCrumbs] = useState([]);

  useEffect(() => {
    (async () => {
      let crumbs = location.pathname.split("/").slice(1);
      let result;
      if(crumbs[0] === 'project'){
        result = await API.get(
          config.api[crumbs[0]],
          { _id: crumbs[2] },
          null,
          null,
          1
        );
      }
      else if (crumbs.length > 1) {
        result = await API.get(
          config.api[crumbs[0]],
          { _id: crumbs[1] },
          null,
          null,
          1
        );
      }
      if (result?.length > 0) {
        crumbs[crumbs.length - 1] = result[0].name
          ? result[0].name?.en
          : result[0].title
          ? result[0].title?.en
          : result[0].index
          ? result[0].index
          : result[0].ID
          ? result[0].ID
          : result[0]._id;
        setCrumbs(crumbs);
      } else {
        setCrumbs(crumbs);
      }
      setIsBusy(false);
    })();
    // eslint-disable-next-line
  }, [isBusy, location.pathname]);

  if (isBusy) {
    return <Loading />;
  } else return <Breadcrumbs>{crumbs}</Breadcrumbs>;
}
