import React, { useMemo } from "react";
import { ListPage } from "../ListPage";
import config from "../../config";
import { useUser } from "../../helpers/userContext";
import { useLang } from "../../helpers/language";
import parse from "html-react-parser";
export default function Printers() {
  const { user } = useUser();
  const { lang } = useLang();
  const columns = [
    {
      accessorFn: (row) => `${row.name}`,
      permissionModel: "name",
      header: config.translate.name[lang],
      enableClickToCopy: true,
      Cell: (
        RecievedObject,
        cell = RecievedObject.renderedCellValue,
        row = RecievedObject.row.original
      ) => {
        let result = parse(row.name ? row.name : "...");
        return result.length > 20 ? result.slice(0, 20) + "..." : result;
      },
    },
    {
      accessorFn: (row) => `${row?.pc?.name}`,
      permissionModel: "pc",
      header: config.translate.pc[lang],
      size: 50,
      Cell: (
        RecievedObject,
        cell = RecievedObject.renderedCellValue,
        row = RecievedObject.row.original
      ) => {
        let result = parse(row?.pc?.name ? row.pc.name : "...");
        return result.length > 20 ? result.slice(0, 20) + "..." : result;
      },
    },
    {
      accessorKey: "ip",
      permissionModel: "ip",
      header: config.translate.ip[lang],
      enableClickToCopy: true,
      size: 130,
      Cell: (
        RecievedObject,
        cell = RecievedObject.renderedCellValue,
        row = RecievedObject.row.original
      ) => {
        let result = parse(row?.ip ? row?.ip : "...");
        return result.length > 20 ? result.slice(0, 20) + "..." : result;
      },
    },
    {
      accessorFn: (row) => `${row?.room?.name}`,
      permissionModel: "room",
      header: config.translate.room[lang],
      size: 100,
      Cell: (
        RecievedObject,
        cell = RecievedObject.renderedCellValue,
        row = RecievedObject.row.original
      ) => {
        let result = parse(row?.room?.name ? row.room.name : "...");
        return result.length > 20 ? result.slice(0, 20) + "..." : result;
      },
    },
  ];
  return (
    <ListPage
      route={config.api.printer}
      query={{}}
      sorting={{ index: "asc" }}
      permissionModel={"printer"}
      uploadType={true}
      limit={null}
      populate={["room", "pc"]}
      columns={columns}
    />
  );
}
