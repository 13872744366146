import React, { useState, useEffect, useMemo } from "react";
import { useParams, useLocation } from "react-router-dom";
import * as API from "../../helpers/api";
import config from "../../config";
import Loading from "../modules/loading";
//
import { useUser } from "../../helpers/userContext";
import parse from "html-react-parser";
import { useLang } from "../../helpers/language";
import Stepper from "./modules/stepper";
import Swal from "sweetalert2";
import { useColorScheme, useDisclosure } from "@mantine/hooks";
import NProgress from "nprogress";
import { useMediaQuery } from "@mantine/hooks";

import {
  Card,
  Text,
  SimpleGrid,
  UnstyledButton,
  Anchor,
  Group,
  useMantineTheme,
  useMantineColorScheme,
  Spoiler,
  Box,
  Button,
  Collapse,
  Flex,
  Title,
  Grid,
} from "@mantine/core";
import classes from "./style.module.css";
import {
  IconCreditCard,
  IconBuildingBank,
  IconRepeat,
  IconReceiptRefund,
  IconReceipt,
  IconReceiptTax,
  IconReport,
  IconCashBanknote,
  IconCoin,
  IconId,
  IconCalendar,
  IconCalendarCheck,
  IconCalendarMonth,
  IconCircuitVoltmeter,
} from "@tabler/icons-react";

//! modules
//? Step 1
import Step1 from "./modules/step1";
import Step2 from "./modules/step2";
import Step3 from "./modules/step3";
import Step4 from "./modules/step4";
import Step5 from "./modules/step5";
import Step6 from "./modules/step6";
import Step7 from "./modules/step7";
import Step8 from "./modules/step9";
import Step9 from "./modules/step10";
import Step10 from "./modules/step12";
import Step11 from "./modules/step13";
import Step12 from "./modules/step14";
import Step13 from "./modules/step15";
import Step14 from "./modules/step16";
import Step15 from "./modules/step17";
import Step16 from "./modules/step18";
import Step17 from "./modules/step18";
import Step18 from "./modules/step18";
import Step19 from "./modules/step19";

export default function ProjectPage() {
  const [isBusy, setBusy] = useState(true);
  let id = useLocation().pathname.split("/")[3];
  const route = config.api.project;
  const { user } = useUser();
  const { lang } = useLang();
  const [opened, Toggle] = useDisclosure(false);
  const [opened2, Toggle2] = useDisclosure(true);
  const [openedInner, ToggleInner] = useDisclosure(true);
  //! global variasbles
  const [ID, setID] = useState("");
  const [priority, setPriority] = useState(0); //  0 less , 1 mid , 2 high
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState("");
  const [projectType, setProjectType] = useState("");
  const [projectTypes, setProjectTypes] = useState("");
  const [city, setCity] = useState("");
  const [branch, setBranch] = useState("");
  const [substation, setSubstation] = useState("");
  const [voltage, setVoltage] = useState("");
  const [voltages, setVoltages] = useState("");
  const [orderNumber, setOrderNumber] = useState("");
  const [address, setAddress] = useState({
    en: "",
    ru: "",
    hy: "",
  });
  const [workingDays, setWorkingDays] = useState("");
  const [step, setStep] = useState(1); // current selected step
  const [activeStep, setActiveStep] = useState(undefined); // current selected step
  // all possible steps
  const [steps, setSteps] = useState([]);
  // local valid steps
  const [localSteps, setLocalSteps] = useState(config.steps);
  const [history, setHistory] = useState([{}]);
  const [users, setUsers] = useState([{}]);
  let modelSendToServer = {};
  const theme = useMantineTheme();
  const { colorScheme } = useMantineColorScheme();
  const [permissions, setPermissions] = useState([]);
  const [isMobile, setIsMobile] = useState("");

  function setEndDateWorkingDays() {
    let localEndDate;
    let localWorkingDays = 0;
    let StartDate = startDate ? new Date(startDate) : new Date();

    // localeeddate is a number but we assign it to a date object
    localEndDate = new Date(
      StartDate.getTime() + voltage.deadline?.project * 24 * 60 * 60 * 1000
    );
    // Assuming StartDate and localEndDate are defined and are JavaScript Date objects
    // Calculate the difference in working days
    while (StartDate < localEndDate) {
      // Skips Sunday and Saturday
      if (StartDate.getDay() !== 0 && StartDate.getDay() !== 6) {
        localWorkingDays++;
      }
      StartDate.setDate(StartDate.getDate() + 1);
    }
    setWorkingDays(localWorkingDays);
    setEndDate(localEndDate);
  }

  const handleStepChange = (step) => {
    setStep(step._id);
  };

  async function UpdateStep({ step, modelSendToServer, backward = false }) {
    let Next, Previous, Current;
    if (backward) {
      try {
        Previous = await API.update(
          config.api.step,
          modelSendToServer.steps[step - 2]._id,
          {
            status: "inProgress",
            startDate: new Date(),
            endDate: null,
            history: [
              ...modelSendToServer.steps[step - 2].history,
              {
                status: "inProgress",
                startDate: new Date(),
                user: user._id,
              },
            ],
          }
        );
        Current = await API.update(
          config.api.step,
          modelSendToServer.steps[step - 1]._id,
          {
            status: null,
            startDate: null,
            endDate: null,
            history: [
              ...modelSendToServer.steps[step - 1].history,
              {
                status: null,
                startDate: null,
                endDate: null,
                user: user._id,
              },
            ],
          }
        );
        if (Previous && Current) {
          setStep(step - 1);
          return { Previous, Current };
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
        }
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      }
    } else {
      try {
        Current = await API.update(
          config.api.step,
          modelSendToServer.steps[step - 1]._id,
          {
            status: "done",
            endDate: new Date(),
            history: [
              ...modelSendToServer.steps[step - 1].history,
              {
                status: "done",
                endDate: new Date(),
                user: user._id,
              },
            ],
          }
        );
        try {
          // test if next step exists
          let [ifStepDoesExists] = await API.get(config.api.step, {
            project_id: id,
            queue: step + 1,
          });
          // if exists update it, if not create it
          if (ifStepDoesExists) {
            Next = await API.update(config.api.step, ifStepDoesExists._id, {
              status: "inProgress",
              queue: step + 1,
              project_id: id,
              name: {
                en: localSteps[step].name.en,
                ru: localSteps[step].name.ru,
                hy: localSteps[step].name.hy,
              },
              startDate: new Date(),
              createdByUser: user._id,
              history: [
                {
                  status: "inProgress",
                  startDate: new Date(),
                  user: user._id,
                },
              ],
            });
          } else {
            Next = await API.post(config.api.step, {
              status: "inProgress",
              queue: step + 1,
              project_id: id,
              name: {
                en: localSteps[step].name.en,
                ru: localSteps[step].name.ru,
                hy: localSteps[step].name.hy,
              },
              startDate: new Date(),
              createdByUser: user._id,
              history: [
                {
                  status: "inProgress",
                  startDate: new Date(),
                  user: user._id,
                },
              ],
            });
          }
          if (Current && Next) {
            setStep(step + 1);
            return { Current, Next };
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
            });
          }
        } catch (error) {
          console.error(error);
        }
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      }
    }
  }

  modelSendToServer = {
    ID,
    history: history ? history : [],
    priority,
    startDate,
    endDate,
    steps: steps ? steps.filter((item) => item._id) : [],
  };

  const HandlePermissionCheck = (input) => {
    if (user?.ananunaki) {
      input.crud = {
        create: true,
        read: true,
        update: true,
        delete: true,
      };
      return input;
    }
    let foundedPermission = user.role.permissions.find(
      (permission) => permission.name === "project"
    );
    if (foundedPermission) {
      let foundedInput = foundedPermission.inputs?.find(
        (inp) => inp.name === input?.permissionModel
      );
      if (foundedInput && foundedInput.read) {
        input.crud = {
          create:
            foundedInput.create !== undefined ? foundedInput.create : false,
          read: foundedInput.read !== undefined ? foundedInput.read : false,
          update:
            foundedInput.update !== undefined ? foundedInput.update : false,
          delete:
            foundedInput.delete !== undefined ? foundedInput.delete : false,
        };
        return input;
      }
    } else {
      console.error("Permission not found");
    }
  };

  useEffect(() => {
    (async () => {
      NProgress.start();
      try {
        let currentProject = await API.get(
          route,
          {
            _id: id,
          },
          null,
          true,
          null,
          ["steps", "city", "branch", "voltage", "projectType"]
        );
        //! set All fields
        setID(currentProject[0]?.ID);
        setHistory(currentProject[0]?.history);
        // set all states from server
        currentProject[0]?.priority && setPriority(currentProject[0]?.priority);
        currentProject[0]?.steps && setSteps(currentProject[0]?.steps);
        currentProject[0]?.substation &&
          setSubstation(currentProject[0]?.substation);
        currentProject[0]?.address?.en &&
          setAddress({ ...address, en: currentProject[0]?.address.en });
        currentProject[0]?.address?.ru &&
          setAddress({ ...address, ru: currentProject[0]?.address.ru });
        currentProject[0]?.address?.hy &&
          setAddress({ ...address, hy: currentProject[0]?.address.hy });
        currentProject[0]?.branch && setBranch(currentProject[0]?.branch);
        currentProject[0]?.voltage && setVoltage(currentProject[0]?.voltage);
        currentProject[0]?.orderNumber &&
          setOrderNumber(currentProject[0]?.orderNumber);
        currentProject[0]?.startDate &&
          setStartDate(new Date(currentProject[0]?.startDate));
        currentProject[0]?.endDate &&
          setEndDate(new Date(currentProject[0]?.endDate));
        currentProject[0]?.workingDays &&
          setWorkingDays(currentProject[0]?.workingDays);
        currentProject[0]?.city && setCity(currentProject[0]?.city);
        currentProject[0]?.branch && setBranch(currentProject[0]?.branch);
        currentProject[0]?.projectType &&
          setProjectType(currentProject[0]?.projectType);

        currentProject[0]?.steps?.forEach((step, index) => {
          if (step.status === "inProgress" && step.bypass !== true) {
            setStep(step.queue);
            setActiveStep(step.queue);
            return;
          }
        });

        setBusy(false);
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: config.translate.somethingWentWrong[lang],
        });
        history.push("/projects");
        setBusy(false);
      }
    })();
    // eslint-disable-next-line
  }, [isBusy, activeStep]);

  useEffect(() => {
    let tempPermissions = [];
    user?.role?.permissions?.forEach((permission) => {
      if (permission.name === "project") {
        permission.inputs.forEach((input) => {
          tempPermissions.push(input);
        });
      }
    });
    setPermissions([...tempPermissions]);
    setIsMobile(window.innerWidth < 768 ? true : false);
  }, []);

  useEffect(() => {
    setEndDateWorkingDays();
    // eslint-disable-next-line
  }, [voltage]);

  // handle page resize
  useEffect(() => {
    // page resize event listerner
    window.addEventListener("resize", () => {
      setIsMobile(window.innerWidth < 768 ? true : false);
    });
  });

  if (isBusy) return <Loading />;
  return (
    <>
      <Flex wrap={"wrap"} align={"center"} gap={20} justify={"center"}>
        <Button
          onClick={() => {
            Toggle.toggle();
          }}
          variant={opened ? "filled" : "outline"}
        >
          {opened
            ? config.translate.hideProjectDetails[lang]
            : config.translate.showProjectDetails[lang]}
        </Button>

        <Button
          onClick={() => {
            Toggle2.toggle();
            if (opened2) ToggleInner.close();
            else ToggleInner.open();
          }}
          variant={opened2 ? "filled" : "outline"}
        >
          {opened2
            ? config.translate.hideAllSteps[lang]
            : config.translate.showAllSteps[lang]}
        </Button>
      </Flex>
      <Flex mb={20} direction={"column"}>
        <Collapse
          style={{ flex: "1 1 auto" }}
          in={opened}
          transitionDuration={250}
          transitionTimingFunction="ease-in-out"
        >
          <Title order={2} mb={10} mt={10} style={{ textAlign: "center" }}>
            {config.translate.projectDetails[lang]}
          </Title>
          <Card shadow="sm" className={classes.card}>
            <Grid grow gutter="xs" align="stretch">
              <Grid.Col span="content">
                <Flex
                  h={"100%"}
                  direction={"column"}
                  align={"center"}
                  justify={"start"}
                  p={10}
                  key="ID"
                  className={classes.item}
                >
                  <img
                    src="/assets/id-button-svgrepo-com.svg"
                    alt="ID"
                    width={30}
                    height={30}
                    style={{
                      filter:
                        colorScheme === "dark" ? "invert(1)" : "invert(0)",
                    }}
                  />
                  <Text size="xs" mt={10}>
                    {ID}
                  </Text>
                </Flex>
              </Grid.Col>

              <Grid.Col span="content">
                <Flex
                  h={"100%"}
                  direction={"column"}
                  align={"center"}
                  justify={"start"}
                  p={10}
                  key={config.translate.startDate[lang]}
                  className={classes.item}
                >
                  <img
                    src="/assets/calendar-arrow-up-svgrepo-com.svg"
                    alt="start date"
                    width={30}
                    height={30}
                    style={{
                      filter:
                        colorScheme === "dark" ? "invert(1)" : "invert(0)",
                    }}
                  />
                  <Text size="xs" mt={10}>
                    {startDate.toDateString()}
                  </Text>
                </Flex>
              </Grid.Col>

              <Grid.Col span="content">
                <Flex
                  h={"100%"}
                  direction={"column"}
                  align={"center"}
                  justify={"start"}
                  p={10}
                  key={config.translate.endDate[lang]}
                  className={classes.item}
                >
                  <img
                    src="/assets/calendar-arrow-down-svgrepo-com.svg"
                    alt="end date"
                    width={30}
                    height={30}
                    style={{
                      filter:
                        colorScheme === "dark" ? "invert(1)" : "invert(0)",
                    }}
                  />
                  <Text size="xs" mt={10}>
                    {endDate.toDateString()} ( working Days {workingDays} )
                  </Text>
                </Flex>
              </Grid.Col>

              <Grid.Col span="content">
                <Flex
                  h={"100%"}
                  direction={"column"}
                  align={"center"}
                  justify={"start"}
                  p={10}
                  key={config.translate.projectType[lang]}
                  className={classes.item}
                >
                  <img
                    src="/assets/octagon-exclamation-svgrepo-com.svg"
                    alt="projecttype"
                    width={30}
                    height={30}
                    style={{
                      filter:
                        colorScheme === "dark" ? "invert(1)" : "invert(0)",
                    }}
                  />
                  <Text size="xs" mt={10}>
                    {projectType && projectType.name && projectType.name[lang]}
                  </Text>
                </Flex>
              </Grid.Col>

              <Grid.Col span="content">
                <Flex
                  h={"100%"}
                  direction={"column"}
                  align={"center"}
                  justify={"start"}
                  p={10}
                  key={config.translate.priority[lang]}
                  className={classes.item}
                >
                  <img
                    src="/assets/security-priority-svgrepo-com.svg"
                    alt="priority"
                    width={30}
                    height={30}
                    style={{
                      filter:
                        colorScheme === "dark" ? "invert(1)" : "invert(0)",
                    }}
                  />
                  <Text size="xs" mt={10}>
                    {priority === 0
                      ? config.translate.high[lang]
                      : priority === 1
                      ? config.translate.medium[lang]
                      : priority === 2
                      ? config.translate.low[lang]
                      : config.translate.undefined[lang]}
                  </Text>
                </Flex>
              </Grid.Col>

              <Grid.Col span="content">
                <Flex
                  h={"100%"}
                  direction={"column"}
                  align={"center"}
                  justify={"start"}
                  p={10}
                  key={config.translate.voltage[lang]}
                  className={classes.item}
                >
                  <img
                    src="/assets/voltage-svgrepo-com.svg"
                    alt="voltage"
                    width={30}
                    height={30}
                    style={{
                      filter:
                        colorScheme === "dark" ? "invert(1)" : "invert(0)",
                    }}
                  />
                  <Text size="xs" mt={10}>
                    {voltage && voltage.name && voltage.name[lang]}
                  </Text>
                </Flex>
              </Grid.Col>

              <Grid.Col span="content">
                <Flex
                  h={"100%"}
                  direction={"column"}
                  align={"center"}
                  justify={"start"}
                  p={10}
                  key={config.translate.city[lang]}
                  className={classes.item}
                >
                  <img
                    src="/assets/city-buildings-svgrepo-com.svg"
                    alt="city"
                    width={30}
                    height={30}
                    style={{
                      filter:
                        colorScheme === "dark" ? "invert(1)" : "invert(0)",
                    }}
                  />
                  <Text size="xs" mt={10}>
                    {city && city.name && city.name[lang]}
                  </Text>
                </Flex>
              </Grid.Col>

              <Grid.Col span="content">
                <Flex
                  h={"100%"}
                  direction={"column"}
                  align={"center"}
                  justify={"start"}
                  p={10}
                  key={config.translate.address[lang]}
                  className={classes.item}
                >
                  <img
                    src="/assets/address-svgrepo-com.svg"
                    alt="address"
                    width={30}
                    height={30}
                    style={{
                      filter:
                        colorScheme === "dark" ? "invert(1)" : "invert(0)",
                    }}
                  />
                  <Text size="xs" mt={10}>
                    {address[lang]}
                  </Text>
                </Flex>
              </Grid.Col>

              <Grid.Col span="content">
                <Flex
                  h={"100%"}
                  direction={"column"}
                  align={"center"}
                  justify={"start"}
                  p={10}
                  key={config.translate.branch[lang]}
                  className={classes.item}
                >
                  <img
                    src="/assets/branch-svgrepo-com.svg"
                    alt="branch"
                    width={30}
                    height={30}
                    style={{
                      filter:
                        colorScheme === "dark" ? "invert(1)" : "invert(0)",
                    }}
                  />
                  <Text size="xs" mt={10}>
                    {branch && branch.name && branch.name[lang]}
                  </Text>
                </Flex>
              </Grid.Col>

              <Grid.Col span="content">
                <Flex
                  h={"100%"}
                  direction={"column"}
                  align={"center"}
                  justify={"start"}
                  p={10}
                  key={config.translate.substation[lang]}
                  className={classes.item}
                >
                  <img
                    src="/assets/bolt-svgrepo-com.svg"
                    alt="substation"
                    width={30}
                    height={30}
                    style={{
                      filter:
                        colorScheme === "dark" ? "invert(1)" : "invert(0)",
                    }}
                  />
                  <Text size="xs" mt={10}>
                    {substation}
                  </Text>
                </Flex>
              </Grid.Col>

              <Grid.Col span="content">
                <Flex
                  h={"100%"}
                  direction={"column"}
                  align={"center"}
                  justify={"start"}
                  p={10}
                  key={config.translate.substation[lang]}
                  className={classes.item}
                >
                  <img
                    src="/assets/number-sign-110-svgrepo-com.svg"
                    alt="orderNumber"
                    width={30}
                    height={30}
                    style={{
                      filter:
                        colorScheme === "dark" ? "invert(1)" : "invert(0)",
                    }}
                  />
                  <Text size="xs" mt={10}>
                    {orderNumber}
                  </Text>
                </Flex>
              </Grid.Col>

              <Grid.Col span="content">
                <Flex
                  h={"100%"}
                  direction={"column"}
                  align={"center"}
                  justify={"start"}
                  p={10}
                  key={config.translate.projectType[lang]}
                  className={classes.item}
                >
                  <img
                    src="/assets/dollar1-svgrepo-com.svg"
                    alt="voltage"
                    width={30}
                    height={30}
                    style={{
                      filter:
                        colorScheme === "dark" ? "invert(1)" : "invert(0)",
                    }}
                  />
                  <Text size="xs" mt={10}>
                    Payments
                  </Text>
                </Flex>
              </Grid.Col>
            </Grid>
          </Card>
        </Collapse>
        <Collapse
          style={{ flex: "1 1 auto" }}
          in={opened2}
          transitionDuration={250}
          transitionTimingFunction="ease-in-out"
        >
          <Title order={2} mb={10} mt={10} style={{ textAlign: "center" }}>
            {localSteps[step - 1]?.name[lang]}
          </Title>
          <Card shadow="sm" className={classes.card}>
            <Stepper
              localSteps={localSteps}
              activeStep={activeStep}
              handleStepChange={handleStepChange}
              modelSendToServer={modelSendToServer}
              step={step}
              lang={lang}
              user={user}
              permissions={permissions}
              toggleInner={ToggleInner}
              openedInner={openedInner}
            />
          </Card>
        </Collapse>
      </Flex>
      {(user.ananunaki ||
        permissions.find((permission) => permission.name === "step1")?.read) &&
      step === 1 ? (
        <Step1
          id={id}
          modelSendToServer={modelSendToServer}
          setBusy={setBusy}
          isBusy={isBusy}
          ID={ID}
          setID={setID}
          history={history}
          lang={lang}
          step={step}
          steps={steps}
          setStep={setStep}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          handleStepChange={handleStepChange}
          route={route}
          openedInner={openedInner}
          localSteps={localSteps}
          UpdateStep={UpdateStep}
          priority={priority}
          setPriority={setPriority}
          projectStartDate={startDate}
          setProjectStartDate={setStartDate}
          projectEndDate={endDate}
          setProjectEndDate={setEndDate}
          projectType={projectType}
          projectTypes={projectTypes}
          setProjectType={setProjectType}
          setProjectTypes={setProjectTypes}
          voltage={voltage}
          branch={branch}
          permissions={permissions}
        />
      ) : (user.ananunaki ||
          permissions.find((permission) => permission.name === "step2")
            ?.read) &&
        step === 2 ? (
        <Step2
          id={id}
          modelSendToServer={modelSendToServer}
          setBusy={setBusy}
          isBusy={isBusy}
          ID={ID}
          setID={setID}
          history={history}
          lang={lang}
          step={step}
          steps={steps}
          setStep={setStep}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          handleStepChange={handleStepChange}
          route={route}
          openedInner={openedInner}
          localSteps={localSteps}
          UpdateStep={UpdateStep}
          priority={priority}
          setPriority={setPriority}
          projectStartDate={startDate}
          setProjectStartDate={setStartDate}
          projectEndDate={endDate}
          setProjectEndDate={setEndDate}
          projectType={projectType}
          projectTypes={projectTypes}
          setProjectType={setProjectType}
          setProjectTypes={setProjectTypes}
          voltage={voltage}
          permissions={permissions}
        />
      ) : (user.ananunaki ||
          permissions.find((permission) => permission.name === "step3")
            ?.read) &&
        step === 3 ? (
        <Step3
          id={id}
          modelSendToServer={modelSendToServer}
          setBusy={setBusy}
          isBusy={isBusy}
          ID={ID}
          setID={setID}
          history={history}
          lang={lang}
          step={step}
          steps={steps}
          setStep={setStep}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          handleStepChange={handleStepChange}
          route={route}
          openedInner={openedInner}
          localSteps={localSteps}
          UpdateStep={UpdateStep}
          priority={priority}
          setPriority={setPriority}
          projectStartDate={startDate}
          setProjectStartDate={setStartDate}
          projectEndDate={endDate}
          setProjectEndDate={setEndDate}
          projectType={projectType}
          projectTypes={projectTypes}
          setProjectType={setProjectType}
          setProjectTypes={setProjectTypes}
          voltage={voltage}
          permissions={permissions}
        />
      ) : (user.ananunaki ||
          permissions.find((permission) => permission.name === "step4")
            ?.read) &&
        step === 4 ? (
        <Step4
          id={id}
          modelSendToServer={modelSendToServer}
          setBusy={setBusy}
          isBusy={isBusy}
          ID={ID}
          setID={setID}
          history={history}
          lang={lang}
          step={step}
          steps={steps}
          setStep={setStep}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          handleStepChange={handleStepChange}
          route={route}
          openedInner={openedInner}
          localSteps={localSteps}
          UpdateStep={UpdateStep}
          priority={priority}
          setPriority={setPriority}
          projectStartDate={startDate}
          setProjectStartDate={setStartDate}
          projectEndDate={endDate}
          setProjectEndDate={setEndDate}
          projectType={projectType}
          projectTypes={projectTypes}
          setProjectType={setProjectType}
          setProjectTypes={setProjectTypes}
          voltage={voltage}
          permissions={permissions}
        />
      ) : (user.ananunaki ||
          permissions.find((permission) => permission.name === "step5")
            ?.read) &&
        step === 5 ? (
        <Step5
          id={id}
          modelSendToServer={modelSendToServer}
          setBusy={setBusy}
          isBusy={isBusy}
          ID={ID}
          setID={setID}
          history={history}
          lang={lang}
          step={step}
          steps={steps}
          setStep={setStep}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          handleStepChange={handleStepChange}
          route={route}
          openedInner={openedInner}
          localSteps={localSteps}
          UpdateStep={UpdateStep}
          priority={priority}
          setPriority={setPriority}
          projectStartDate={startDate}
          setProjectStartDate={setStartDate}
          projectEndDate={endDate}
          setProjectEndDate={setEndDate}
          projectType={projectType}
          projectTypes={projectTypes}
          setProjectType={setProjectType}
          setProjectTypes={setProjectTypes}
          voltage={voltage}
          permissions={permissions}
        />
      ) : (user.ananunaki ||
          permissions.find((permission) => permission.name === "step6")
            ?.read) &&
        step === 6 ? (
        <Step6
          id={id}
          modelSendToServer={modelSendToServer}
          setBusy={setBusy}
          isBusy={isBusy}
          ID={ID}
          setID={setID}
          history={history}
          lang={lang}
          step={step}
          steps={steps}
          setStep={setStep}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          handleStepChange={handleStepChange}
          openedInner={openedInner}
          route={route}
          localSteps={localSteps}
          UpdateStep={UpdateStep}
          priority={priority}
          setPriority={setPriority}
          projectStartDate={startDate}
          setProjectStartDate={setStartDate}
          projectEndDate={endDate}
          setProjectEndDate={setEndDate}
          projectType={projectType}
          projectTypes={projectTypes}
          setProjectType={setProjectType}
          setProjectTypes={setProjectTypes}
          voltage={voltage}
          permissions={permissions}
          branch={branch}
        />
      ) : (user.ananunaki ||
          permissions.find((permission) => permission.name === "step7")
            ?.read) &&
        step === 7 ? (
        <Step7
          id={id}
          modelSendToServer={modelSendToServer}
          setBusy={setBusy}
          isBusy={isBusy}
          ID={ID}
          setID={setID}
          history={history}
          lang={lang}
          step={step}
          steps={steps}
          setStep={setStep}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          handleStepChange={handleStepChange}
          openedInner={openedInner}
          route={route}
          localSteps={localSteps}
          UpdateStep={UpdateStep}
          priority={priority}
          setPriority={setPriority}
          projectStartDate={startDate}
          setProjectStartDate={setStartDate}
          projectEndDate={endDate}
          setProjectEndDate={setEndDate}
          projectType={projectType}
          projectTypes={projectTypes}
          setProjectType={setProjectType}
          setProjectTypes={setProjectTypes}
          voltage={voltage}
          branch={branch}
          permissions={permissions}
        />
      ) : (user.ananunaki ||
          permissions.find((permission) => permission.name === "step8")
            ?.read) &&
        step === 8 ? (
        <Step8
          id={id}
          modelSendToServer={modelSendToServer}
          setBusy={setBusy}
          isBusy={isBusy}
          ID={ID}
          setID={setID}
          history={history}
          lang={lang}
          step={step}
          steps={steps}
          setStep={setStep}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          handleStepChange={handleStepChange}
          openedInner={openedInner}
          route={route}
          localSteps={localSteps}
          UpdateStep={UpdateStep}
          priority={priority}
          setPriority={setPriority}
          projectStartDate={startDate}
          setProjectStartDate={setStartDate}
          projectEndDate={endDate}
          setProjectEndDate={setEndDate}
          projectType={projectType}
          projectTypes={projectTypes}
          setProjectType={setProjectType}
          setProjectTypes={setProjectTypes}
          voltage={voltage}
          permissions={permissions}
        />
      ) : (user.ananunaki ||
          permissions.find((permission) => permission.name === "step9")
            ?.read) &&
        step === 9 ? (
        <Step9
          id={id}
          modelSendToServer={modelSendToServer}
          setBusy={setBusy}
          isBusy={isBusy}
          ID={ID}
          setID={setID}
          history={history}
          lang={lang}
          step={step}
          steps={steps}
          setStep={setStep}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          handleStepChange={handleStepChange}
          openedInner={openedInner}
          route={route}
          localSteps={localSteps}
          UpdateStep={UpdateStep}
          priority={priority}
          setPriority={setPriority}
          projectStartDate={startDate}
          setProjectStartDate={setStartDate}
          projectEndDate={endDate}
          setProjectEndDate={setEndDate}
          projectType={projectType}
          projectTypes={projectTypes}
          setProjectType={setProjectType}
          setProjectTypes={setProjectTypes}
          voltage={voltage}
          permissions={permissions}
        />
      ) : (user.ananunaki ||
          permissions.find((permission) => permission.name === "step10")
            ?.read) &&
        step === 10 ? (
        <Step10
          id={id}
          modelSendToServer={modelSendToServer}
          setBusy={setBusy}
          isBusy={isBusy}
          ID={ID}
          setID={setID}
          history={history}
          lang={lang}
          step={step}
          steps={steps}
          setStep={setStep}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          handleStepChange={handleStepChange}
          route={route}
          localSteps={localSteps}
          UpdateStep={UpdateStep}
          priority={priority}
          setPriority={setPriority}
          projectStartDate={startDate}
          setProjectStartDate={setStartDate}
          projectEndDate={endDate}
          setProjectEndDate={setEndDate}
          projectType={projectType}
          projectTypes={projectTypes}
          setProjectType={setProjectType}
          setProjectTypes={setProjectTypes}
          voltage={voltage}
          openedInner={openedInner}
          permissions={permissions}
        />
      ) : (user.ananunaki ||
          permissions.find((permission) => permission.name === "step11")
            ?.read) &&
        step === 11 ? (
        <Step11
          id={id}
          modelSendToServer={modelSendToServer}
          setBusy={setBusy}
          isBusy={isBusy}
          ID={ID}
          setID={setID}
          history={history}
          lang={lang}
          step={step}
          steps={steps}
          setStep={setStep}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          handleStepChange={handleStepChange}
          route={route}
          localSteps={localSteps}
          UpdateStep={UpdateStep}
          priority={priority}
          setPriority={setPriority}
          projectStartDate={startDate}
          setProjectStartDate={setStartDate}
          projectEndDate={endDate}
          setProjectEndDate={setEndDate}
          projectType={projectType}
          projectTypes={projectTypes}
          setProjectType={setProjectType}
          setProjectTypes={setProjectTypes}
          voltage={voltage}
          openedInner={openedInner}
          permissions={permissions}
        />
      ) : (user.ananunaki ||
          permissions.find((permission) => permission.name === "step12")
            ?.read) &&
        step === 12 ? (
        <Step12
          id={id}
          modelSendToServer={modelSendToServer}
          setBusy={setBusy}
          isBusy={isBusy}
          ID={ID}
          setID={setID}
          history={history}
          lang={lang}
          step={step}
          steps={steps}
          setStep={setStep}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          handleStepChange={handleStepChange}
          route={route}
          localSteps={localSteps}
          UpdateStep={UpdateStep}
          priority={priority}
          setPriority={setPriority}
          projectStartDate={startDate}
          setProjectStartDate={setStartDate}
          projectEndDate={endDate}
          setProjectEndDate={setEndDate}
          projectType={projectType}
          projectTypes={projectTypes}
          setProjectType={setProjectType}
          setProjectTypes={setProjectTypes}
          voltage={voltage}
          openedInner={openedInner}
          permissions={permissions}
        />
      ) : (user.ananunaki ||
          permissions.find((permission) => permission.name === "step13")
            ?.read) &&
        step === 13 ? (
        <Step13
          id={id}
          modelSendToServer={modelSendToServer}
          setBusy={setBusy}
          isBusy={isBusy}
          ID={ID}
          setID={setID}
          history={history}
          lang={lang}
          step={step}
          steps={steps}
          setStep={setStep}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          handleStepChange={handleStepChange}
          route={route}
          localSteps={localSteps}
          UpdateStep={UpdateStep}
          priority={priority}
          setPriority={setPriority}
          projectStartDate={startDate}
          setProjectStartDate={setStartDate}
          projectEndDate={endDate}
          setProjectEndDate={setEndDate}
          projectType={projectType}
          projectTypes={projectTypes}
          setProjectType={setProjectType}
          setProjectTypes={setProjectTypes}
          voltage={voltage}
          openedInner={openedInner}
          permissions={permissions}
        />
      ) : (user.ananunaki ||
          permissions.find((permission) => permission.name === "step14")
            ?.read) &&
        step === 14 ? (
        <Step14
          id={id}
          modelSendToServer={modelSendToServer}
          setBusy={setBusy}
          isBusy={isBusy}
          ID={ID}
          setID={setID}
          history={history}
          lang={lang}
          step={step}
          steps={steps}
          setStep={setStep}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          handleStepChange={handleStepChange}
          route={route}
          localSteps={localSteps}
          UpdateStep={UpdateStep}
          priority={priority}
          setPriority={setPriority}
          projectStartDate={startDate}
          setProjectStartDate={setStartDate}
          projectEndDate={endDate}
          setProjectEndDate={setEndDate}
          projectType={projectType}
          projectTypes={projectTypes}
          setProjectType={setProjectType}
          setProjectTypes={setProjectTypes}
          voltage={voltage}
          openedInner={openedInner}
          permissions={permissions}
        />
      ) : (user.ananunaki ||
          permissions.find((permission) => permission.name === "step15")
            ?.read) &&
        step === 15 ? (
        <Step15
          id={id}
          modelSendToServer={modelSendToServer}
          setBusy={setBusy}
          isBusy={isBusy}
          ID={ID}
          setID={setID}
          history={history}
          lang={lang}
          step={step}
          steps={steps}
          setStep={setStep}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          handleStepChange={handleStepChange}
          route={route}
          localSteps={localSteps}
          UpdateStep={UpdateStep}
          priority={priority}
          setPriority={setPriority}
          projectStartDate={startDate}
          setProjectStartDate={setStartDate}
          projectEndDate={endDate}
          setProjectEndDate={setEndDate}
          projectType={projectType}
          projectTypes={projectTypes}
          setProjectType={setProjectType}
          setProjectTypes={setProjectTypes}
          voltage={voltage}
          openedInner={openedInner}
          permissions={permissions}
        />
      ) : (user.ananunaki ||
          permissions.find((permission) => permission.name === "step16")
            ?.read) &&
        step === 16 ? (
        <Step16
          id={id}
          modelSendToServer={modelSendToServer}
          setBusy={setBusy}
          isBusy={isBusy}
          ID={ID}
          setID={setID}
          history={history}
          lang={lang}
          step={step}
          steps={steps}
          setStep={setStep}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          handleStepChange={handleStepChange}
          route={route}
          localSteps={localSteps}
          UpdateStep={UpdateStep}
          priority={priority}
          setPriority={setPriority}
          projectStartDate={startDate}
          setProjectStartDate={setStartDate}
          projectEndDate={endDate}
          setProjectEndDate={setEndDate}
          projectType={projectType}
          projectTypes={projectTypes}
          setProjectType={setProjectType}
          setProjectTypes={setProjectTypes}
          voltage={voltage}
          openedInner={openedInner}
          permissions={permissions}
        />
      ) : (user.ananunaki ||
          permissions.find((permission) => permission.name === "step17")
            ?.read) &&
        step === 17 ? (
        <Step17
          id={id}
          modelSendToServer={modelSendToServer}
          setBusy={setBusy}
          isBusy={isBusy}
          ID={ID}
          setID={setID}
          history={history}
          lang={lang}
          step={step}
          steps={steps}
          setStep={setStep}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          handleStepChange={handleStepChange}
          route={route}
          localSteps={localSteps}
          UpdateStep={UpdateStep}
          priority={priority}
          setPriority={setPriority}
          projectStartDate={startDate}
          setProjectStartDate={setStartDate}
          projectEndDate={endDate}
          setProjectEndDate={setEndDate}
          projectType={projectType}
          projectTypes={projectTypes}
          setProjectType={setProjectType}
          setProjectTypes={setProjectTypes}
          voltage={voltage}
          openedInner={openedInner}
          permissions={permissions}
        />
      ) : (user.ananunaki ||
          permissions.find((permission) => permission.name === "step18")
            ?.read) &&
        step === 18 ? (
        <Step18
          id={id}
          modelSendToServer={modelSendToServer}
          setBusy={setBusy}
          isBusy={isBusy}
          ID={ID}
          setID={setID}
          history={history}
          lang={lang}
          step={step}
          steps={steps}
          setStep={setStep}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          handleStepChange={handleStepChange}
          route={route}
          localSteps={localSteps}
          UpdateStep={UpdateStep}
          priority={priority}
          setPriority={setPriority}
          projectStartDate={startDate}
          setProjectStartDate={setStartDate}
          projectEndDate={endDate}
          setProjectEndDate={setEndDate}
          projectType={projectType}
          projectTypes={projectTypes}
          setProjectType={setProjectType}
          setProjectTypes={setProjectTypes}
          voltage={voltage}
          openedInner={openedInner}
          permissions={permissions}
        />
      ) : (user.ananunaki ||
          permissions.find((permission) => permission.name === "step19")
            ?.read) &&
        step === 19 ? (
        <Step19
          id={id}
          modelSendToServer={modelSendToServer}
          setBusy={setBusy}
          isBusy={isBusy}
          ID={ID}
          setID={setID}
          history={history}
          lang={lang}
          step={step}
          steps={steps}
          setStep={setStep}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          handleStepChange={handleStepChange}
          route={route}
          localSteps={localSteps}
          UpdateStep={UpdateStep}
          priority={priority}
          setPriority={setPriority}
          projectStartDate={startDate}
          setProjectStartDate={setStartDate}
          projectEndDate={endDate}
          setProjectEndDate={setEndDate}
          projectType={projectType}
          projectTypes={projectTypes}
          setProjectType={setProjectType}
          setProjectTypes={setProjectTypes}
          voltage={voltage}
          openedInner={openedInner}
          permissions={permissions}
        />
      ) : (
        <div>Step {step} is not found</div>
      )}
    </>
  );
}
