import React, { useMemo } from "react";
import { ListPage } from "../ListPage";
import { useUser } from "../../helpers/userContext";
import { useLang } from "../../helpers/language";
import parse from "html-react-parser";
import config from "../../config";

export default function ChatList() {
  const { user } = useUser();
  const { lang } = useLang();
  const columns = [
    {
      accessorKey: "index",
      header: config.translate.index[lang],
      permissionModel: "index",
      size: 30,
    },
  ];
  return (
    <ListPage
      route={config.api.chat}
      query={{}}
      sorting={{ index: "asc" }}
      permissionModel={"chat"}
      uploadType={true}
      limit={null}
      populate={["users"]}
      columns={columns}
    />
  );
}
