import React, { useState, useEffect } from "react";
import StepPage from "../StepPage";
import SinglePage from "../../SinglePage";
import { useParams } from "react-router-dom";
import * as API from "../../../helpers/api";
import config from "../../../config";
import Loading from "../../modules/loading";
// import getUploads from "../../../helpers/getUploads";
import { useUser } from "../../../helpers/userContext";
import moment from "moment";
import parse from "html-react-parser";
import { useLang } from "../../../helpers/language";
import Swal from "sweetalert2";
import { IconCalendarClock, IconCalendarWeek } from "@tabler/icons-react";
import { IconTimeline } from "@tabler/icons-react";
import LoadingSceleton from "./loadingSceleton";

export default function Step1({
  route,
  id,
  ID,
  setID,
  modelSendToServer,
  destiny,
  lang,
  history,
  step,
  UpdateStep,
  steps,
  setStep,
  localSteps,
  activeStep,
  setActiveStep,
  isBusy,
  setBusy,
  priority,
  setPriority,
  projectType,
  setProjectType,
  priorities,
  projectTypes,
  setProjectTypes,
  projectStartDate,
  setProjectStartDate,
  handleStepChange,
  projectEndDate,
  setProjectEndDate,
  voltage,
  branch,
  permissions,
  openedInner,
}) {
  //! Step 1
  const [isComponentBusy, setComponentBusy] = useState(true);
  const [Id, setCurrentStepId] = useState("");
  const [currentSTEP, setCurrentSTEP] = useState({});
  const [startDate, setStartDate] = useState([]);
  const [endDate, setEndDate] = useState([]);
  const [deadline, setDeadline] = useState("");
  const [status, setStatus] = useState("notStarted");
  // queue is set automatically when sending to server
  const { user } = useUser();
  const [History, setHistory] = useState([{}]);
  const [selectedTimeInterval, setSelectedTimeInterval] = useState({});
  const [timeLineSelected, setTimeLineSelected] = useState(1);
  // upload specific variables
  const [image, setImage] = useState([]);
  const [images, setImages] = useState([]);
  const [kmz, setKmz] = useState(undefined);
  const [historySteps, setHistorySteps] = useState([]);
  const [descEn, setDescEn] = useState("");
  const [descRu, setDescRu] = useState("");
  const [descHy, setDescHy] = useState("");
  const [hamadzaynecnox, setHamadzaynecnox] = useState([]);
  const [texaznnox, setTexaznnox] = useState([]);

  let otherFields = {
    users: [hamadzaynecnox._id, texaznnox._id],
    desc: { en: descEn, ru: descRu, hy: descHy },
  };

  const setUploadsByTimeInterval = async (item) => {
    if (item.destiny === "images") {
      if (
        moment(item.date) > moment(selectedTimeInterval.startDate) &&
        moment(item.date) < moment(selectedTimeInterval.endDate)
      ) {
        setImages((prev) => [...prev, item]);
      }
    }
    if (item.destiny === "image") {
      if (
        moment(item.date) > moment(selectedTimeInterval.startDate) &&
        moment(item.date) < moment(selectedTimeInterval.endDate)
      ) {
        setImage(item);
      }
    }
    if (item.destiny === "kmz") {
      if (
        moment(item.date) > moment(selectedTimeInterval.startDate) &&
        moment(item.date) < moment(selectedTimeInterval.endDate)
      ) {
        setKmz(item);
      }
    }
    return item;
  };

  useEffect(() => {
    (async () => {
      branch?.users?.map(async (item) => {
        if (item.role === "hamadzaynecnox") {
          let hamaynqiPatasxanatu = await API.get(config.api.user, {
            _id: item.user,
          });
          setHamadzaynecnox(hamaynqiPatasxanatu[0]);
        }
        if (item.role === "texaznnox") {
          let shagrgirPatasxanatu = await API.get(config.api.user, {
            _id: item.user,
          });
          setTexaznnox(shagrgirPatasxanatu[0]);
        }
      });

      let currentStep = await API.get(
        config.api.step,
        {
          project_id: id,
          queue: step,
        },
        {},
        true,
        1
      );
      // set all states from server
      currentStep[0] && setCurrentSTEP(currentStep[0]);
      currentStep[0]?._id && setCurrentStepId(currentStep[0]?._id);
      currentStep[0]?.startDate && setStartDate(currentStep[0]?.startDate);
      currentStep[0]?.endDate && setEndDate(currentStep[0]?.endDate);
      currentStep[0]?.status && setStatus(currentStep[0]?.status);
      currentStep[0]?.history && setHistory(currentStep[0]?.history);
      currentStep[0]?.desc && setDescEn(currentStep[0]?.desc.en);
      currentStep[0]?.desc && setDescRu(currentStep[0]?.desc.ru);
      currentStep[0]?.desc && setDescHy(currentStep[0]?.desc.hy);

      let historySteps = [];
      if (currentStep[0]?.history) {
        for (let i = 0; i < currentStep[0]?.history.length; i++) {
          if (currentStep[0]?.history[i].status === "inProgress") {
            // historySteps.push(currentStep[0]?.history[i]);
            historySteps.push({
              startDate: currentStep[0]?.history?.[i].date,
              endDate: currentStep[0]?.history?.[i + 1]?.date,
              changes: {},
            });
          }
        }
      }

      setHistorySteps(historySteps);
      setTimeLineSelected(historySteps.length - 1);
      setSelectedTimeInterval({
        startDate: historySteps[historySteps.length - 1]?.startDate,
        endDate: historySteps[historySteps.length - 1]?.endDate,
      });

      setImage(undefined);
      setImages([]);
      setKmz(undefined);
      currentStep[0]?.uploads?.map((item) => {
        setUploadsByTimeInterval(item);
      });

      setDeadline(voltage?.deadline?.step1);

      // eslint-disable-next-line
      setBusy(false);
      setComponentBusy(false);
    })();
    // eslint-disable-next-line
  }, [isBusy, isComponentBusy]);

  useEffect(() => {
    // change default files on selectedTimeinterval change
    setImage(undefined);
    setImages([]);
    setKmz(undefined);
    currentSTEP?.uploads?.map((item) => {
      setUploadsByTimeInterval(item);
    });
  }, [selectedTimeInterval]);

  if (isComponentBusy) return <LoadingSceleton />;
  return (
    <StepPage
      isBusy={isBusy}
      setBusy={setBusy}
      route={route}
      projectId={id}
      stepId={Id}
      handleStepChange={handleStepChange}
      lang={lang}
      History={History}
      modelSendToServer={modelSendToServer}
      pageTitle={localSteps?.[step - 1]?.name[lang]}
      step={step}
      UpdateStep={UpdateStep}
      activeStep={activeStep}
      setActiveStep={setActiveStep}
      steps={steps}
      setStep={setStep}
      localSteps={localSteps}
      startDate={startDate}
      endDate={endDate}
      selectedTimeInterval={selectedTimeInterval}
      setSelectedTimeInterval={setSelectedTimeInterval}
      timeLineSelected={timeLineSelected}
      setTimeLineSelected={setTimeLineSelected}
      historySteps={historySteps}
      otherFields={otherFields}
      setComponentBusy={setComponentBusy}
      permissions={permissions}
      openedInner={openedInner}
      isComponentBusy={isComponentBusy}
      inputs={[
        historySteps[0]?.startDate && {
          value: moment(historySteps[0]?.startDate).format(
            "DD-MM-YYYY, HH:mm:ss"
          ),
          type: "text",
          usageType: "textfield",
          label: config.translate.startDate[lang],
          disabled: true,
          permissionModel: "step1-startDate",
          rightIcon: <IconTimeline />,
        },
        historySteps[0]?.startDate &&
        historySteps[historySteps.length - 1]?.endDate
          ? {
              value:
                "It lasts " +
                moment(historySteps[historySteps.length - 1]?.endDate).diff(
                  historySteps[historySteps.length - 1]?.startDate,
                  "minutes"
                ) +
                " working minutes",
              type: "text",
              usageType: "textfield",
              label: config.translate.processTime[lang],
              permissionModel: "step1-processTime",
              disabled: true,
              rightIcon: <IconCalendarClock />,
            }
          : {
              value:
                "Still going on " +
                moment(new Date()).diff(
                  historySteps[historySteps.length - 1]?.startDate,
                  "minutes"
                ) +
                " working minutes",
              type: "text",
              usageType: "textfield",
              label: config.translate.processTime[lang],
              permissionModel: "step1-processTime",
              disabled: true,
              rightIcon: <IconCalendarClock />,
            },
        historySteps[historySteps.length - 1]?.endDate && {
          value: moment(historySteps[historySteps.length - 1]?.endDate).format(
            "DD-MM-YYYY, HH:mm:ss"
          ),
          type: "text",
          usageType: "textfield",
          label: config.translate.endDate[lang],
          disabled: true,
          permissionModel: "step1-endDate",
          rightIcon: <IconCalendarClock />,
        },
        deadline && {
          value: moment(historySteps[0]?.startDate)
            .add(deadline, "days")
            .format("DD-MM-YYYY"),
          type: "text",
          usageType: "textfield",
          label: config.translate.deadline[lang],
          permissionModel: "step1-deadline",
          disabled: true,
          rightIcon: <IconCalendarWeek />,
        },
        {
          value: descEn,
          setter: setDescEn,
          type: "text",
          usageType: "textarea-simple",
          label: config.translate.description[lang],
          placeholder: config.translate.inputInLatin[lang],
          lang: "en",
          permissionModel: "step1-desc",
          width: 12,
        },
        {
          value: descRu,
          setter: setDescRu,
          type: "text",
          usageType: "textarea-simple",
          label: config.translate.description[lang],
          placeholder: config.translate.inputInRussian[lang],
          lang: "ru",
          permissionModel: "step1-desc",
          width: 12,
        },
        {
          value: descHy,
          setter: setDescHy,
          type: "text",
          usageType: "textarea-simple",
          label: config.translate.description[lang],
          placeholder: config.translate.inputInArmenian[lang],
          permissionModel: "step1-desc",
          lang: "hy",
          width: 12,
        },
        {
          value: texaznnox?.name?.[lang],
          type: "text",
          usageType: "textfield",
          label: config.translate.user[lang] + " տեղազննող",
          permissionModel: "step1-textaznnox",
          disabled: true,
        },
        {
          value: hamadzaynecnox?.name?.[lang],
          type: "text",
          usageType: "textfield",
          label: config.translate.user[lang] + " համաձայնեցնող",
          permissionModel: "step1-hamadzaynecnox",
          disabled: true,
        },
      ]}
      filesComponent={[
        {
          destiny: "image",
          file: image,
          setFile: setImage,
          filesUploadLimit: 1,
          filesMaxSize: 5,
          acceptedFiles: ["image/png", "image/jpeg", "image/jpg"],
          adminMode: user.role !== "editor",
          height: 500,
          permissionModel: "step1-image",
          placeholder: "Հետագիծ",
        },
        {
          destiny: "kmz",
          file: kmz,
          setFile: setKmz,
          filesUploadLimit: 1,
          filesMaxSize: 5,
          acceptedFiles: [".kmz", ".kml"],
          adminMode: user.role !== "editor",
          permissionModel: "step1-kmz",
          height: 500,
        },
        {
          destiny: "images",
          files: images,
          setFiles: setImages,
          filesUploadLimit: 3,
          filesMaxSize: 5,
          acceptedFiles: ["image/png", "image/jpeg", "image/jpg"],
          adminMode: user.role !== "editor",
          height: 500,
          permissionModel: "step1-images",
          placeholder: "Նկարներ",
        },
      ]}
    />
  );
}
