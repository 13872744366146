import React, { useMemo, useEffect, useState } from "react";
import { ListPage } from "../ListPage";
import config from "../../config";
import * as API from "../../helpers/api";
import NProgress, { set } from "nprogress";
import { useUser } from "../../helpers/userContext";
import { useLang } from "../../helpers/language";
import parse from "html-react-parser";
import Loading from "../modules/loading";
import moment from "moment";
import {
  Avatar,
  Box,
  Button,
  filterProps,
  Flex,
  Menu,
  Text,
  Title,
} from "@mantine/core";
import { Link, useParams } from "react-router-dom";
import { Image, useMantineColorScheme } from "@mantine/core";

const parsePhoneNumber = (number) =>
  `+${number
    .split(/[-*\/\s]+/)
    .join("")
    .slice(1)}`;

function checkBirthDay(birthdate, colorScheme = "light") {
  const today = moment();
  const birthdateMoment = moment(birthdate, moment.ISO_8601, true);

  if (!birthdateMoment.isValid()) {
    return "Неверная дата";
  }

  const years = today.diff(birthdateMoment, "years");

  birthdateMoment.add(years, "years");
  const isBirthday = today.isSame(birthdateMoment, "day");

  const nextBirthday = birthdateMoment.clone().add(1, "year");

  const upcomingBirthdayDays = nextBirthday.diff(today, "days");

  let formattedResult;

  if ([1, 2, 3, 4].includes(years % 10) && !(years >= 11 && years <= 14)) {
    if (isBirthday) {
      formattedResult = `<div class="asdasdas"><img class="birthday-icon" style="margin-right: 15px; margin-left:0; filter: invert(${
        colorScheme === "light" ? 0 : 1
      })" width="30px" height="30px" src="/assets/birthday-cake-svgrepo-com.svg"><span>${years} года | ${moment(
        birthdate,
        moment.ISO_8601,
        true
      ).format("DD.MM.YYYY")}</span></div>`;
    } else {
      if (upcomingBirthdayDays < 7) {
        formattedResult = `<div class="asdasdas"><img class="birthday-icon" style="margin-right: 15px; margin-left:0; filter: invert(${
          colorScheme === "light" ? 0 : 1
        })" width="30px" height="30px" src="/assets/birthday-cake-svgrepo-com.svg"><span>${
          upcomingBirthdayDays + 1
        } дней | ${years} года | ${moment(
          birthdate,
          moment.ISO_8601,
          true
        ).format("DD.MM.YYYY")}</span></div>`;
      } else {
        formattedResult = `<div class="asdasdas"><span>${years} года | ${moment(
          birthdate,
          moment.ISO_8601,
          true
        ).format("DD.MM.YYYY")}</span></div>`;
      }
    }
  } else {
    if (isBirthday) {
      formattedResult = `<div class="asdasdas"><img class="birthday-icon" style="margin-right: 15px; margin-left:0; filter: invert(${
        colorScheme === "light" ? 0 : 1
      })" width="30px" height="30px" src="/assets/birthday-cake-svgrepo-com.svg"><span>${years} лет | ${moment(
        birthdate,
        moment.ISO_8601,
        true
      ).format("DD.MM.YYYY")}</span></div>`;
    } else {
      if (upcomingBirthdayDays < 7) {
        formattedResult = `<div class="asdasdas"><img class="birthday-icon" style="margin-right: 15px; margin-left:0; filter: invert(${
          colorScheme === "light" ? 0 : 1
        })" width="30px" height="30px" src="/assets/birthday-cake-svgrepo-com.svg"><span>${
          upcomingBirthdayDays + 1
        } дней | ${years} лет | ${moment(
          birthdate,
          moment.ISO_8601,
          true
        ).format("DD.MM.YYYY")}</span></div>`;
      } else {
        formattedResult = `<div class="asdasdas"><span>${years} лет | ${moment(
          birthdate,
          moment.ISO_8601,
          true
        ).format("DD.MM.YYYY")}</span></div>`;
      }
    }
  }

  return parse(typeof formattedResult === "string" ? formattedResult : "");
}
function calculateAge(birthdate) {
  const today = moment();
  const birthdateMoment = moment(birthdate, moment.ISO_8601, true);
  if (!birthdateMoment.isValid()) {
    return "Неверная дата";
  }
  const years = today.diff(birthdateMoment, "years");
  birthdateMoment.add(years, "years");
  let formattedResult = years;
  return formattedResult;
}

const customColors = {
  titleColor: "#2ea7c1",
  textColor: "#666",
};

export default function Users() {
  const { user } = useUser();
  const { lang } = useLang();
  let { userWorkingStatus } = useParams();
  console.log("🚀 ~ Users ~ userWorkingStatus:", userWorkingStatus);
  const { colorScheme } = useMantineColorScheme();
  const [positions, setpositions] = useState([]);
  const [departments, setdepartments] = useState([]);
  const [roles, setroles] = useState([]);
  const [isBusy, setBusy] = useState(true);
  const [columns, setColumns] = useState([]);

  let renderDetailPanel = ({ row }) => (
    <Box
      style={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: "16px",
        padding: "16px",
      }}
    >
      <Link to={`/user/${row.original._id}`}>
        <Avatar
          variant="filled"
          radius="sm"
          h={200}
          w={200}
          src={`${config.api.API_URL}/${row.original.uploads?.[0]?.path}`}
        />
      </Link>
      <Box sx={{ textAlign: "center" }}>
        <Flex justify="flex-center">
          <Title style={{ color: customColors.titleColor }}>
            {row.original.name[lang]}
          </Title>
        </Flex>
        <Flex justify="flex-start">
          <Text fw={700} style={{ color: customColors.textColor }}>
            {config.translate.position[lang]}
          </Text>
          <Text style={{ color: customColors.textColor }}>
            : {row.original.position?.name[lang]}
          </Text>
        </Flex>
        <Flex justify="flex-start">
          <Text fw={700} style={{ color: customColors.textColor }}>
            {config.translate.department[lang]}
          </Text>
          <Text style={{ color: customColors.textColor }}>
            : {row.original.department?.name[lang]}
          </Text>
        </Flex>
        <Flex justify="flex-start">
          <Text fw={700} style={{ color: customColors.textColor }}>
            {config.translate.pc[lang]}
          </Text>
          <Text style={{ color: customColors.textColor }}>
            : {row.original.pc?.name}
          </Text>
        </Flex>
        <Flex justify="flex-start">
          <Text fw={700} style={{ color: customColors.textColor }}>
            {config.translate.email[lang]}
          </Text>
          <Text style={{ color: customColors.textColor }}>
            : {row.original.email}
          </Text>
        </Flex>
        <Flex justify="flex-start">
          <Text fw={700} style={{ color: customColors.textColor }}>
            {config.translate.phone[lang]}
          </Text>
          <Text style={{ color: customColors.textColor }}>
            : {row.original.phone}
          </Text>
        </Flex>
        <Flex justify="flex-start">
          <Text fw={700} style={{ color: customColors.textColor }}>
            {config.translate.dateOfBirth[lang]}
          </Text>
          <Text style={{ color: customColors.textColor }}>
            : {moment(row.original.dateOfBirth).format("MM.DD.YYYY")}
          </Text>
        </Flex>
      </Box>
    </Box>
  );

  function setRefreshColumns() {
    return [
      {
        accessorKey: "index",
        permissionModel: "index",
        header: config.translate.index[lang],
        enableClickToCopy: true,
        size: 30,
        Cell: (
          RecievedObject,
          cell = RecievedObject.renderedCellValue,
          row = RecievedObject.row.original
        ) => {
          let result = row.index ? row.index : "...";
          return result.length > 20 ? result.slice(0, 20) + "..." : result;
        },
      },
      {
        accessorFn: (row) =>
          `${row.firstName.en} ${row.firstName.ru} ${row.firstName.hy}`,
        permissionModel: "firstName",
        size: 500,
        header: config.translate.firstName[lang],
        // filterVariant: "multi-select",
        Cell: (
          RecievedObject,
          cell = RecievedObject.renderedCellValue,
          row = RecievedObject.row.original
        ) => {
          let result = parse(row.firstName[lang] ? row.firstName[lang] : "...");
          return result.length > 20 ? result.slice(0, 20) + "..." : result;
        },
      },
      {
        accessorFn: (row) =>
          `${row.lastName.en} ${row.lastName.ru} ${row.lastName.hy}`,
        permissionModel: "lastName",
        size: 120,
        header: config.translate.lastName[lang],
        // filterVariant: "multi-select",
        Cell: (
          RecievedObject,
          cell = RecievedObject.renderedCellValue,
          row = RecievedObject.row.original
        ) => {
          let result = parse(row.lastName[lang] ? row.lastName[lang] : "...");
          return result.length > 20 ? result.slice(0, 20) + "..." : result;
        },
      },
      {
        accessorKey: "phone",
        permissionModel: "phone",
        enableColumnActions: false,
        header: config.translate.more[lang],
        Cell: (
          RecievedObject,
          cell = RecievedObject.renderedCellValue,
          row = RecievedObject.row.original
        ) => {
          let result = "";
          if (row.phone && row.phone?.length > 0) {
            result = (
              <div
                className="icon-comment-wrapper"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <a
                  style={{ marginRight: "40px" }}
                  href={`tel:${parsePhoneNumber(row.phone)}`}
                >
                  <Image
                    w="20"
                    h="20"
                    className="icon-comment"
                    src="/assets/phone-call-svgrepo-com.svg"
                    alt="phone"
                    style={{
                      filter: colorScheme === "dark" ? "invert(1)" : "",
                    }}
                  />
                </a>
                <a style={{ marginRight: "40px" }} href={`mailto:${row.email}`}>
                  <Image
                    w="20"
                    h="20"
                    className="icon-comment"
                    src="/assets/email-svgrepo-com.svg"
                    alt="email"
                    style={{
                      filter: colorScheme === "dark" ? "invert(1)" : "",
                    }}
                  />
                </a>
                <a
                  style={{ marginRight: "40px" }}
                  href={`https://chat.apit.am/direct/${row.chatID}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Image
                    w="20"
                    h="20"
                    className="icon-comment"
                    src="/assets/message-circle-chat-svgrepo-com.svg"
                    alt="chat"
                    style={{
                      filter: colorScheme === "dark" ? "invert(1)" : "",
                    }}
                  />
                </a>
              </div>
            );
          }
          return result;
        },
      },
      {
        accessorFn: (row) =>
          `${row.position?.name?.hy} ${row.position?.name?.ru} ${row.position?.name?.en}`,
        permissionModel: "position",
        header: config.translate.position[lang],
        filterVariant: "select",
        mantineFilterSelectProps: {
          data: [...new Set(positions.flatMap((pos) => pos.name?.[lang]))],
        },
        size: 200,
        columnSizing: true,
        Cell: (
          RecievedObject,
          cell = RecievedObject.renderedCellValue,
          row = RecievedObject.row.original
        ) => {
          let result = parse(
            row?.position?.name[lang] ? row.position.name[lang] : "..."
          );
          return result.length > 20 ? result.slice(0, 20) + "..." : result;
        },
      },
      {
        accessorFn: (row) =>
          `${row.department?.name?.hy} ${row.department?.name?.ru} ${row.department?.name?.en}`,
        permissionModel: "department",
        header: config.translate.department[lang],
        filterVariant: "select",
        mantineFilterSelectProps: {
          data: [...new Set(departments.flatMap((dep) => dep.name?.[lang]))],
        },
        size: 300,
        columnSizing: true,
        Cell: (
          RecievedObject,
          cell = RecievedObject.renderedCellValue,
          row = RecievedObject.row.original
        ) => {
          let result = parse(
            row?.department?.name[lang] ? row.department.name[lang] : "..."
          );
          return result.length > 30 ? result.slice(0, 30) + "..." : result;
        },
      },
      {
        accessorKey: "dateOfBirth",
        permissionModel: "dateOfBirth",
        header: config.translate.dateOfBirth[lang],
        enableClickToCopy: true,
        filterVariant: "date",
        size: 100,
        Cell: (
          RecievedObject,
          cell = RecievedObject.renderedCellValue,
          row = RecievedObject.row.original
        ) => {
          let result = moment(row.dateOfBirth).format("MM.DD.YYYY");
          return result;
        },
      },
      {
        accessorKey: "age",
        permissionModel: "dateOfBirth",
        header: config.translate.age[lang],
        filterVariant: "range",
        filterFn: "between",
        size: 200,
        Cell: (
          RecievedObject,
          cell = RecievedObject.renderedCellValue,
          row = RecievedObject.row.original
        ) => {
          let result = calculateAge(row.dateOfBirth);
          return result;
        },
      },
    ];
  }

  useEffect(() => {
    NProgress.start();
    (async () => {
      let rawDepartments = await API.get(config.api.department);
      if (rawDepartments) setdepartments(rawDepartments);
      let rawPositions = await API.get(config.api.position);
      if (rawPositions) setpositions(rawPositions);
      let rawRoles = await API.get(config.api.role);
      if (rawRoles) setroles(rawRoles);

      setColumns(setRefreshColumns());

      setBusy(false);
    })();
    // eslint-disable-next-line
  }, [isBusy]);

  useEffect(() => {
    setColumns(setRefreshColumns());
  }, [lang, colorScheme]);

  if (!isBusy && columns.length > 0)
    return (
      <ListPage
        route={config.api.user}
        query={
          userWorkingStatus === "all"
            ? {}
            : {
                workingStatus: userWorkingStatus
                  ? userWorkingStatus
                  : "working",
              }
        }
        customFiltersArrays={{
          position: positions,
          department: departments,
          role: roles,
        }}
        sorting={{ index: "asc" }}
        uploadType={true}
        limit={null}
        populate={["position", "pc", "department"]}
        permissionModel={"user"}
        columns={columns}
        renderDetailPanel={renderDetailPanel}
      />
    );
  else return <Loading />;
}
