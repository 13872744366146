import React, { useMemo } from "react";
import { ListPage } from "../ListPage";
import { useUser } from "../../helpers/userContext";
import { useLang } from "../../helpers/language";
import parse from "html-react-parser";
import config from "../../config";
export default function Softwares() {
  const { user } = useUser();
  const { lang } = useLang();
  const columns = [
    {
      accessorKey: "index",
      permissionModel: "index",
      header: "Index",
      size: 30,
    },
    {
      accessorFn: (row) => `${row?.name}`,
      permissionModel: "name",
      header: config.translate.name[lang],
      enableClickToCopy: true,
      grow: true,
      Cell: (
        RecievedObject,
        cell = RecievedObject.renderedCellValue,
        row = RecievedObject.row.original
      ) => {
        let result = parse(row.name ? row.name : "...");
        return result.length > 20 ? result.slice(0, 20) + "..." : result;
      },
    },
  ];
  return (
    <ListPage
      route={config.api.software}
      query={{}}
      sorting={{ index: "asc" }}
      permissionModel={"software"}
      uploadType={true}
      limit={null}
      columns={columns}
    />
  );
}
