import { Flex, Skeleton } from "@mantine/core";
import React from "react";

export default function Loading() {
  return (
    <Flex>
      <Skeleton
        style={{
          height: "calc(100vh - 290px)",
          width: "220px",
          marginRight: "20px",
        }}
      />
      <Skeleton
        style={{ height: "calc(100vh - 290px)", width: "calc(100% - 240px)" }}
      />
    </Flex>
  );
}
