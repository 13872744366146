import React, { useState, useEffect } from "react";
import SinglePage from "../SinglePage";
import { useParams } from "react-router-dom";
import * as API from "../../helpers/api";
import config from "../../config";
import Loading from "../modules/loading";
import { useLang } from "../../helpers/language";
import { useUser } from "../../helpers/userContext";
import NProgress from "nprogress";

export default function Position() {
  const [isBusy, setBusy] = useState(true);
  let id = useParams().id;
  const route = config.api.position;
  const { lang } = useLang();

  const [index, setindex] = useState("");
  const [nameEn, setNameEn] = useState("");
  const [nameRu, setNameRu] = useState("");
  const [nameHy, setNameHy] = useState("");
  const [descEn, setDescEn] = useState("");
  const [descRu, setDescRu] = useState("");
  const [descHy, setDescHy] = useState("");
  const { user } = useUser();
  const [permissions, setPermissions] = useState([]);

  const modelSendToServer = {
    index,
    name: {
      en: nameEn,
      ru: nameRu,
      hy: nameHy,
    },
    desc: {
      en: descEn,
      ru: descRu,
      hy: descHy,
    },
  };

  useEffect(() => {
    (async () => {
      NProgress.start();
      if (id !== "add") {
        let currentItem = await API.get(route, {
          _id: id,
        });
        setindex(currentItem[0]?.index);
        setNameEn(currentItem[0]?.name?.en);
        setNameRu(currentItem[0]?.name?.ru);
        setNameHy(currentItem[0]?.name?.hy);
        setDescEn(currentItem[0]?.desc?.en);
        setDescRu(currentItem[0]?.desc?.ru);
        setDescHy(currentItem[0]?.desc?.hy);
      }
      setBusy(false);
    })();
    // eslint-disable-next-line
  }, [isBusy]);

  useEffect(() => {
    let tempPermissions = [];
    user?.role?.permissions?.forEach((permission) => {
      if (permission.name === "ticket") {
        permission.inputs.forEach((input) => {
          tempPermissions.push(input);
        });
      }
    });
    setPermissions([...tempPermissions]);
  }, []);

  if (isBusy) return <Loading />;
  else
    return (
      <SinglePage
        isBusy={isBusy}
        setBusy={setBusy}
        route={route}
        permissionModel="position"
        id={id}
        permissions={permissions}
        modelSendToServer={modelSendToServer}
        inputs={[
          {
            value: index,
            setter: setindex,
            type: "number",
            usageType: "number",
            label: config.translate.index[lang],
            placeholder: "Index",
            permissionModel: "index",
          },
          {
            value: nameEn,
            setter: setNameEn,
            type: "text",
            usageType: "textfield",
            label: "Name",
            placeholder: config.translate.inputInLatin[lang],
            lang: "en",
            permissionModel: "name",
          },
          {
            value: nameRu,
            setter: setNameRu,
            type: "text",
            usageType: "textfield",
            label: "Имя",
            placeholder: config.translate.inputInRussian[lang],
            lang: "ru",
            permissionModel: "name",
          },
          {
            value: nameHy,
            setter: setNameHy,
            type: "text",
            usageType: "textfield",
            label: "Անուն",
            placeholder: config.translate.inputInArmenian[lang],
            lang: "hy",
            permissionModel: "name",
          },
          {
            value: descEn,
            setter: setDescEn,
            type: "text",
            usageType: "textarea",
            label: "Description",
            placeholder: config.translate.inputInLatin[lang],
            lang: "en",
            width: 12,
            permissionModel: "desc",
          },
          {
            value: descRu,
            setter: setDescRu,
            type: "text",
            usageType: "textarea",
            label: "Описание",
            placeholder: config.translate.inputInRussian[lang],
            lang: "ru",
            width: 12,
            permissionModel: "desc",
          },
          {
            value: descHy,
            setter: setDescHy,
            type: "text",
            usageType: "textarea",
            label: "Նկարագրություն",
            placeholder: config.translate.inputInArmenian[lang],
            lang: "hy",
            width: 12,
            permissionModel: "desc",
          },
        ]}
      />
    );
}
