import React, { useState, useEffect, useMemo } from "react";
import SinglePage from "../SinglePage";
import { useParams } from "react-router-dom";
import * as API from "../../helpers/api";
import config from "../../config";
import Loading from "../modules/loading";
import { useUser } from "../../helpers/userContext";
import moment from "moment";
import parse from "html-react-parser";
import { useLang } from "../../helpers/language";
import NProgress from "nprogress";

const parsePhoneNumber = (number) =>
  `+374${number
    .split(/[-*\/\s]+/)
    .join("")
    .slice(1)}`;

export default function Branch() {
  const [isBusy, setBusy] = useState(true);
  let id = useParams().id;
  const route = config.api.branch;
  const { lang } = useLang();
  const { user } = useUser();

  const [index, setindex] = useState("");
  const [nameEn, setNameEn] = useState("");
  const [nameRu, setNameRu] = useState("");
  const [nameHy, setNameHy] = useState("");

  // const [users, setUsers] = useState([]);
  const [allUsers, setAllUsers] = useState([]);

  // users
  const [hamadzaynecnox, setHamadzaynecnox] = useState("");
  const [texaznnox, setTexazznox] = useState("");
  const [shagrgirPatasxanatu, setShagrgirPatasxanatu] = useState("");
  const [hamaynqiPatasxanatu, setHamaynqiPatasxanatu] = useState("");
  const [permissions, setPermissions] = useState([]);

  const modelSendToServer = {
    index,
    name: {
      en: nameEn,
      ru: nameRu,
      hy: nameHy,
    },
    users: [
      {
        user: hamadzaynecnox && hamadzaynecnox,
        step: 1,
        role: "hamadzaynecnox",
      },
      {
        user: texaznnox && texaznnox,
        step: 1,
        role: "texaznnox",
      },
      {
        user: shagrgirPatasxanatu && shagrgirPatasxanatu,
        step: 6,
        role: "shagrgirPatasxanatu",
      },
      {
        user: hamaynqiPatasxanatu && hamaynqiPatasxanatu,
        step: 6,
        role: "hamaynqiPatasxanatu",
      },
    ],
  };

  useEffect(() => {
    (async () => {
      NProgress.start();
      // all users
      let rawusers = await API.get(config.api.user, {
        temprorary: { $ne: true },
        workingStatus: "working",
      });
      setAllUsers(rawusers);

      if (id !== "add") {
        let currentItem = await API.get(
          route,
          {
            _id: id,
          },
          null,
          null,
          null,
          ["users.user"]
        );
        setindex(currentItem[0]?.index);
        setNameEn(currentItem[0]?.name?.en);
        setNameRu(currentItem[0]?.name?.ru);
        setNameHy(currentItem[0]?.name?.hy);

        currentItem[0]?.users?.forEach((user) => {
          if (user.role === "hamadzaynecnox") setHamadzaynecnox(user.user._id);
          if (user.role === "texaznnox") setTexazznox(user.user._id);
          if (user.role === "shagrgirPatasxanatu")
            setShagrgirPatasxanatu(user.user._id);
          if (user.role === "hamaynqiPatasxanatu")
            setHamaynqiPatasxanatu(user.user._id);
        });
      }
      setBusy(false);
    })();
    // eslint-disable-next-line
  }, [isBusy]);

  useEffect(() => {
    let tempPermissions = [];
    user?.role?.permissions?.forEach((permission) => {
      if (permission.name === "ticket") {
        permission.inputs.forEach((input) => {
          tempPermissions.push(input);
        });
      }
    });
    setPermissions([...tempPermissions]);
  }, []);

  if (isBusy) return <Loading />;
  else
    return (
      <SinglePage
        isBusy={isBusy}
        setBusy={setBusy}
        route={route}
        id={id}
        permissions={permissions}
        permissionModel="branch"
        modelSendToServer={modelSendToServer}
        inputs={[
          {
            value: index,
            setter: setindex,
            type: "number",
            usageType: "number",
            label: config.translate.index[lang],
            placeholder: config.translate.index[lang],
            permissionModel: "index",
          },
          {
            value: nameEn,
            setter: setNameEn,
            type: "text",
            usageType: "textfield",
            label: config.translate.name[lang],
            placeholder: config.translate.inputInLatin[lang],
            lang: "en",
            permissionModel: "name",
          },
          {
            value: nameRu,
            setter: setNameRu,
            type: "text",
            usageType: "textfield",
            label: config.translate.name[lang],
            placeholder: config.translate.inputInRussian[lang],
            lang: "ru",
            permissionModel: "name",
          },
          {
            value: nameHy,
            setter: setNameHy,
            type: "text",
            usageType: "textfield",
            label: config.translate.name[lang],
            placeholder: config.translate.inputInArmenian[lang],
            lang: "hy",
            permissionModel: "name",
          },
          {
            value: hamadzaynecnox,
            setter: setHamadzaynecnox,
            type: "optionlist",
            usageType: "optionlist",
            optionListValue: allUsers,
            label: config.translate.hamadzaynecnox[lang],
            permissionModel: "users",
          },
          {
            value: texaznnox,
            setter: setTexazznox,
            type: "optionlist",
            usageType: "optionlist",
            optionListValue: allUsers,
            label: config.translate.texaznnox[lang],
            permissionModel: "users",
          },
          {
            value: shagrgirPatasxanatu,
            setter: setShagrgirPatasxanatu,
            type: "optionlist",
            usageType: "optionlist",
            optionListValue: allUsers,
            label: config.translate.shagrgirPatasxanatu[lang],
            permissionModel: "users",
          },
          {
            value: hamaynqiPatasxanatu,
            setter: setHamaynqiPatasxanatu,
            type: "optionlist",
            usageType: "optionlist",
            optionListValue: allUsers,
            label: config.translate.hamaynqiPatasxanatu[lang],
            permissionModel: "users",
          },
        ]}
      />
    );
}
