import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useConnectedUser } from "../../helpers/connectedUserContext";
import { Link } from "react-router-dom";
import config from "../../config";
import { useLang } from "../../helpers/language";
import { useDisclosure } from "@mantine/hooks";
import {
  Avatar,
  Text,
  Group,
  Card,
  Grid,
  Modal,
  Button,
  List,
  Image,
  Flex,
  NavLink,
  ScrollArea,
  useMantineColorScheme,
  Accordion,
} from "@mantine/core";
import {
  IconPhoneCall,
  IconAt,
  IconHistory,
  IconLogout,
  IconSettings,
  IconNotification,
} from "@tabler/icons-react";

export default function Notifications() {
  const [notificationOpened, setnotificationOpened] = useState(false);
  const { socket, notifications, setnotifications } = useConnectedUser();
  const audio = useRef(null);
  const { lang } = useLang();
  const { colorScheme } = useMantineColorScheme();
  const [opened, OpenClose] = useDisclosure(false);

  useEffect(() => {
    audio?.current?.play();
  }, [notifications]);

  return (
    <>
      <Button
        justify="center"
        width
        onClick={OpenClose.toggle}
        p={5}
        size="xs"
        leftSection={
          <IconNotification
            size="1rem"
            color={colorScheme === "dark" ? "white" : "black"}
          />
        }
        variant="default"
        mt={5}
      >
        {config.translate.notifications[lang]}
      </Button>
      <Modal
        opened={opened}
        onClose={OpenClose.close}
        title={config.translate.notifications[lang]}
        centered
      >
        <Card withBorder shadow="sm" radius="md">
          <Card.Section withBorder inheritPadding py="xs">
            {notifications.length > 0 ? (
              <ScrollArea
                style={{ maxHeight: "50vh" }}
                className="mantine-scroll-area"
              >
                <List>
                  {notifications.map((notification, index) => (
                    <List.Item
                      key={index}
                      // onClick={() => {
                      //   setnotificationOpened(notification);
                      // }}
                      hover
                    >
                      <Grid>
                        <Grid.Col span={1}>
                          <Avatar
                            src={`${config.api.API_URL}/${notification?.createdByUser?.avatar}`}
                            alt={notification?.createdByUser?.name}
                            radius="xl"
                            size="lg"
                          />
                        </Grid.Col>
                        <Grid.Col span={11}>
                          <Group>
                            <Text>
                              <strong>{notification.title?.[lang]}</strong>
                            </Text>
                            <Text>{notification?.desc?.[lang]}</Text>
                            <Text size="xs" color="white">
                              {moment(notification?.date).fromNow()}
                            </Text>
                          </Group>
                        </Grid.Col>
                      </Grid>
                    </List.Item>
                  ))}
                </List>
              </ScrollArea>
            ) : (
              <Text align="center" size="xl">
                {config.translate.noNotifications[lang]}
              </Text>
            )}
          </Card.Section>
        </Card>
      </Modal>
    </>
  );
}
