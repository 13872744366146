import React, { useState, useEffect } from "react";
import SinglePage from "../SinglePage";
import { useParams } from "react-router-dom";
import * as API from "../../helpers/api";
import config from "../../config";
import Loading from "../modules/loading";
import { useLang } from "../../helpers/language";
import { useUser } from "../../helpers/userContext";
import NProgress from "nprogress";

export default function Printer() {
  const [isBusy, setBusy] = useState(true);
  let id = useParams().id;
  const route = config.api.printer;
  const { lang } = useLang();
  const { user } = useUser();

  const [index, setindex] = useState("");
  const [name, setName] = useState("");

  const [ip, setip] = useState("");
  const [mac, setmac] = useState("");
  const [printedCount, setprintedCount] = useState("");

  const [rooms, setrooms] = useState("");
  const [room, setroom] = useState("");

  const [pcs, setpcs] = useState("");
  const [pc, setpc] = useState("");

  const [image, setimage] = useState("");
  const [file, setfile] = useState("");
  const [permissions, setPermissions] = useState([]);

  const modelSendToServer = {
    index,
    name,
    ip,
    mac,
    room,
    pc,
    printedCount,
  };

  useEffect(() => {
    (async () => {
      NProgress.start();
      let rawRooms = await API.get(config.api.room);
      if (rawRooms) {
        setrooms(rawRooms);
      }
      let rawPCs = await API.get(config.api.pc);
      if (rawPCs) {
        setpcs(rawPCs);
      }
      if (id !== "add") {
        let currentItem = await API.get(
          route,
          {
            _id: id,
          },
          {},
          {},
          1,
        );
        setindex(currentItem[0]?.index);
        setName(currentItem[0]?.name);
        setip(currentItem[0]?.ip);
        setmac(currentItem[0]?.mac);
        setroom(currentItem[0]?.room);
        setpc(currentItem[0]?.pc);
        setprintedCount(currentItem[0]?.printedCount);

        setimage(undefined);
        currentItem[0]?.uploads?.map((item) => {
          if (item.destiny === "image") setimage(item);
          if (item.destiny === "file") setfile(item);
          return item;
        });
      }
      setBusy(false);
    })();
    // eslint-disable-next-line
  }, [isBusy]);

  useEffect(() => {
    let tempPermissions = [];
    user?.role?.permissions?.forEach((permission) => {
      if (permission.name === "ticket") {
        permission.inputs.forEach((input) => {
          tempPermissions.push(input);
        });
      }
    });
    setPermissions([...tempPermissions]);
  }, []);

  if (isBusy) return <Loading />;
  else
    return (
      <SinglePage
        isBusy={isBusy}
        setBusy={setBusy}
        route={route}
        permissionModel="printer"
        id={id}
        permissions={permissions}
        modelSendToServer={modelSendToServer}
        inputs={[
          {
            value: index,
            setter: setindex,
            type: "number",
            usageType: "number",
            label: config.translate.index[lang],
            placeholder: config.translate.index[lang],
            permissionModel: "index",
          },
          {
            value: name,
            setter: setName,
            type: "text",
            usageType: "textfield",
            label: config.translate.name[lang],
            placeholder: config.translate.inputInLatin[lang],
            permissionModel: "name",
          },
          {
            value: ip,
            setter: setip,
            type: "text",
            usageType: "textfield",
            label: config.translate.ipAddress[lang],
            placeholder: config.translate.inputInLatin[lang],
            permissionModel: "ip",
          },
          {
            value: mac,
            setter: setmac,
            type: "text",
            usageType: "textfield",
            label: config.translate.macAddress[lang],
            placeholder: config.translate.inputInLatin[lang],
            permissionModel: "mac",
          },
          {
            value: printedCount,
            setter: setprintedCount,
            type: "text",
            usageType: "textfield",
            label: config.translate.printedCount[lang],
            placeholder: config.translate.inputInLatin[lang],
            permissionModel: "printedCount",
          },
          {
            value: room,
            setter: setroom,
            optionListValue: rooms,
            type: "optionlist",
            usageType: "optionlist",
            label: config.translate.room[lang],
            permissionModel: "room",
          },
          {
            value: pc,
            setter: setpc,
            optionListValue: pcs,
            type: "optionlist",
            usageType: "optionlist",
            label: config.translate.pc[lang],
            permissionModel: "pc",
          },
        ]}
        filesComponent={[
          {
            destiny: "image",
            file: image,
            setFile: setimage,
            filesUploadLimit: 1,
            acceptedFiles: ["image/*"],
            buttonAddText: "Add",
            buttonOpenText: "Open",
            buttonRemoveText: "Remove",
            dropzoneText: "There is no file, click to upload a new one",
            adminMode: user.role !== "editor",
            permissionModel: "image",
          },
          {
            destiny: "file",
            file: file,
            setFile: setfile,
            filesUploadLimit: 1,
            acceptedFiles: ["application/*"],
            buttonAddText: "Add",
            buttonOpenText: "Open",
            buttonRemoveText: "Remove",
            dropzoneText: "There is no file, click to upload a new one",
            adminMode: user.role !== "editor",
            permissionModel: "file",
          },
        ]}
      />
    );
}
