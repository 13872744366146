import React, { useState, useEffect } from "react";
import SinglePage from "../SinglePage";
import { useParams } from "react-router-dom";
import * as API from "../../helpers/api";
import config from "../../config";
import Loading from "../modules/loading";
import { useLang } from "../../helpers/language";
import { useUser } from "../../helpers/userContext";
import NProgress from "nprogress";

export default function Problem() {
  const [isBusy, setBusy] = useState(true);
  let id = useParams().id;
  const route = config.api.problem;
  const { lang } = useLang();
  const [nameEn, setNameEn] = useState("");
  const [nameRu, setNameRu] = useState("");
  const [nameHy, setNameHy] = useState("");
  const [index, setindex] = useState("");
  const [relatedList, setRelatedList] = useState("");
  const { user } = useUser();
  const [permissions, setPermissions] = useState([]);

  const listItems = [
    {
      name: {
        en: "Printer",
        ru: "Принтер",
        hy: "Տպիչ",
      },
      value: "printer",
    },
    {
      name: {
        en: "Software",
        ru: "Программное обеспечение",
        hy: "Համակարգային ծրագիր",
      },
      value: "software",
    },
    {
      name: {
        en: "SMB",
        ru: "SMB",
        hy: "SMB",
      },
      value: "smb",
    },
  ];

  const modelSendToServer = {
    name: {
      en: nameEn,
      ru: nameRu,
      hy: nameHy,
    },
    index,
    relatedList,
  };

  useEffect(() => {
    (async () => {
      NProgress.start();
      if (id !== "add") {
        let currentItem = await API.get(route, {
          _id: id,
        });

        setNameEn(currentItem[0]?.name?.en);
        setNameRu(currentItem[0]?.name?.ru);
        setNameHy(currentItem[0]?.name?.hy);
        setindex(currentItem[0]?.index);

        listItems.forEach((item) => {
          if (item.value === currentItem[0]?.relatedList) {
            setRelatedList(item.value);
          }
        });
      }
      setBusy(false);
    })();
    // eslint-disable-next-line
  }, [isBusy]);

  useEffect(() => {
    let tempPermissions = [];
    user?.role?.permissions?.forEach((permission) => {
      if (permission.name === "ticket") {
        permission.inputs.forEach((input) => {
          tempPermissions.push(input);
        });
      }
    });
    setPermissions([...tempPermissions]);
  }, []);

  if (isBusy) return <Loading />;
  else
    return (
      <SinglePage
        isBusy={isBusy}
        setBusy={setBusy}
        route={route}
        permissions={permissions}
        permissionModel="problem"
        id={id}
        modelSendToServer={modelSendToServer}
        inputs={[
          {
            value: index,
            setter: setindex,
            type: "number",
            usageType: "number",
            label: config.translate.index[lang],
            placeholder: config.translate.index[lang],
            permissionModel: "index",
          },
          {
            value: nameEn,
            setter: setNameEn,
            type: "text",
            usageType: "textfield",
            label: config.translate.name[lang],
            placeholder: config.translate.inputInLatin[lang],
            lang: "en",
            permissionModel: "name",
          },
          {
            value: nameRu,
            setter: setNameRu,
            type: "text",
            usageType: "textfield",
            label: config.translate.name[lang],
            placeholder: config.translate.inputInRussian[lang],
            lang: "ru",
            permissionModel: "name",
          },
          {
            value: nameHy,
            setter: setNameHy,
            type: "text",
            usageType: "textfield",
            label: config.translate.name[lang],
            placeholder: config.translate.inputInArmenian[lang],
            lang: "hy",
            permissionModel: "name",
          },
          {
            value: relatedList,
            setter: setRelatedList,
            optionListValue: listItems,
            type: "optionlist",
            usageType: "optionlist",
            label: config.translate.relatedList[lang],
            permissionModel: "relatedList",
          },
        ]}
      />
    );
}
