import React, { useMemo } from "react";
import { ListPage } from "../ListPage";
import { useUser } from "../../helpers/userContext";
import { useLang } from "../../helpers/language";
import parse from "html-react-parser";
import config from "../../config";

export default function CityList() {
  const { user } = useUser();
  const { lang } = useLang();
  const columns = [
    {
      accessorFn: (row) => `${row.name.en} ${row.name.ru} ${row.name.hy}`,
      permissionModel: "name",
      header: config.translate.name[lang],
      Cell: (
        RecievedObject,
        cell = RecievedObject.renderedCellValue,
        row = RecievedObject.row.original
      ) => {
        let result = parse(row.name[lang] ? row.name[lang] : "...");
        return result;
      },
    },
    {
      accessorFn: (row) =>
        `${row?.branches[0]?.name?.en} ${row?.branches[0]?.name?.ru} ${row?.branches[0]?.name?.hy}`,
      permissionModel: "branches",
      header: config.translate.branch[lang],
      grow: true,
      Cell: (
        RecievedObject,
        cell = RecievedObject.renderedCellValue,
        row = RecievedObject.row.original
      ) => {
        let result = parse(
          row?.branches[0]?.name[lang]
            ? row?.branches.length > 0
              ? row.branches[0].name[lang]
              : row.branches[0].name[lang] + "..."
            : config.translate.noContent[lang]
        );
        return result;
      },
    },
  ];
  return (
    <ListPage
      route={config.api.city}
      query={{}}
      sorting={{ index: "asc" }}
      permissionModel={"city"}
      uploadType={true}
      limit={null}
      populate={["branches"]}
      columns={columns}
    />
  );
}
