import React, { useState, useRef, useEffect } from "react";
import config from "../../config";
// import Dropzone from "react-dropzone";
import * as API from "../../helpers/api";
import { useHistory } from "react-router-dom";
import Loading from "./loading";
import { Document, Page } from "react-pdf";
import Swal from "sweetalert2";
import slash from "slash";
import { Carousel, useAnimationOffsetEffect } from "@mantine/carousel";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";
import { DELETE } from "../../helpers/CRUD";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";

import {
  Group,
  Text,
  rem,
  Button,
  Image,
  Card,
  CardSection,
  useMantineColorScheme,
  Title,
  CloseIcon,
  useMantineTheme,
  Modal,
  FileInput,
  Grid,
  ScrollArea,
  SimpleGrid,
  Divider,
  Flex,
} from "@mantine/core";
import {
  IconUpload,
  IconPhoto,
  IconX,
  IconTrash,
  IconShare,
  IconShare2,
  IconArrowRight,
  IconArrowLeft,
  IconPlus,
  IconPictureInPictureOn,
} from "@tabler/icons-react";
import { Dropzone } from "@mantine/dropzone";
import { useLang } from "../../helpers/language";
import { IconShare3 } from "@tabler/icons-react";
import { useDisclosure } from "@mantine/hooks";
import moment from "moment";

export function FilesComponent({
  id,
  alt,
  setAlt,
  file,
  files,
  setBusy,
  setFile,
  setFiles,
  route,
  acceptedFiles,
  placeholder,
  destiny,
  thumbSizeWidth,
  thumbSizeHeight,
  responsiveImages,
  slider,
  filesMaxSize,
  width,
  height,
  filesUploadLimit,
  disabled,
  date,
  old,
  user,
  permissionModel,
  permissions,
}) {
  const [loading, setLoading] = useState(false);
  const [fullScreen, setFullScreen] = useState(false);
  const { colorScheme } = useMantineColorScheme();
  const Theme = useMantineTheme();
  const openRef = useRef(null);
  var fileId = "";
  const history = useHistory();
  const { lang } = useLang();
  const [opened, { open, close }] = useDisclosure(false);
  const [modalStartIndex, setModalSrartIndex] = useState(0);
  const [base64, setBase64] = useState(null);
  const [crud, setcrud] = useState(null);

  useEffect(() => {
    if (permissions) {
      let tempCrud = permissions.find(
        (permission) => (permission.name = permissionModel)
      );
      setcrud(tempCrud);
    }
  }, []);

  const setBase = async (files, oldBase64Array) => {
    let filePromises = files.map((file) => {
      return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = async function (e) {
          resolve(e.target.result);
        };
      });
    });
    const fileInfos = await Promise.all(filePromises);
    setBase64(oldBase64Array ? [...oldBase64Array, ...fileInfos] : fileInfos);
  };

  async function removeFile({ id, index }) {
    if (id) {
      Swal.fire({
        title: config.translate.areYouSure[lang],
        text: config.translate.youCannotRevertThisAction[lang],
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: config.translate.yes[lang],
      }).then(async (result) => {
        if (result.isConfirmed) {
          if (files) {
            try {
              await API.remove(config.api.upload, id);
              setLoading(false);
              setBusy(true);
              setFiles(null);
            } catch (err) {
              console.error(err);
              Swal.fire({
                title: "Error!",
                text: config.translate.swal.error[lang],
                icon: "error",
                confirmButtonText: "Ok",
              });
              setLoading(false);
            }
          } else if (file) {
            try {
              await API.remove(config.api.upload, file._id);
              setLoading(false);
              setFile(null);
              setBusy(true);
            } catch (err) {
              console.error(err);
              Swal.fire({
                title: "Error!",
                text: config.translate.swal.error[lang],
                icon: "error",
                confirmButtonText: "Ok",
              });
              setLoading(false);
            }
          }
          setBase64(null);
        }
      });
    } else if (index || index === 0) {
      Swal.fire({
        title: config.translate.areYouSure[lang],
        text: config.translate.youCannotRevertThisAction[lang],
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: config.translate.yes[lang],
      }).then(async (result) => {
        if (base64) {
          let tempBase64 = [...base64];
          tempBase64.splice(index, 1);
          setBase64(tempBase64);
          if (files && files.length > 1) {
            setFiles && setFiles(tempBase64);
          } else {
            setFile && setFile(tempBase64);
          }
        }
      });
    }
  }

  async function handleSave(files, lang, destiny, alt) {
    let Files = Array.from(files);
    console.log("🚀 ~ handleSave ~ Files:", Files);
    if (Files.length > 0) {
      setLoading(true);
      try {
        setBase(Files);
        if (Files.length > 1) {
          setFiles && setFiles(Files);
        } else {
          setFile && setFile(Files);
        }
      } catch (err) {
        console.error(err);
      }
      setLoading(false);
      // setBusy(true);
    } else {
      Swal.fire({
        title: "Error!",
        text: "Error with file selection!",
        icon: "error",
        confirmButtonText: "Ok",
      });
    }
  }

  const openInNewTab = (e) => {
    let win = window.open(`${config.api.API_URL}/${file.path}`, "_blank");
    win.focus();
  };

  const [map, setMap] = useState(null);

  const onLoad = (map) => {
    var src = config.api.API_URL + "/" + file.path;
    const kmlLayer = new window.google.maps.KmlLayer({
      url: src,
      map: map,
    });
    setMap(map);
  };

  const onUnmount = (map) => {
    setMap(null);
  };

  //!  Key	Mime type
  // png	image/png
  // gif	image/gif
  // jpeg	image/jpeg
  // svg	image/svg+xml
  // webp	image/webp
  // avif	image/avif
  // mp4	video/mp4
  // zip	application/zip
  // csv	text/csv
  // pdf	application/pdf
  // doc	application/msword
  // docx	application/vnd.openxmlformats-officedocument.wordprocessingml.document
  // xls	application/vnd.ms-excel
  // xlsx	application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
  // ppt	application/vnd.ms-powerpoint
  // pptx	application/vnd.openxmlformats-officedocument.presentationml.presentation
  // exe	application/vnd.microsoft.portable-executable

  //!   Variable	Mime types
  // IMAGE_MIME_TYPE	image/png, image/gif, image/jpeg, image/svg+xml, image/webp, image/avif
  // PDF_MIME_TYPE	application/pdf
  // MS_WORD_MIME_TYPE	application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document
  // MS_EXCEL_MIME_TYPE	application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
  // MS_POWERPOINT_MIME_TYPE	application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation

  useEffect(() => {}, [base64]);

  // modes
  // 1. file
  // 1. files
  // 2. image
  // 3. images
  // 4. video
  // 7. kml
  // 8. kmz
  // 9. pdf
  // 10. doc
  // 11. docx
  // 12. xls
  // 13. xlsx
  // 14. ppt
  // 15. pptx
  // 16. exe
  // 17. blob
  // 17. blobs

  return (
    <Card
      p={20}
      style={{
        maxWidth: width ? width + 40 : "100%",
        height: height ? height + 130 : "100%",
        margin: "0 auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        border: `1px solid ${
          colorScheme === "dark" ? Theme.colors.gray[7] : "white"
        }`,
      }}
    >
      <Title order={3} mb={10} style={{ textAlign: "center" }}>
        {placeholder
          ? placeholder
          : destiny
          ? destiny.charAt(0).toUpperCase() +
            destiny.slice(1) +
            " " +
            config.translate.file[lang]
          : " Files"}
      </Title>
      {/* <input
                value={alt || ""}
                onChange={handleChange}
                type="text"
                disabled={file ? true : false}
                className="form-control"
                placeholder="Alternative text for SEO optimization: Write text before upload file"
              /> */}

      {base64 &&
      base64.length === 1 &&
      (destiny === "image" || destiny === "images") ? (
        <Image
          src={base64?.[0]}
          w={width ? width : "100%"}
          h={height ? height : "100%"}
          radius="sm"
          objectFit={"contain"}
        />
      ) : base64?.length === 1 &&
        (destiny === "video" ||
          destiny === "itemVideo" ||
          destiny === "packageVideo") ? (
        <>
          <video src={base64?.[0]} controls style={{ width: "100%" }} />
        </>
      ) : base64 && base64.length === 1 ? (
        <>{file?.[0].name}</>
      ) : base64 && base64.length > 1 && destiny === "images" ? (
        <>
          <ScrollArea h={"calc(100% - 35px)"} w={"100%"}>
            <SimpleGrid cols={3} spacing="xs" verticalSpacing="xs">
              {base64.map((file, index) => {
                return (
                  <div style={{ position: "relative" }}>
                    <Image
                      src={`${file}`}
                      w={"100%"}
                      h={"140px"}
                      radius="sm"
                      style={{
                        objectFit: "cover",
                      }}
                    />
                    {!fullScreen && (
                      <>
                        <Button
                          onClick={() => removeFile({ index })}
                          disabled={disabled}
                          color="red"
                          id={fileId}
                          style={{
                            position: "absolute",
                            top: 5,
                            right: 5,
                            opacity: 0.8,
                            width: "20px",
                            height: "20px",
                            padding: "0",
                          }}
                        >
                          <CloseIcon
                            size={14}
                            color={colorScheme === "dark" ? "white" : "black"}
                            m={0}
                          />
                        </Button>
                      </>
                    )}
                  </div>
                );
              })}
            </SimpleGrid>
            <FileInput
              variant="filled"
              placeholder={config.translate.addFiles[lang]}
              valueComponent={() => config.translate.addFiles[lang]}
              leftSection={
                <IconPictureInPictureOn
                  style={{ width: rem(18), height: rem(18) }}
                  stroke={1.5}
                />
              }
              acceptedFiles={acceptedFiles}
              onChange={(files) => setBase(files, base64)}
              multiple={filesUploadLimit > 1 ? true : false}
              style={{
                position: "absolute",
                bottom: 10,
                left: 10,
                color: "var(--mantine-color-blue-6)",
              }}
            />
          </ScrollArea>
        </>
      ) : !disabled && file && file.path && !(files && files.length > 0) ? (
        file?.type === "application/pdf" ? (
          <embed
            type="application/pdf"
            width={width ? width : "100%"}
            height={height ? height : "100%"}
            src={`${config.api.API_URL}/${file.path}`}
            style={{ borderRadius: "5px", marginBottom: "10px" }}
          />
        ) : file?.type === "image/png" ||
          file?.type === "image/jpg" ||
          file?.type === "image/jpeg" ||
          file?.type === "image/JPEG" ? (
          <>
            <div style={{ position: "relative" }}>
              <Image
                src={`${config.api.API_URL}/${file.path}`}
                alt={`${file.alt || file.path || ""}`}
                w={width ? width : "100%"}
                h={height ? height : "100%"}
                radius="sm"
                objectFit={"contain"}
              />
              {!fullScreen && (
                <>
                  <Button
                    onClick={(e) => {
                      open(true);
                    }}
                    disabled={disabled}
                    color="cyan"
                    id={fileId}
                    style={{
                      position: "absolute",
                      bottom: 5,
                      right: 5,
                      opacity: 0.8,
                      width: "30px",
                      height: "30px",
                      padding: "0",
                    }}
                  >
                    <IconShare2
                      size={14}
                      color={colorScheme === "dark" ? "white" : "black"}
                      m={0}
                    />
                  </Button>
                </>
              )}
            </div>
            <Modal opened={opened} onClose={close} centered fullScreen>
              <Image
                src={`${config.api.API_URL}/${file.path}`}
                alt={`${file.alt || file.path || ""}`}
                radius="sm"
                objectFit={"contain"}
                style={{
                  width: "auto",
                  margin: " 0 auto",
                  height: "calc(100vh - 100px)",
                }}
              />
            </Modal>
          </>
        ) : file?.destiny === "kml" || file?.destiny === "kmz" ? (
          <GoogleMap
            mapContainerStyle={{
              width: width ? width : "100%",
              height: height ? height : "100%",
            }}
            // center={{ lat: 40.17, lng: 44.51 }}
            zoom={16}
            onLoad={onLoad}
            onUnmount={onUnmount}
            mapTypeId={"satellite"}
          ></GoogleMap>
        ) : file?.type.includes("video") ? (
          <video
            width={width ? width : "100%"}
            height={height ? height : "100%"}
            controls
          >
            <source
              src={`${config.api.API_URL}/${file.path}`}
              type={file.type}
            />
            Your browser does not support the video tag.
          </video>
        ) : (
          <p>{file?.name}</p>
        )
      ) : !disabled &&
        files &&
        files.length > 0 &&
        (files[0]?.type === "image/png" ||
          files[0]?.type === "image/jpg" ||
          files[0]?.type === "image/jpeg" ||
          files[0]?.type === "image/JPEG") ? (
        <>
          <ScrollArea h={"calc(100% - 35px)"} w={"100%"}>
            <SimpleGrid cols={3} spacing="xs" verticalSpacing="xs">
              {files.map((file, key) => {
                return (
                  <div key={key} style={{ position: "relative" }}>
                    <Image
                      src={`${config.api.API_URL}/${file.path}`}
                      alt={`${file.alt || file.path || ""}`}
                      w={"100%"}
                      h={"140px"}
                      radius="sm"
                      style={{
                        objectFit: "cover",
                      }}
                    />
                    {!fullScreen && (
                      <>
                        <Button
                          onClick={() => removeFile({ id: file._id })}
                          disabled={disabled}
                          color="red"
                          id={fileId}
                          style={{
                            position: "absolute",
                            top: 5,
                            right: 5,
                            opacity: 0.8,
                            width: "20px",
                            height: "20px",
                            padding: "0",
                          }}
                        >
                          <CloseIcon
                            size={14}
                            color={colorScheme === "dark" ? "white" : "black"}
                            m={0}
                          />
                        </Button>

                        <Button
                          onClick={(e) => {
                            open(true);
                            setModalSrartIndex(files.indexOf(file));
                          }}
                          disabled={disabled}
                          color="cyan"
                          id={fileId}
                          style={{
                            position: "absolute",
                            bottom: 5,
                            right: 5,
                            opacity: 0.8,
                            width: "30px",
                            height: "30px",
                            padding: "0",
                          }}
                        >
                          <IconShare2
                            size={14}
                            color={colorScheme === "dark" ? "white" : "black"}
                            m={0}
                          />
                        </Button>
                      </>
                    )}
                  </div>
                );
              })}
            </SimpleGrid>
            <FileInput
              variant="filled"
              placeholder={config.translate.addFiles[lang]}
              leftSection={
                <IconPictureInPictureOn
                  style={{ width: rem(18), height: rem(18) }}
                  stroke={1.5}
                />
              }
              onChange={(files) => handleSave(files, lang, destiny, alt)}
              multiple={filesUploadLimit > 1 ? true : false}
              style={{
                position: "absolute",
                bottom: 10,
                left: 10,
                color: "var(--mantine-color-blue-6)",
              }}
            />
          </ScrollArea>
          <Modal opened={opened} onClose={close} centered fullScreen>
            <ImageGallery
              startIndex={modalStartIndex}
              slideDuration={200}
              showThumbnails={true}
              items={files.map((file) => {
                file.thumbnailHeight = "100px";
                file.thumbnail = `${config.api.API_URL}/${file.path}`;
                file.renderItem = () => {
                  return (
                    <Image
                      src={`${config.api.API_URL}/${file.path}`}
                      alt={`${file.name}`}
                      w={"auto"}
                      m={"0 auto"}
                      h={"calc(100vh - 200px)"}
                      objectFit={"contain"}
                    />
                  );
                };
                file.renderThumbInner = () => {
                  return (
                    <Image
                      src={`${config.api.API_URL}/${file.path}`}
                      alt={`${file.name}`}
                      style={{
                        height: "100px",
                      }}
                    />
                  );
                };
                return file;
              })}
              onScreenChange={(fullScreen) => {
                if (fullScreen) {
                  document.body.style.overflow = "hidden";
                  setFullScreen(true);
                } else {
                  document.body.style.overflow = "auto";
                  setFullScreen(false);
                }
              }}
            />
          </Modal>
        </>
      ) : !disabled &&
        files &&
        files.length > 0 &&
        files[0]?.type === "application/pdf" ? (
        <>
          <ScrollArea h={"calc(100% - 35px)"} w={"100%"}>
            <SimpleGrid cols={3} spacing="xs" verticalSpacing="xs">
              {files.map((file) => {
                return (
                  <div style={{ position: "relative" }}>
                    <embed
                      type="application/pdf"
                      width={"100%"}
                      height={"140px"}
                      src={`${config.api.API_URL}/${file.path}`}
                      style={{
                        borderRadius: "5px",
                        marginBottom: "10px",
                      }}
                      onClick={(e) => {
                        open(true);
                        setModalSrartIndex(files.indexOf(file));
                      }}
                    />
                    {!fullScreen && (
                      <>
                        <Button
                          onClick={() => removeFile({ id: file._id })}
                          disabled={disabled}
                          color="red"
                          id={fileId}
                          style={{
                            position: "absolute",
                            top: 5,
                            right: 5,
                            opacity: 0.8,
                            width: "20px",
                            height: "20px",
                            padding: "0",
                          }}
                        >
                          <CloseIcon
                            size={14}
                            color={colorScheme === "dark" ? "white" : "black"}
                            m={0}
                          />
                        </Button>

                        <Button
                          onClick={(e) => {
                            open(true);
                            setModalSrartIndex(files.indexOf(file));
                          }}
                          disabled={disabled}
                          color="cyan"
                          id={fileId}
                          style={{
                            position: "absolute",
                            bottom: 5,
                            right: 5,
                            opacity: 0.8,
                            width: "30px",
                            height: "30px",
                            padding: "0",
                          }}
                        >
                          <IconShare2
                            size={14}
                            color={colorScheme === "dark" ? "white" : "black"}
                            m={0}
                          />
                        </Button>
                      </>
                    )}
                  </div>
                );
              })}
            </SimpleGrid>
            <FileInput
              variant="filled"
              placeholder={config.translate.addFiles[lang]}
              leftSection={
                <IconPictureInPictureOn
                  style={{ width: rem(18), height: rem(18) }}
                  stroke={1.5}
                />
              }
              onChange={(files) => handleSave(files, lang, destiny, alt)}
              multiple={filesUploadLimit > 1 ? true : false}
              style={{
                position: "absolute",
                bottom: 10,
                left: 10,
                color: "var(--mantine-color-blue-6)",
              }}
            />
          </ScrollArea>
          <Modal opened={opened} onClose={close} centered fullScreen>
            <ImageGallery
              startIndex={modalStartIndex}
              slideDuration={200}
              showThumbnails={false}
              items={files.map((file, key) => {
                file.renderItem = () => {
                  return (
                    <embed
                      type="application/pdf"
                      key={key}
                      style={{
                        width: "100%",
                        height: "calc(100vh - 100px)",
                      }}
                      src={`${config.api.API_URL}/${file.path}`}
                    />
                  );
                };
                return file;
              })}
              onScreenChange={(fullScreen) => {
                if (fullScreen) {
                  document.body.style.overflow = "hidden";
                  setFullScreen(true);
                } else {
                  document.body.style.overflow = "auto";
                  setFullScreen(false);
                }
              }}
            />
          </Modal>
        </>
      ) : (
        !disabled && (
          <>
            <Dropzone
              openRef={openRef}
              onDrop={(files) => handleSave(files, lang, destiny, alt)}
              onReject={(files) =>
                Swal.fire("Error!", "File is too big!", "error")
              }
              maxSize={5 * 1024 ** 2}
              accept={acceptedFiles}
              multiple={filesUploadLimit > 1 ? true : false}
            >
              <Group
                justify="center"
                gap="xl"
                mih={220}
                style={{ pointerEvents: "none" }}
              >
                <Dropzone.Accept>
                  <IconUpload
                    style={{
                      width: rem(52),
                      height: rem(52),
                      color: "var(--mantine-color-blue-6)",
                    }}
                    stroke={1.5}
                  />
                </Dropzone.Accept>
                <Dropzone.Reject>
                  <IconX
                    style={{
                      width: rem(52),
                      height: rem(52),
                      color: "var(--mantine-color-red-6)",
                    }}
                    stroke={1.5}
                  />
                </Dropzone.Reject>
                <Dropzone.Idle>
                  <IconPhoto
                    style={{
                      width: rem(52),
                      height: rem(52),
                      color: "var(--mantine-color-dimmed)",
                    }}
                    stroke={1.5}
                  />
                </Dropzone.Idle>

                <div style={{ textAlign: "center", lineHeight: "1.5em" }}>
                  <Text size="xl" inline>
                    {config.translate.dropzoneText[lang]}
                  </Text>
                  {filesUploadLimit && (
                    <Text size="sm" c="dimmed" inline mt={7}>
                      {`${config.translate.attach[lang]} ${filesUploadLimit} ${
                        filesUploadLimit > 1
                          ? config.translate.files[lang]
                          : config.translate.file[lang]
                      }, ${config.translate?.maxSize[lang]} ${filesMaxSize}MB`}
                    </Text>
                  )}
                </div>
              </Group>
            </Dropzone>

            <Group justify="center" mt="md">
              <Button onClick={() => openRef.current?.()}>
                {config.translate?.selectFiles?.[lang]}
              </Button>
            </Group>
          </>
        )
      )}
      {file || base64 ? (
        <Group justify="center">
          {file && !base64 && (
            <Button
              onClick={openInNewTab}
              disabled={slider || disabled}
              display={slider ? "none" : "inline"}
              variant="light"
              leftSection={
                <IconShare3
                  size={14}
                  color={colorScheme === "dark" ? "white" : "black"}
                />
              }
            >
              {config.translate.open[lang]}
            </Button>
          )}
          {file || (base64 && base64.length === 1) ? (
            <Button
              onClick={() => removeFile({ id: file?._id, index: 0 })}
              disabled={disabled}
              color="red"
              id={fileId}
              variant="light"
              leftSection={
                <CloseIcon
                  size={14}
                  color={colorScheme === "dark" ? "white" : "black"}
                />
              }
            >
              {config.translate.remove[lang]}
            </Button>
          ) : null}
        </Group>
      ) : null}
    </Card>
  );
}
