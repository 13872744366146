import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import * as API from "../../helpers/api";
import { useLang } from "../../helpers/language";
import config from "../../config";
import Swal from "sweetalert2";
import moment from "moment";
import classes from "../Tabs.module.css";
//! import modules
import Input from "../modules/input";
import "date-fns";
import { IconArrowBack, IconDeviceFloppy, IconList } from "@tabler/icons-react";
import {
  Group,
  Button,
  Card,
  Tabs,
  Grid,
  CardSection,
  useMantineColorScheme,
  useMantineTheme,
} from "@mantine/core";
import { useUser } from "../../helpers/userContext";

export default function ProjectAdd() {
  const [isBusy, setBusy] = useState(true);
  const route = config.api.project;
  const { lang, setLang } = useLang();
  const { user } = useUser();
  const history = useHistory();
  const { colorScheme } = useMantineColorScheme();
  const Theme = useMantineTheme();
  const location = useLocation();
  const id = location.pathname.split("/").pop();

  const [permissions, setPermissions] = useState([]);

  //! global variasbles
  const [ID, setID] = useState("");
  const [priority, setPriority] = useState(0); //  0 less , 1 mid , 2 high
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState("");
  const [projectType, setProjectType] = useState("");
  const [projectTypes, setProjectTypes] = useState("");
  const [city, setCity] = useState("");
  const [cities, setCities] = useState("");
  const [branch, setBranch] = useState("");
  const [branches, setBranches] = useState("");
  const [branchObj, setBranchObj] = useState("");
  const [substation, setSubstation] = useState("");
  const [voltage, setVoltage] = useState("");
  const [voltages, setVoltages] = useState("");
  const [orderNumber, setOrderNumber] = useState("");
  const [addressEn, setAddressEn] = useState("");
  const [addressRu, setAddressRu] = useState("");
  const [addressHy, setAddressHy] = useState("");
  const [workingDays, setWorkingDays] = useState("");
  const [tempStep, setTempStep] = useState("");

  const [step, setStep] = useState(1); // current selected step
  const [activeStep, setActiveStep] = useState(undefined); // current selected step
  const [steps, setSteps] = useState([]);
  const [users, setUsers] = useState([{}]);

  let modelSendToServer = {};
  modelSendToServer = {
    ID,
    history: history ? history : [],
    priority,
    startDate,
    endDate,
    steps,
    projectType,
    city,
    branch,
    substation,
    voltage,
    orderNumber,
    address: {
      en: addressEn,
      ru: addressRu,
      hy: addressHy,
    },
    workingDays,
  };

  useEffect(() => {
    let tempPermissions = [];
    user?.role?.permissions?.forEach((permission) => {
      if (permission.name === "project") {
        permission.inputs.forEach((input) => {
          tempPermissions.push(input);
        });
      }
    });
    setPermissions([...tempPermissions]);
  }, []);

  const HandlePermissionCheck = (input) => {
    if (input) {
      if (user?.ananunaki) {
        input.crud = {
          create: true,
          read: true,
          update: true,
          delete: true,
        };
        return input;
      }
      if (permissions) {
        let foundedInput = permissions?.find(
          (inp) => inp.name === input?.permissionModel
        );
        if (foundedInput && foundedInput.read) {
          input.crud = {
            create:
              foundedInput.create !== undefined ? foundedInput.create : false,
            read: foundedInput.read !== undefined ? foundedInput.read : false,
            update:
              foundedInput.update !== undefined ? foundedInput.update : false,
            delete:
              foundedInput.delete !== undefined ? foundedInput.delete : false,
          };
          return input;
        }
      } else {
        console.error("Permission not found");
      }
    }
  };

  useEffect(() => {
    (async () => {
      // all users
      let rawusers = await API.get(config.api.user);
      if (rawusers) setUsers(rawusers);
      // all cities
      let rawcities = await API.get(config.api.city, {}, {}, {}, {}, [
        "branches",
      ]);
      if (rawcities) setCities(rawcities);
      // all voltages
      let rawvoltages = await API.get(config.api.voltage);
      if (rawvoltages) setVoltages(rawvoltages);
      // all projectTypes
      let rawprojectTypes = await API.get(config.api.projectType);
      if (rawprojectTypes) setProjectTypes(rawprojectTypes);

      let tempStep = await API.post(config.api.step);
      setTempStep(tempStep);

      let Projects = await API.get(
        route,
        { temprorary: { $ne: true } },
        { ID: -1 },
        {}
      );

      if (Projects[0]?.ID || Projects[0]?.ID === 0) {
        let result;
        if (!isNaN(Projects[0].ID)) {
          result = parseInt(Projects[0].ID) + 1;
        } else if (isNaN(Projects[0].ID)) {
          result = Projects[0].ID + "-1";
        }
        setID(result);
      } else {
        setID(0);
      }

      setBusy(false);
    })();
    // eslint-disable-next-line
  }, [activeStep]);

  const priorities = [
    {
      _id: "0",
      name: {
        en: "Low",
        ru: "Низкий",
        hy: "Ցածր",
      },
    },
    {
      _id: "1",
      name: {
        en: "Medium",
        ru: "Средний",
        hy: "Միջին",
      },
    },
    {
      _id: "2",
      name: {
        en: "High",
        ru: "Высокий",
        hy: "Բարձր",
      },
    },
  ]; //  0 less , 1 mid , 2 high
  // ! MAIN BUTTONS -------SAVE------REMOVE------CANCEL
  const saveItem = async () => {
    Swal.fire({
      title: config.translate.areYouSure[lang],
      text: config.translate.youCannotRevertThisAction[lang],
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: config.translate.yes[lang],
    }).then(async (result) => {
      if (result.isConfirmed) {
        setBusy(true);
        modelSendToServer.temprorary = false;
        modelSendToServer.steps[0] = tempStep._id;
        try {
          let result = await API.update(route, id, modelSendToServer);
          if (result) {
            try {
              await API.update(config.api.step, tempStep._id, {
                project_id: result._id,
                queue: step,
                startDate: [startDate],
                status: "inProgress",
                history: [
                  {
                    date: new Date(),
                    user: user._id,
                    status: "inProgress",
                  },
                ],
              });
              Swal.fire(
                config.translate.saved[lang],
                config.translate.yourFileHasBeenSaved[lang],
                "success"
              );
              setTimeout(() => {
                history.push(`/${route}/edit/${result._id}`);
              }, 1000);
            } catch (error) {
              console.error(error);
            }
          } else {
            console.error("error in saving project");
          }
        } catch (error) {
          console.log(error);
        }
      }
    });
  };

  function setEndDateWorkingDays() {
    let localEndDate;
    let localWorkingDays = 0;
    let StartDate = startDate ? new Date(startDate) : new Date();
    voltages.length > 0 &&
      voltages.forEach((item) => {
        if (item._id === voltage) {
          // localeeddate is a number but we assign it to a date object
          localEndDate = new Date(
            StartDate.getTime() + item.deadline?.project * 24 * 60 * 60 * 1000
          );
          // Assuming StartDate and localEndDate are defined and are JavaScript Date objects
          // Calculate the difference in working days
          while (StartDate < localEndDate) {
            // Skips Sunday and Saturday
            if (StartDate.getDay() !== 0 && StartDate.getDay() !== 6) {
              localWorkingDays++;
            }
            StartDate.setDate(StartDate.getDate() + 1);
          }
          setWorkingDays(localWorkingDays);
          setEndDate(localEndDate);
          setEndDate(localEndDate);
        }
      });
  }

  useEffect(() => {
    (async () => {
      setEndDateWorkingDays();
      // eslint-disable-next-line
      // let files = await getUploads(id);
      setBusy(false);
    })();
    // eslint-disable-next-line
  }, [isBusy, activeStep]);

  useEffect(() => {
    if (city) {
      let currentCity = cities.filter((item) => item._id === city)[0];
      setBranches(currentCity.branches);
    }
    // eslint-disable-next-line
  }, [city]);

  useEffect(() => {
    setEndDateWorkingDays();
    // eslint-disable-next-line
  }, [voltage]);

  return (
    <Tabs defaultValue="first" variant="unstyled" classNames={classes}>
      <Tabs.List>
        <Tabs.Tab
          value="first"
          color="blue"
          leftSection={<IconList size={14} />}
        >
          {config.translate.mainInformation[lang]}
        </Tabs.Tab>
      </Tabs.List>
      <Tabs.Panel value="first">
        <Card
          shadow="sm"
          p="md"
          style={{
            borderTopLeftRadius: 0,
          }}
        >
          <Grid
            grow
            p={20}
            style={{
              background: colorScheme === "dark" ? Theme.colors.dark[6] : "",
            }}
          >
            {
              <Grid.Col
                span={{
                  base: 12,
                  md: 6,
                  lg: 3,
                }}
              >
                <Input
                  value={ID}
                  setter={setID}
                  type="text"
                  usageType="textfield"
                  label={config.translate.id[lang]}
                  placeholder={config.translate.id[lang]}
                  crud={{
                    create: true,
                    read: true,
                    update: true,
                    delete: true,
                  }}
                />
              </Grid.Col>
            }
            {cities && cities.length > 0 && (
              <Grid.Col
                span={{
                  base: 12,
                  md: 6,
                  lg: 3,
                }}
              >
                <Input
                  value={city._id}
                  setter={setCity}
                  optionListValue={cities}
                  type="optionlist"
                  usageType="optionlist"
                  label={config.translate.city[lang]}
                  lang={lang}
                  crud={{
                    create: true,
                    read: true,
                    update: true,
                    delete: true,
                  }}
                />
              </Grid.Col>
            )}
            {branches && branches.length > 0 && (
              <Grid.Col
                span={{
                  base: 12,
                  md: 6,
                  lg: 3,
                }}
              >
                <Input
                  value={branch._id}
                  setter={setBranch}
                  optionListValue={branches}
                  type="optionlist"
                  usageType="optionlist"
                  label={config.translate.branch[lang]}
                  lang={lang}
                  crud={{
                    create: true,
                    read: true,
                    update: true,
                    delete: true,
                  }}
                />
              </Grid.Col>
            )}

            {lang === "en" && (
              <Grid.Col
                span={{
                  base: 12,
                  md: 6,
                  lg: 3,
                }}
              >
                <Input
                  value={addressEn}
                  setter={setAddressEn}
                  type="textfield"
                  usageType="textfield"
                  label={config.translate.address[lang]}
                  placeholder={config.translate.address[lang]}
                  lang={lang}
                  crud={{
                    create: true,
                    read: true,
                    update: true,
                    delete: true,
                  }}
                />
              </Grid.Col>
            )}

            {lang === "ru" && (
              <Grid.Col
                span={{
                  base: 12,
                  md: 6,
                  lg: 3,
                }}
              >
                <Input
                  value={addressRu}
                  setter={setAddressRu}
                  type="textfield"
                  usageType="textfield"
                  label={config.translate.address[lang]}
                  placeholder={config.translate.address[lang]}
                  crud={{
                    create: true,
                    read: true,
                    update: true,
                    delete: true,
                  }}
                  lang={lang}
                />
              </Grid.Col>
            )}
            {lang === "hy" && (
              <Grid.Col
                span={{
                  base: 12,
                  md: 6,
                  lg: 3,
                }}
              >
                <Input
                  value={addressHy}
                  setter={setAddressHy}
                  type="textfield"
                  usageType="textfield"
                  label={config.translate.address[lang]}
                  placeholder={config.translate.address[lang]}
                  lang={lang}
                  crud={{
                    create: true,
                    read: true,
                    update: true,
                    delete: true,
                  }}
                />
              </Grid.Col>
            )}
            <Grid.Col
              span={{
                base: 12,
                md: 6,
                lg: 3,
              }}
            >
              <Input
                value={substation}
                setter={setSubstation}
                type="textfield"
                usageType="textfield"
                label={config.translate.substation[lang]}
                placeholder={config.translate.substation[lang]}
                lang={lang}
                crud={{
                  create: true,
                  read: true,
                  update: true,
                  delete: true,
                }}
              />
            </Grid.Col>
            <Grid.Col
              span={{
                base: 12,
                md: 6,
                lg: 3,
              }}
            >
              <Input
                value={orderNumber}
                setter={setOrderNumber}
                type="textfield"
                usageType="textfield"
                label={config.translate.orderNumber[lang]}
                placeholder={config.translate.orderNumber[lang]}
                lang={lang}
                crud={{
                  create: true,
                  read: true,
                  update: true,
                  delete: true,
                }}
              />
            </Grid.Col>
            <Grid.Col
              span={{
                base: 12,
                md: 6,
                lg: 3,
              }}
            >
              <Input
                value={projectType._id}
                setter={setProjectType}
                optionListValue={projectTypes}
                type="optionlist"
                usageType="optionlist"
                label={config.translate.projectTypes[lang]}
                lang={lang}
                crud={{
                  create: true,
                  read: true,
                  update: true,
                  delete: true,
                }}
              />
            </Grid.Col>
            <Grid.Col
              span={{
                base: 12,
                md: 6,
                lg: 3,
              }}
            >
              <Input
                value={moment(new Date()).format("DD-MM-YYYY, HH:mm:ss")}
                type="text"
                usageType="textfield"
                disabled={true}
                label={config.translate.startDate[lang]}
                lang={lang}
                crud={{
                  create: true,
                  read: true,
                  update: true,
                  delete: true,
                }}
              />
            </Grid.Col>
            <Grid.Col
              span={{
                base: 12,
                md: 6,
                lg: 3,
              }}
            >
              <Input
                value={voltage._id}
                setter={setVoltage}
                optionListValue={voltages}
                type="optionlist"
                usageType="optionlist"
                label={config.translate.voltageLevel[lang]}
                lang={lang}
                crud={{
                  create: true,
                  read: true,
                  update: true,
                  delete: true,
                }}
              />
            </Grid.Col>
            {voltage && (
              <Grid.Col
                span={{
                  base: 12,
                  md: 6,
                  lg: 3,
                }}
              >
                <Input
                  value={
                    endDate &&
                    endDate instanceof Date &&
                    endDate?.toLocaleDateString() +
                      " (" +
                      workingDays +
                      " " +
                      config.translate.workingDays[lang] +
                      ")"
                  }
                  optionListValue={voltages}
                  type="textfield"
                  usageType="textfield"
                  disabled={true}
                  label={config.translate.deadLine[lang]}
                  lang={lang}
                  crud={{
                    create: true,
                    read: true,
                    update: true,
                    delete: true,
                  }}
                />
              </Grid.Col>
            )}
            <Grid.Col
              span={{
                base: 12,
                md: 6,
                lg: 3,
              }}
            >
              <Input
                value={priority}
                setter={setPriority}
                optionListValue={priorities}
                type="optionlist"
                usageType="optionlist"
                label={config.translate.priority[lang]}
                lang={lang}
                crud={{
                  create: true,
                  read: true,
                  update: true,
                  delete: true,
                }}
              />
            </Grid.Col>
          </Grid>

          <CardSection p={20} mt={20}>
            <Group justify="center" grow>
              {
                <>
                  <Button
                    leftSection={
                      <IconDeviceFloppy
                        size={14}
                        color={colorScheme === "dark" ? "white" : "black"}
                      />
                    }
                    variant="light"
                    onClick={() => {
                      saveItem();
                    }}
                  >
                    {config.translate.save[lang]}
                  </Button>
                  <Button
                    leftSection={
                      <IconArrowBack
                        size={14}
                        color={colorScheme === "dark" ? "white" : "black"}
                      />
                    }
                    color="orange"
                    variant="light"
                    onClick={() => history.go(`/${route}/`)}
                  >
                    {config.translate.cancel[lang]}
                  </Button>
                </>
              }
            </Group>
          </CardSection>
        </Card>
      </Tabs.Panel>
    </Tabs>
  );
}
