import React, { useMemo } from "react";
import { ListPage } from "../ListPage";
import config from "../../config";
import { useUser } from "../../helpers/userContext";
import { useLang } from "../../helpers/language";
import parse from "html-react-parser";

export default function Pcs() {
  const { user } = useUser();
  const { lang } = useLang();

  const columns = [
    {
      accessorKey: "index",
      permissionModel: "index",
      header: config.translate.index[lang],
      enableClickToCopy: true,
      size: 30,
      Cell: (
        RecievedObject,
        cell = RecievedObject.renderedCellValue,
        row = RecievedObject.row.original
      ) => {
        let result = row.index ? row.index : "...";
        return result.length > 20 ? result.slice(0, 20) + "..." : result;
      },
    },
    {
      accessorFn: (row) => `${row.name.en} ${row.name.ru} ${row.name.hy}`,
      permissionModel: "name",
      header: config.translate.name[lang],
      grow: true,
      Cell: (
        RecievedObject,
        cell = RecievedObject.renderedCellValue,
        row = RecievedObject.row.original
      ) => {
        let result = parse(row.name[lang] ? row.name[lang] : "no description");
        return result.length > 20 ? result.slice(0, 20) + "..." : result;
      },
    },
  ];

  return (
    <ListPage
      route={config.api.status}
      query={{}}
      sorting={{ index: "asc" }}
      permissionModel={"status"}
      limit={null}
      columns={columns}
    />
  );
}
