import React, { useMemo } from "react";
import { ListPage } from "../ListPage";
import { useUser } from "../../helpers/userContext";
import { useLang } from "../../helpers/language";
import parse from "html-react-parser";
import config from "../../config";
import {
  Input,
  Text,
  Slider,
  Box,
  Flex,
  Accordion,
  Card,
  Grid,
} from "@mantine/core";
import moment from "moment";

export default function ProjectList() {
  const { user } = useUser();
  const { lang } = useLang();
  const columns = [
    {
      accessorKey: "ID",
      permissionModel: "ID",
      header: "ID",
      size: 30,
    },
    {
      accessorKey: "steps",
      header: config.translate.steps[lang],
      permissionModel: "steps",
      filterVariant: "multi-select",
      grow: true,
      Cell: (
        RecievedObject,
        cell = RecievedObject.renderedCellValue,
        row = RecievedObject.row.original
      ) => {
        let result;
        cell?.forEach((item, index) => {
          if (item.status === "inProgress") {
            result = { ...item, index };
            return item;
          }
        });
        // result && result.index ? result.index : "Not started";
        let value = result && result.index + 1 ? result.index + 1 : 0;
        let label = value
          ? config.steps[result.index]?.name?.[lang]
          : config.steps[0]?.name?.[lang];
        return (
          <Flex direction="column" w={"100%"}>
            <Text size="sm" mb={10}>
              {label}
            </Text>

            <Slider
              thumbChildren={<Text size="1rem">{value}</Text>}
              label={null}
              max={19}
              defaultValue={0}
              thumbSize={30}
              styles={{
                thumb: { borderWidth: "1px", padding: "rem(3)" },
                width: "100%",
              }}
              step={1}
              min={1}
              value={value}
            />
          </Flex>
        );
      },
    },
    {
      accessorKey: "priority",
      header: config.translate.priority[lang],
      permissionModel: "priority",
      size: 200,
      filterVariant: "multi-select",
      //custom conditional format and styling
      Cell: ({ cell }) => (
        <Box
          sx={(theme) => ({
            backgroundColor:
              cell.getValue() === 0
                ? theme.colors.green[9]
                : cell.getValue() === 1
                ? theme.colors.yellow[9]
                : cell.getValue() === 2
                ? theme.colors.red[9]
                : theme.colors.gray[9],
            borderRadius: "10px",
            color: "#fff",
            maxWidth: "150px",
            padding: "5px",
            textAlign: "center",
          })}
        >
          {cell.getValue() === 0
            ? config.translate.high[lang]
            : cell.getValue() === 1
            ? config.translate.medium[lang]
            : cell.getValue() === 2
            ? config.translate.low[lang]
            : config.translate.undefined[lang]}
        </Box>
      ),
    },
  ];

  const renderDetailPanel = ({ row }) => {
    let Row = row?.original;
    return (
      <Grid gutter="lg">
        <Grid.Col span={12}>
          <Text size="lg" weight={500} p={16}>
            {config.translate.dates[lang]}
          </Text>
          <Card
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: "16px",
              padding: "16px",
            }}
            w={"100%"}
          >
            <Flex justify={"space-between"}>
              <Box>
                <Text size="sm" weight={500}>
                  {config.translate.startDate[lang]}
                </Text>
                <Text
                  size="sm"
                  c={moment().isAfter(Row.startDate) ? "red" : "green"}
                >
                  {moment(Row.startDate).calendar()}
                </Text>
              </Box>
              <Box>
                <Text size="sm" weight={500}>
                  {config.translate.endDate[lang]}
                </Text>
                <Text
                  size="sm"
                  c={moment().isAfter(Row.endDate) ? "red" : "green"}
                >
                  {moment(Row.endDate).calendar()}
                </Text>
              </Box>
            </Flex>
          </Card>
        </Grid.Col>
        <Grid.Col span={12}>
          <Text size="lg" weight={500} p={16}>
            {config.translate.steps[lang]}
          </Text>
          <Card
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: "16px",
              padding: "16px",
            }}
            w={"100%"}
          >
            {/* // default value is the last item */}
            <Accordion
              defaultValue={
                Row?.steps?.[Row?.steps?.length - 1].name?.[lang] || "..."
              }
              w={"100%"}
            >
              {Row?.steps?.map((step, index) => {
                return (
                  <Accordion.Item
                    key={index}
                    value={step.name?.[lang] || "..."}
                  >
                    <Accordion.Control icon={step.emoji}>
                      {step.name?.[lang] || "..."}
                    </Accordion.Control>
                    <Accordion.Panel>
                      {" "}
                      <Text
                        size="sm"
                        weight={500}
                        c={
                          step.status === "done"
                            ? "green"
                            : step.status === "inProgress"
                            ? "orange"
                            : "dark"
                        }
                      >
                        {step.status}
                        {": "}
                        {moment(
                          moment(row.closedDate) - moment(row.openedDate)
                        ).format("m") + " мин."}
                      </Text>
                      <Text size="sm" weight={500}>
                        {moment(step.startDate).calendar()}
                        {moment(step.endDate).calendar()} --{" "}
                      </Text>
                    </Accordion.Panel>
                  </Accordion.Item>
                );
              })}
            </Accordion>
          </Card>
        </Grid.Col>
      </Grid>
    );
  };

  return (
    <ListPage
      route={config.api.project}
      editPage={"/edit"}
      query={{}}
      sorting={{ index: "asc" }}
      uploadType={true}
      limit={null}
      populate={["steps"]}
      permissionModel={"project"}
      columns={columns}
      renderDetailPanel={renderDetailPanel}
    />
  );
}
