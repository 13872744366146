import React, { useMemo } from "react";
import { ListPage } from "../ListPage";
import config from "../../config";
import { useUser } from "../../helpers/userContext";
import { useLang } from "../../helpers/language";
import parse from "html-react-parser";

export default function Roles() {
  const { user } = useUser();
  const { lang } = useLang();

  const columns = [
    {
      accessorKey: "index",
      permissionModel: config.translate.index[lang],
      header: "Index",
      enableClickToCopy: true,
      size: 30,
      Cell: (
        RecievedObject,
        cell = RecievedObject.renderedCellValue,
        row = RecievedObject.row.original
      ) => {
        let result = parse(row.index ? row.index : "...");
        return result;
      },
    },
    {
      accessorFn: (row) => `${row?.name?.en} ${row?.name?.ru} ${row?.name?.hy}`,
      permissionModel: "name",
      header: config.translate.name[lang],
      Cell: (
        RecievedObject,
        cell = RecievedObject.renderedCellValue,
        row = RecievedObject.row.original
      ) => {
        let result = parse(row?.name?.[lang] ? row.name?.[lang] : "...");
        return result;
      },
    },
    {
      accessorKey: "updatedAt",
      permissionModel: "updatedAt",
      header: "Updated At",
      enableClickToCopy: true,
      Cell: (
        RecievedObject,
        cell = RecievedObject.renderedCellValue,
        row = RecievedObject.row.original
      ) => {
        let result = parse(row.updatedAt ? row.updatedAt : "...");
        return result;
      },
    },
    {
      accessorFn: (row) => `${row?.desc?.en} ${row?.desc?.ru} ${row?.desc?.hy}`,
      header: config.translate.desc[lang],
      permissionModel: "desc",
      grow: true,
      Cell: (
        RecievedObject,
        cell = RecievedObject.renderedCellValue,
        row = RecievedObject.row.original
      ) => {
        let result = parse(row?.desc?.[lang] ? row.desc?.[lang] : "...");
        return result;
      },
    },
  ];

  return (
    <ListPage
      route={config.api.role}
      // query={}
      sorting={{ ip: "asc" }}
      permissionModel={"role"}
      uploadType={true}
      limit={null}
      columns={columns}
    />
  );
}
