import React, { useState, useEffect, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import NProgress, { set } from "nprogress";
import moment from "moment";
// import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import * as API from "../helpers/api";
import config from "../config";
import Loading from "./modules/loading";
import { useUser } from "../helpers/userContext";
import { useConnectedUser } from "../helpers/connectedUserContext";
import { useLang } from "../helpers/language";
import { DELETE } from "../helpers/CRUD";
import {
  MantineReactTable,
  useMantineReactTable,
  MRT_GlobalFilterTextInput,
  MRT_ToggleFiltersButton,
  MRT_ColumnPinningButtons,
  MRT_ColumnOrderingButtons,
  MRT_ColumnFilterModesButtons,
  MRT_ColumnResizingButtons,
  MRT_ColumnVisibilityButtons,
  MRT_ToggleDensePaddingButton,
  MRT_GroupingButtons,
  MRT_Pagination,
  MRT_RowSelectionButtons,
  MRT_RowActionsButtons,
  MRT_FacetedValuesButtons,
  MRT_StickyHeaderToggle,
  MRT_StickyFooterToggle,
  MRT_ClickToCopy,
  MRT_DetailPanel,
} from "mantine-react-table";
import {
  CloseIcon,
  Container,
  MantineProvider,
  useMantineTheme,
  useMantineTextVariant,
  useMantineTitleVariant,
  useMantineColorScheme,
  Box,
  Button,
  Flex,
  Menu,
  Text,
  Title,
  MenuItem,
} from "@mantine/core";
import {
  IconUserCircle,
  IconSend,
  IconEdit,
  IconTrash,
} from "@tabler/icons-react";
import { Link } from "react-router-dom";
import ErrorPage from "../helpers/ErrorPage";

function convertToGMT0(dateString) {
  // Create a Date object from the input date string
  const originalDate = new Date(dateString);
  function getUserGMTOffset() {
    const offset = new Date().getTimezoneOffset(); // returns offset in minutes
    const hours = Math.floor(Math.abs(offset) / 60);
    const minutes = Math.abs(offset) % 60;
    const sign = offset > 0 ? "-" : "+";
    return hours;
  }
  let myGMTZone = getUserGMTOffset();
  // Get the local time components
  const year = originalDate.getUTCFullYear();
  const month = originalDate.getUTCMonth();
  const date = originalDate.getUTCDate();
  const hours = originalDate.getUTCHours();
  const minutes = originalDate.getUTCMinutes();
  const seconds = originalDate.getUTCSeconds();
  // Create a new Date object in GMT with the same local time components
  const gmtDate = new Date(
    Date.UTC(year, month, date, hours + myGMTZone, minutes, seconds)
  );
  return gmtDate.toUTCString();
}

function calculateBirthdate(age) {
  const currentDate = moment();
  // get the birthdate in correction of one day
  const birthDate = currentDate.subtract(age, "years").format("YYYY-MM-DD");
  return new Date(birthDate);
}

let FirstRender = false;
let SecondRender = false;

export function ListPage({
  route,
  query,
  sorting,
  pageIndex = 0,
  pageSize = 10,
  permissionModel,
  uploadType,
  limit = 10,
  skip = 0,
  populate,
  columns,
  editPage,
  addPage,
  renderDetailPanel = null,
  pageTitle,
  noAdd,
  defaultColumn = {
    maxSize: 1000,
    minSize: 40,
    size: 180, //default size is usually 180
  },
  showSkeletons = true,
  enableColumnActions = false,
  enableEditing = false,
  enableColumnFilterModes = false,
  enableColumnOrdering = false,
  enableFacetedValues = false,
  enableGrouping = false,
  enablePinning = false,
  enableRowSelection = false,
  enableRowActions = true,
  enableSorting = false,
  enableDensityToggle = false,
  mantineSearchTextInputProps = null,
  enableColumnResizing = false,
  enableStickyHeader = false,
  enableStickyFooter = false,
  mantinePaginationProps = {
    radius: "sm",
    size: "sm",
  },
  paginationDisplayMode = "pages",
  positionToolbarAlertBanner = "bottom",
  enableClickToCopy = false,
  initialState = {
    sorting: [],
    filters: [],
    grouping: [],
    pinnedColumns: [],
    hiddenColumns: [],
    selectedRows: [],
    searchQuery: "",
    showColumnFilters: true,
    showGlobalFilter: false,
  },
  customFiltersArrays,
}) {
  let history = useHistory();
  const { user } = useUser();
  let CRUD = {
    create: false,
    read: false,
    update: false,
    delete: false,
  };
  const [firstRender, setfirstRender] = useState(false);
  const [apiData, setApiData] = useState([]);
  const location = useLocation();
  const { lang } = useLang();
  const [buttonLoading, setButtonLoading] = useState(false);
  const { colorScheme } = useMantineTheme();
  const { connectedUsers } = useConnectedUser();
  //data and fetching state
  const [isError, setIsError] = useState(false);
  let editedPageTitle = `${pageTitle}`;
  let formatedLocation = location.search
    .replace("?", "")
    .split("&")
    .reduce((a, v) => ({ ...a, [v.split("=")[0]]: v.split("=")[1] }), {});
  const [CollectionCount, setCollectionCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isBusy, setBusy] = useState(false);
  //table state
  const [ColumnFilters, setColumnFilters] = useState([]);
  const [GlobalFilter, setGlobalFilter] = useState("");
  const [Sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: Number(
      pageIndex ||
        pageIndex === 0 ||
        (formatedLocation.pageIndex && formatedLocation.pageIndex)
    ),
    pageSize: Number(
      pageSize || (formatedLocation.pageSize && formatedLocation.pageSize)
    ), //customize the default page size
  });

  user.role?.permissions?.forEach((permission) => {
    if (user?.ananunaki) {
      CRUD = {
        create: true,
        read: true,
        update: true,
        delete: true,
      };
    }
    if (permission.name === permissionModel) {
      CRUD.read = permission.crud.read;
      CRUD.create = permission.crud.create;
      CRUD.update = permission.crud.update;
      CRUD.delete = permission.crud.delete;
    }
  });

  const addNew = async () => {
    setButtonLoading(true);
    let result;
    let specificPageRoute = "" + route;
    if (route === "project") specificPageRoute += "/add";
    try {
      result = await API.post(route, { temprorary: true });
      history.push(`/${specificPageRoute}/${result._id}?temprorary=true`);
      setButtonLoading(false);
    } catch (error) {
      console.error(error);
      setButtonLoading(false);
    }
  };

  let tempColumns = [];

  columns = columns.forEach((column) => {
    if (user?.ananunaki) {
      tempColumns.push(column);
    } else {
      user.role?.permissions?.forEach((permission) => {
        if (permission.name === permissionModel) {
          permission.inputs.forEach((input) => {
            if (column.permissionModel === input.name && input.read) {
              tempColumns.push(column);
            }
          });
        }
      });
    }
  });

  columns = tempColumns;

  const table = useMantineReactTable({
    defaultColumn,
    layoutMode: "grid-no-grow",
    columnResizeMode: "onChange",
    columns: columns ? columns : [],
    data: apiData ? apiData : [],
    enableColumnFilterModes,
    enableColumnOrdering,
    enableFacetedValues,
    enableDensityToggle,
    enableGrouping,
    enableColumnActions,
    enablePinning,
    enableRowSelection,
    columnVirtualizerProps: {
      overscan: 5, //adjust the number of columns that are rendered to the left and right of the visible area of the table
      estimateSize: () => 40, //if your columns are wider or , try tweaking this value to make scrollbar size more accurate
    },
    rowVirtualizerProps: {
      overscan: 10, //adjust the number or rows that are rendered above and below the visible area of the table
      estimateSize: () => 10, //if your rows are taller than normal, try tweaking this value to make scrollbar size more accurate
    },
    enableRowActions,
    initialState: {
      ...initialState,
      density: "xs",
      showGlobalFilter: true,
      sorting: Sorting,
    },
    paginationDisplayMode,
    positionToolbarAlertBanner,
    mantinePaginationProps,
    enableClickToCopy,
    mantineSearchTextInputProps,
    renderDetailPanel,
    enableColumnResizing,
    enableStickyHeader,
    enableStickyFooter,
    enableEditing,
    enableSorting,
    manualPagination: true,
    manualFiltering: true,
    manualSorting: false,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onSortingChange: setSorting,
    showSkeletons,
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Error loading data" }
      : undefined,
    rowCount: CollectionCount,
    onPaginationChange: (props) => {
      let localPagination = {
        pageIndex: Number(
          pageIndex ||
            pageIndex === 0 ||
            (formatedLocation.pageIndex && formatedLocation.pageIndex)
        ),
        pageSize: Number(
          pageSize || (formatedLocation.pageSize && formatedLocation.pageSize)
        ),
      };
      if (props(localPagination).pageIndex !== 0) {
        if (FirstRender) SecondRender = true;
        FirstRender = true;
        setPagination(props(localPagination));
      } else if (
        props(localPagination).pageIndex === 0 &&
        localPagination.pageIndex > 0
      ) {
        if (FirstRender && SecondRender) {
          if (FirstRender) SecondRender = true;
          FirstRender = true;
          setPagination({
            ...props(localPagination),
            pageIndex: 0,
          });
        } else {
          if (FirstRender) SecondRender = true;
          FirstRender = true;
          setPagination({
            ...props(localPagination),
            pageIndex: localPagination.pageIndex,
          });
        }
      } else {
        if (FirstRender) SecondRender = true;
        FirstRender = true;
        setPagination({ ...props(localPagination) });
      }
    },
    state: {
      ColumnFilters,
      GlobalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
    },
    renderRowActionMenuItems: ({ row }) => {
      return (
        <>
          {(user.ananunaki || CRUD.update) && (
            <Menu.Item>
              <Link
                to={`/${route}${editPage ? editPage : ""}/${
                  row?.original?._id
                }`}
              >
                <Button
                  leftSection={
                    <IconEdit
                      size={14}
                      color={colorScheme === "dark" ? "black" : "white"}
                    />
                  }
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  variant="light"
                >
                  {config.translate.edit[lang]}
                </Button>
              </Link>
            </Menu.Item>
          )}
          {(user.ananunaki || CRUD.delete) && (
            <Menu.Item>
              <Button
                color="red"
                leftSection={
                  <CloseIcon
                    size={14}
                    color={colorScheme === "dark" ? "black" : "white"}
                  />
                }
                variant="light"
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
                onClick={() => {
                  DELETE({
                    route,
                    id: row?.original?._id,
                    // history,
                    lang,
                    setBusy,
                    isBusy,
                  });
                }}
              >
                {config.translate.remove[lang]}
              </Button>
            </Menu.Item>
          )}
        </>
      );
    },
    renderTopToolbar: ({ table }) => {
      return (
        <Flex p="md" justify="space-between">
          <Flex sx={{ gap: "8px" }}>
            {(user.ananunaki || CRUD.create) && (
              <Button
                color="green"
                loading={buttonLoading}
                onClick={addNew}
                variant="filled"
              >
                {config.translate.add[lang]}
              </Button>
            )}
          </Flex>
          <Flex gap="xs">
            {/* <MRT_GlobalFilterTextInput table={table} /> */}
            <MRT_ToggleFiltersButton table={table} />
            {/* <MRT_ToggleDensePaddingButton table={table} /> */}
          </Flex>
        </Flex>
      );
    },
  });

  useEffect(() => {
    NProgress.start();
    (async () => {
      let rawData;
      if (GlobalFilter) {
        try {
          rawData = await API.get(
            route,
            query
              ? {
                  ...query,
                  temprorary: { $ne: true },
                  // $or: [
                  //   ...stringSearchFields.map((field) => ({
                  //     [field]: new RegExp("^" + GlobalFilter, "i"),
                  //   })),
                  //   ...numberSearchFields.map((field) => ({
                  //     $where: `/^${GlobalFilter}.*/.test(this.${field})`,
                  //   })),
                  // ],
                }
              : { temprorary: { $ne: true } },
            sorting ? sorting : "desc",
            uploadType ? uploadType : "",
            pagination?.pageSize ? pagination?.pageSize : limit ? limit : "",
            populate ? populate : "",
            pagination?.pageIndex * pagination?.pageSize,
            true
          );
        } catch (error) {
          setIsError(true);
          console.error(error);
          return;
        }
      } else if (ColumnFilters.length > 0) {
        try {
          rawData = await API.get(
            route,
            {
              $and: [
                { ...query, temprorary: { $ne: true } },
                ...ColumnFilters.map(({ id, value }) => {
                  let sendKey,
                    sendValue = value;

                  for (const [key, value] of Object.entries(config.translate)) {
                    if (id === key) {
                      sendKey = key;
                    } else if (value?.[lang] === id) {
                      if (key === "position" || key === "department") {
                        let result = customFiltersArrays?.[key].filter(
                          (item) => item.name?.[lang] === sendValue
                        );
                        sendKey = key;
                        if (result[0] && result[0]._id)
                          sendValue = result[0]._id;
                      } else {
                        sendKey = key + "." + lang;
                      }
                    }
                  }
                  if (sendKey === "dateOfBirth") {
                    return {
                      [sendKey]: convertToGMT0(sendValue),
                    };
                  } else if (sendKey === "age") {
                    if (sendValue[0] === "" && sendValue[1] === "") return;
                    if (sendValue[0] === "") {
                      return {
                        dateOfBirth: {
                          $gte: calculateBirthdate(sendValue[1]),
                        },
                      };
                    }
                    if (sendValue[1] === "") {
                      return {
                        dateOfBirth: {
                          $lte: calculateBirthdate(sendValue[0]),
                        },
                      };
                    }
                    return {
                      dateOfBirth: {
                        $lte: calculateBirthdate(sendValue[0]),
                        $gte: calculateBirthdate(sendValue[1]),
                      },
                    };
                  } else if (sendKey === "index") {
                    return {
                      [sendKey]: sendValue,
                    };
                  } else if (
                    sendKey === "position" ||
                    sendKey === "department"
                  ) {
                    return {
                      [sendKey]: sendValue,
                    };
                  } else if (sendKey === "ports") {
                    return {
                      [sendKey]: Number(sendValue),
                    };
                  } else {
                    return {
                      [sendKey]: { $regex: sendValue, $options: "i" },
                    };
                  }
                }),
              ],
            },
            sorting ? sorting : "desc",
            uploadType ? uploadType : "",
            pagination?.pageSize ? pagination?.pageSize : limit ? limit : "",
            populate ? populate : "",
            null,
            true
          );
        } catch (error) {
          setIsError(true);
          console.error(error);
          return;
        }
      } else {
        try {
          rawData = await API.get(
            route,
            query
              ? { ...query, temprorary: { $ne: true } }
              : { temprorary: { $ne: true } },
            sorting ? sorting : "desc",
            uploadType ? uploadType : "",
            pagination?.pageSize ? pagination?.pageSize : limit ? limit : "",
            populate ? populate : "",
            pagination?.pageIndex * pagination?.pageSize,
            true
          );
        } catch (error) {
          setIsError(true);
          console.error(error);
          return;
        }
      }

      setApiData(rawData.items);
      setCollectionCount(rawData.count);

      NProgress.done();
    })();
    FirstRender = true;
    // eslint-disable-next-line
  }, [
    location.search,
    window.location.pathname,
    GlobalFilter,
    ColumnFilters,
    ColumnFilters.length,
    isBusy,
  ]);

  useEffect(() => {
    const newUrl = `${location.pathname}?pageIndex=${pagination.pageIndex}&pageSize=${pagination.pageSize}`;
    history.push(newUrl);
    // eslint-disable-next-line
  }, [pagination?.pageIndex, pagination?.pageSize]);

  if (NProgress.done()) {
    return (
      <div style={{ height: "calc(100vh - 220px)" }} className="wrapper">
        <MantineReactTable table={table} />
      </div>
    );
  } else return <ErrorPage />;
}
