import React, { useEffect, useState } from "react";
import config from "../../config";
import {
  Input,
  useMantineColorScheme,
  useMantineTheme,
  MantineProvider,
  Button,
  Box,
  Flex,
  Menu,
  Text,
  Title,
  Timeline,
  Card,
} from "@mantine/core";
import { useLang } from "../../helpers/language";
import {
  IconUserCircle,
  IconSend,
  IconEdit,
  IconDownload,
  IconGitBranch,
  IconGitPullRequest,
  IconGitCommit,
  IconMessageDots,
} from "@tabler/icons-react";
import moment from "moment";

export function TimelineComponent({
  data,
  placeholder,
  width,
  height,
  bullet,
  crud,
  user,
  columns,
}) {
  const { lang } = useLang();
  const { colorScheme } = useMantineColorScheme();
  const Theme = useMantineTheme();

  if (user.ananunaki || crud.read) {
    return (
      <Card
        p={20}
        style={{
          maxWidth: width ? width + 40 : "100%",
          width: "100%",
          height: height ? height + 130 : "100%",
          margin: "0 auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          border: `1px solid ${
            colorScheme === "dark" ? Theme.colors.gray[7] : "white"
          }`,
        }}
      >
        <Title order={3} mb={40}>
          {placeholder}
        </Title>
        <Timeline active={data.length} bulletSize={24} lineWidth={2}>
          {data?.map((item, index) => {
            return (
              <Timeline.Item
                // bullet={<IconGitCommit size={12} />}
                bullet={bullet ? bullet : <IconMessageDots size={12} />}
                title={
                  typeof item?.desc === "string"
                    ? item?.desc
                    : item?.desc?.[lang] || "No description"
                }
                key={index}
              >
                <Text c="dimmed" size="sm">
                  {item?.user?.name?.[lang] || "No user"}
                </Text>
                <Text size="xs" mt={4}>
                  {moment(item?.date).format("HH:mm")}
                  {index > 0 &&
                    index < data.length - 1 &&
                    " - " +
                      moment(data[index + 1]?.date).diff(
                        moment(item?.date),
                        "minutes"
                      )}
                </Text>
              </Timeline.Item>
            );
          })}
        </Timeline>
      </Card>
    );
  }
  return null;
}
