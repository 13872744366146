import React, { useMemo } from "react";
import { ListPage } from "../ListPage";
import config from "../../config";
import { useUser } from "../../helpers/userContext";
import { useLang } from "../../helpers/language";
import parse from "html-react-parser";
import {
  Box,
  Button,
  Flex,
  Menu,
  Text,
  Title,
  Grid,
  Progress,
  Tooltip,
  useMantineColorScheme,
  useMantineTheme,
  Badge,
} from "@mantine/core";
import moment from "moment";
import { Link } from "react-router-dom";
import { IconBrandHipchat, IconCommand } from "@tabler/icons-react";

export default function Tickets() {
  const { user } = useUser();
  const { lang } = useLang();
  const { colorScheme } = useMantineColorScheme();
  const Theme = useMantineTheme();

  const columns = [
    {
      accessorKey: "ID",
      permissionModel: "ID",
      header: "ID",
      size: 50,
      enableClickToCopy: true,
    },
    // {
    //   accessorKey: "openedDate",
    //   header: config.translate.openedDate[lang],
    //   size: 50,
    //   Cell: (
    //     RecievedObject,
    //     cell = RecievedObject.renderedCellValue,
    //     row = RecievedObject.row.original
    //   ) => {
    //     let result = "";

    //     if (row.closedDate) {
    //       result =
    //         moment(moment(row.closedDate) - moment(row.openedDate)).format(
    //           "m"
    //         ) + ` ${config.translate.minutes[lang]}.`;
    //     } else {
    //       result = "...";
    //     }
    //     return parse(typeof result === "string" ? result : "");
    //   },
    // },
    {
      header: config.translate.status[lang],
      permissionModel: "status",
      accessorKey: "status",
      size: 500,
      Cell: (
        RecievedObject,
        cell = RecievedObject.renderedCellValue,
        row = RecievedObject.row.original
      ) => {
        let result = (
          <Progress.Root size={50}>
            {row.status?.index >= 1 && (
              <Progress.Section
                value={100 / row.status?.index}
                color={`${
                  row.status?.index > 1 ? Theme.colors.dark[6] : "red"
                }`}
              >
                <Progress.Label
                  style={{
                    color: `${
                      row.status?.index > 1
                        ? Theme.colors.dark[1]
                        : Theme.colors.gray[1]
                    }`,
                    margin: "0 10px",
                    width: 100,
                  }}
                >
                  <Box style={{ fontSize: 14, textAlign: "center" }}>
                    {config.translate.sent[lang]}
                  </Box>
                  <Box mt={5} style={{ fontSize: 12, textAlign: "center" }}>
                    {moment(row.openedDate).format("HH:mm")} {" - "}
                    {moment(row.history[1]?.date).diff(
                      moment(row.openedDate),
                      "minutes"
                    )}{" "}
                    {config.translate.minutes[lang]}
                  </Box>
                </Progress.Label>
              </Progress.Section>
            )}
            {row.status?.index >= 2 && (
              <Progress.Section
                value={100 / row.status?.index}
                color={`${
                  row.status?.index > 2 ? Theme.colors.dark[5] : "yellow"
                }`}
              >
                <Progress.Label
                  style={{
                    color: `${
                      row.status?.index > 2
                        ? Theme.colors.dark[1]
                        : Theme.colors.gray[1]
                    }`,
                    margin: "0 10px",
                    width: 100,
                  }}
                >
                  <Box style={{ fontSize: 14, textAlign: "center" }}>
                    {config.translate.inProgress[lang]}
                  </Box>
                  <Box mt={5} style={{ fontSize: 12, textAlign: "center" }}>
                    {moment(row.history[0]?.date).format("HH:mm")} {" - "}
                    {moment(row.history[1]?.date).diff(
                      moment(row.history[0]?.date),
                      "minutes"
                    )}{" "}
                    {config.translate.minutes[lang]}
                  </Box>
                </Progress.Label>
              </Progress.Section>
            )}
            {row.status?.index >= 3 && (
              <Progress.Section
                value={100 / row.status?.index}
                color={`${
                  row.status?.index > 3 ? Theme.colors.dark[4] : "cyan"
                }`}
              >
                <Progress.Label
                  style={{
                    color: `${
                      row.status?.index > 3
                        ? Theme.colors.dark[1]
                        : Theme.colors.gray[1]
                    }`,
                    margin: "0 10px",
                    width: 100,
                  }}
                >
                  <Box style={{ fontSize: 14, textAlign: "center" }}>
                    {config.translate.done[lang]}
                  </Box>
                  <Box mt={5} style={{ fontSize: 12, textAlign: "center" }}>
                    {moment(row.history[1]?.date).format("HH:mm")} {" - "}
                    {moment(row.history[2]?.date).diff(
                      moment(row.history[1]?.date),
                      "minutes"
                    )}{" "}
                    {config.translate.minutes[lang]}
                  </Box>
                </Progress.Label>
              </Progress.Section>
            )}
            {row.status?.index >= 4 && (
              <Progress.Section
                value={100 / row.status?.index}
                color={`${
                  row.status?.index > 4 ? Theme.colors.dark[3] : "green"
                }`}
              >
                <Progress.Label
                  style={{
                    color: `${
                      row.status?.index > 4
                        ? Theme.colors.dark[1]
                        : Theme.colors.gray[1]
                    }`,
                    margin: "0 10px",
                    width: 100,
                  }}
                >
                  <Box style={{ fontSize: 14, textAlign: "center" }}>
                    {config.translate.closed[lang]}
                  </Box>
                  <Box mt={5} style={{ fontSize: 12, textAlign: "center" }}>
                    {moment(row.history[2]?.date).format("HH:mm")} {" - "}
                    {moment(row.closedDate?.date).diff(
                      moment(row.history[2]?.date),
                      "minutes"
                    )}{" "}
                    {config.translate.minutes[lang]}
                  </Box>
                </Progress.Label>
              </Progress.Section>
            )}
          </Progress.Root>
        );
        return result;
      },
    },
    {
      accessorFn: (row) =>
        `${row?.createdByUser?.name?.en} ${row?.createdByUser?.name?.ru} ${row?.createdByUser?.name?.hy}`,
      permissionModel: "createdByUser",
      size: 200,
      header: config.translate.user[lang],
      Cell: (
        RecievedObject,
        cell = RecievedObject.renderedCellValue,
        row = RecievedObject.row.original
      ) => {
        let result = row.createdByUser?.name?.[lang];
        return result;
      },
      enableClickToCopy: true,
      filterVariant: "multi-select",
    },
    {
      accessorKey: "comment",
      permissionModel: "comment",
      header: config.translate.comment[lang],
      size: 80,
      Cell: (
        RecievedObject,
        cell = RecievedObject.renderedCellValue,
        row = RecievedObject.row.original
      ) => {
        let result = "";
        if (row.comment && row.comment.length > 0) {
          result = (
            <>
              <IconBrandHipchat size={30} />
              <Badge size="md" circle>
                {row.comment.length}
              </Badge>
            </>
          );
        }
        return result;
      },
    },
  ];

  const renderDetailPanel = ({ row }) => (
    <Grid gutter="xs">
      <Grid.Col span={4}>
        <Title order={3} mb={4}>
          {config.translate.problem[lang]}
        </Title>
        <Flex gap="xs" align="center">
          {row.original.objective?.room?.name && (
            <Link to={`/room/${row.original.objective?.room._id}`}>
              <Box
                style={(Theme) => ({
                  backgroundColor: Theme.colors.red[7],
                  borderRadius: "4px",
                  color: "#fff",
                  padding: "4px",
                })}
              >
                {row.original.objective?.room?.name}
              </Box>
            </Link>
          )}
          {row.original.objective?.pc?.name && (
            <>
              {row.original.objective?.room ? " - " : ""}
              <Link to={`/pc/${row.original.objective?.pc._id}`}>
                <Box
                  style={(Theme) => ({
                    backgroundColor: Theme.colors.blue[7],
                    borderRadius: "4px",
                    color: "#fff",
                    padding: "4px",
                  })}
                >
                  {row.original.objective?.pc?.name}
                </Box>
              </Link>{" "}
              -
            </>
          )}
          {row.original.objective?.problem?.name?.[lang] && (
            <>
              -
              <Link to={`/problem/${row.original.objective?.problem._id}`}>
                <Box
                  style={(Theme) => ({
                    backgroundColor: Theme.colors.orange[7],
                    borderRadius: "4px",
                    color: "#fff",
                    padding: "4px",
                  })}
                >
                  {row.original.objective?.problem?.name?.[lang]}
                </Box>
              </Link>
            </>
          )}
          {row.original.objective?.smb?.name?.[lang] && (
            <>
              {" "}
              -
              <Link to={`/smb/${row.original.objective?.smb._id}`}>
                <Box
                  style={(Theme) => ({
                    backgroundColor: Theme.colors.yellow[9],
                    borderRadius: "4px",
                    color: "#fff",
                    padding: "4px",
                  })}
                >
                  {row.original.objective?.smb?.name?.[lang]}
                </Box>
              </Link>
            </>
          )}
          {row.original.objective?.software?.name?.[lang] && (
            <>
              {" "}
              -
              <Link to={`/software/${row.original.objective?.software._id}`}>
                <Box
                  style={(Theme) => ({
                    backgroundColor: Theme.colors.cyan[9],
                    borderRadius: "4px",
                    color: "#fff",
                    padding: "4px",
                  })}
                >
                  {row.original.objective?.software?.name?.[lang]}
                </Box>
              </Link>
            </>
          )}
          {row.original.objective?.printer?.name?.[lang] && (
            <>
              -
              <Link to={`/printer/${row.original.objective?.printer._id}`}>
                <Box
                  style={(Theme) => ({
                    backgroundColor: Theme.colors.cyan[9],
                    borderRadius: "4px",
                    color: "#fff",
                    padding: "4px",
                  })}
                >
                  {row.original.objective?.printer?.name?.[lang]}
                </Box>
              </Link>
            </>
          )}
        </Flex>
      </Grid.Col>
      {row.original.desc && row.original.desc.length > 0 ? (
        <Grid.Col span={4}>
          <Title order={4} mb={4} mt={4}>
            {config.translate.problemDescription[lang]}
          </Title>
          <Box
            p={10}
            style={{
              border: "1px solid #e0e0e0",
              borderRadius: "5px",
              color:
                colorScheme === "dark"
                  ? Theme.colors.gray[2]
                  : Theme.colors.gray[8],
            }}
          >
            <Flex justify="space-between">
              {parse(row.original.desc ? row.original.desc : "")}
            </Flex>
          </Box>
        </Grid.Col>
      ) : null}
      {row.original.comment && row.original.comment.length > 0 ? (
        <Grid.Col mt={10} span={4}>
          <Title order={4}>{config.translate.comment[lang]}</Title>
          <Flex direction="column" gap={10}>
            {row.original.comment.map((item, index) => {
              return (
                <Box
                  key={index}
                  p={10}
                  style={{
                    border: "1px solid #e0e0e0",
                    borderRadius: "5px",
                  }}
                >
                  <Flex justify="space-between">
                    <Text size="sm" color="gray">
                      {item.user?.name?.[lang]}
                    </Text>
                    <Text size="sm" color="gray">
                      {new Date(item.date).toLocaleString()}
                    </Text>
                  </Flex>
                  <Text mt={10}>{item.desc}</Text>
                </Box>
              );
            })}
          </Flex>
        </Grid.Col>
      ) : null}
    </Grid>
  );

  return (
    <ListPage
      route={config.api.ticket}
      query={
        user.department?._id === "64a2e60e31eebe0f70c6478d"
          ? null
          : {
              createdByUser: user._id,
            }
      }
      sorting={{ ID: "desc" }}
      limit={10}
      uploadType={true}
      populate={[
        // "history.user",
        "status",
        "createdByUser",
        "objective.problem",
        "objective.pc",
        "objective.smb",
        "objective.software",
        "objective.room",
        "objective.printer",
        "comment.user",
      ]}
      columns={columns}
      renderDetailPanel={renderDetailPanel}
      permissionModel={"ticket"}
      enableFacetedValues={true}
    />
  );
}
