import React, { useMemo } from "react";
import { ListPage } from "../ListPage";
import config from "../../config";
import { useUser } from "../../helpers/userContext";
import { useLang } from "../../helpers/language";
import parse from "html-react-parser";

export default function Problems() {
  const { user } = useUser();
  const { lang } = useLang();

  const columns = [
    {
      accessorKey: "index",
      permissionModel: "index",
      header: config.translate.index[lang],
      enableClickToCopy: true,
      size: 30,
      Cell: (
        RecievedObject,
        cell = RecievedObject.renderedCellValue,
        row = RecievedObject.row.original
      ) => {
        let result = row.index;
        return result;
      },
    },
    {
      accessorFn: (row) => `${row?.name.en} ${row?.name.ru} ${row?.name.hy}`,
      permissionModel: "name",
      header: config.translate.name[lang],
      enableClickToCopy: true,
      grow: true,
      Cell: (
        RecievedObject,
        cell = RecievedObject.renderedCellValue,
        row = RecievedObject.row.original
      ) => {
        let result = parse(row.name[lang] ? row.name[lang] : "...");
        return result;
      },
    },
    {
      accessorKey: "relatedList",
      header: config.translate.relatedList[lang],
      permissionModel: "relatedList",
      enableClickToCopy: true,
      Cell: (
        RecievedObject,
        cell = RecievedObject.renderedCellValue,
        row = RecievedObject.row.original
      ) => {
        let result = parse(row.relatedList ? row.relatedList : "...");
        return result;
      },
    },
  ];
  return (
    <ListPage
      route={config.api.problem}
      query={{}}
      sorting={{ index: "asc" }}
      permissionModel={"problem"}
      uploadType={null}
      limit={null}
      columns={columns}
    />
  );
}
