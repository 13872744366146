import React, { useState, useEffect } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import SinglePage from "../SinglePage";
import AddPage from "./ticketAdd";
import * as API from "../../helpers/api";
import config from "../../config";
import Loading from "../modules/loading";
import { useUser } from "../../helpers/userContext";
import moment from "moment";
import parse from "html-react-parser";
import { useLang } from "../../helpers/language";
import Swal from "sweetalert2";
import { UPDATE } from "../../helpers/CRUD";
import NProgress, { set } from "nprogress";

export default function Ticket() {
  const [isBusy, setBusy] = useState(true);
  let id = useParams().id;
  let { search } = useLocation();
  search = search.substring(1).split("=");
  let tempMode = search[0] === "temprorary" && search[1] === "true";
  const route = config.api.ticket;
  const { user } = useUser();
  const { lang } = useLang();
  const history = useHistory();

  const [index, setindex] = useState("");
  const [ID, setID] = useState("");
  const [pcs, setpcs] = useState("");
  const [statuses, setstatuses] = useState("");
  const [status, setStatus] = useState("");
  const [statusForHistory, setStatusForHistory] = useState("");
  const [statusWithoutClose, setstatusWithoutClose] = useState("");
  const [closeStatus, setCloseStatus] = useState("");

  const [rooms, setrooms] = useState("");
  const [printers, setprinters] = useState("");
  const [softwares, setsoftwares] = useState("");
  const [smbs, setsmbs] = useState("");

  const [desc, setDesc] = useState("");
  const [openedDate, setopenedDate] = useState(new Date());
  const [closedDate, setclosedDate] = useState("");
  const [History, setHistory] = useState([{}]);
  const [comment, setcomment] = useState(undefined);
  const [User, setUser] = useState([{}]);
  const [createdByUser, setcreatedByUser] = useState("");
  const [sendButtonState, setsendButtonState] = useState(true);
  const [lastStatus, setLastStatus] = useState();
  const [firstStatus, setFirstStatus] = useState();
  const [image, setimage] = useState("");
  const [permissions, setPermissions] = useState([]);

  const [objective, setObjective] = useState({
    problem: null,
    problemRelatedList: null,
    pc: {},
    smb: null,
    software: null,
    room: {},
    printer: null,
  });

  const [canSendState, setCanSendState] = useState(false);

  const [problems, setproblems] = useState(null);

  const commentColumns = [
    {
      accessorKey: "date",
      header: config.translate.date[lang],
      Cell: (
        RecievedObject,
        cell = RecievedObject.renderedCellValue,
        row = RecievedObject.row.original
      ) => {
        let result =
          moment(row.date).isValid() &&
          moment(row.date).format("HH:mm:ss | DD/MM/YYYY");
        return result;
      },
    },
    {
      accessorKey: "comment",
      header: config.translate.comment[lang],
      Cell: (
        RecievedObject,
        cell = RecievedObject.renderedCellValue,
        row = RecievedObject.row.original
      ) => {
        let result = parse(row.desc ? row.desc : "...");
        console.log("🚀 ~ Ticket ~ row:", row);
        return result.length > 20 ? result.slice(0, 20) + "..." : result;
      },
    },
    {
      accessorFn: (row) => `${row.name} ${row.name?.ru} ${row.name?.hy}`,
      header: config.translate.name[lang],
      Cell: (
        RecievedObject,
        cell = RecievedObject.renderedCellValue,
        row = RecievedObject.row.original
      ) => {
        let result = parse(
          row.user?.name?.[lang] ? row.user?.name?.[lang] : "No user"
        );
        return result.length > 20 ? result.slice(0, 20) + "..." : result;
      },
    },
  ];

  useEffect(() => {}, [objective]);

  let modelSendToServer = {};

  modelSendToServer = {
    index,
    openedDate,
    closedDate,
    desc,
    ID,
    objective: {
      problem: objective?.problem,
      pc: objective?.pc,
      smb: objective?.smb,
      software: objective?.software,
      room: objective?.room,
      printer: objective?.printer,
      problemRelatedList: objective?.problemRelatedList,
    },
    status,
    createdByUser,
    history: History,
    comment,
  };

  useEffect(() => {
    (async () => {
      NProgress.start();
      // all problems
      let rawproblems = await API.get(config.api.problem, {
        parent: null,
      });
      if (rawproblems) setproblems(rawproblems);

      // all pcs
      let rawpcs = await API.get(config.api.pc);
      if (rawpcs) setpcs(rawpcs);

      // all server shared folders
      let rawsmbs = await API.get(config.api.smb);
      if (rawsmbs) setsmbs(rawsmbs);

      // all rooms
      let rawroom = await API.get(config.api.room);
      if (rawroom) setrooms(rawroom);

      // all printer
      let rawprinters = await API.get(config.api.printer);
      let formattedPrinters = [];
      rawprinters.forEach((printer) => {
        if (printer.room) {
          rawroom.forEach((room) => {
            if (printer.room === room._id) {
              let newPrinterWithRoom = { ...printer };
              newPrinterWithRoom.room = room;
              newPrinterWithRoom.name = (() => {
                let result = printer.name || {};
                result = "Room " + room?.name + " | " + result;
                return result;
              })();
              formattedPrinters.push(newPrinterWithRoom);
            }
          });
        }
      });
      if (rawprinters) setprinters(formattedPrinters);

      // all software
      let rawsoftwares = await API.get(config.api.software);
      if (rawsoftwares) setsoftwares(rawsoftwares);

      // all users
      let rawusers = await API.get(config.api.user);
      if (rawusers) setUser(rawusers);

      // all statuses
      let rawstatus = await API.get(config.api.status, {}, { index: 1 });
      const firstSetStatus = rawstatus[0]._id;
      setCloseStatus(rawstatus[rawstatus.length - 1]._id);

      if (rawstatus) {
        rawstatus.shift();
        const statuses = rawstatus.map((item) => item);
        rawstatus.pop();
        const statusWithoutCloses = rawstatus.map((item) => item);
        setstatuses(statuses);
        setstatusWithoutClose(statusWithoutCloses);
      }

      let rawID = await API.get(
        route,
        { temprorary: { $ne: true } },
        { ID: -1 },
        {},
        1
      );

      // select pc automate if there is no pc selected, use user's pc,
      if (
        objective &&
        objective?.pc &&
        Object.keys(objective?.pc).length === 0
      ) {
        let userPC = rawpcs.find((pc) => pc._id === user.pc);
        if (userPC) {
          setObjective({
            ...objective,
            pc: userPC._id,
            room: userPC.room,
          });
        }
      }

      if (!tempMode) {
        let currentItem = await API.get(
          route,
          {
            _id: id,
          },
          null,
          true,
          1,
          ["createdByUser", "history.user", "comment.user"]
        );

        setID(
          currentItem[0]?.ID
            ? currentItem[0]?.ID
            : rawID[0]?.ID
            ? rawID[0]?.ID + 1
            : 1
        );
        setindex(currentItem[0]?.index);
        setDesc(currentItem[0]?.desc);
        setHistory(currentItem[0]?.history);
        setcomment(currentItem[0]?.comment);
        setObjective(currentItem[0]?.objective);
        setcreatedByUser(currentItem[0]?.createdByUser);
        setStatus(currentItem[0]?.status);
        currentItem[0]?.openedDate && setopenedDate(currentItem[0]?.openedDate);
        currentItem[0]?.closedDate && setclosedDate(currentItem[0]?.closedDate);

        setimage(undefined);
        currentItem[0]?.uploads?.map((item) => {
          if (item.destiny === "image") setimage(item);
          return item;
        });
        if (
          objective &&
          objective?.pc &&
          Object.keys(objective?.pc).length === 0
        ) {
          let userPC = rawpcs.find((pc) => pc._id === user.pc);
          if (userPC) {
            setObjective({
              ...objective,
              pc: userPC._id,
              room: userPC.room,
            });
          }
        }
      } else {
        let currentItem = await API.get(
          route,
          {
            _id: id,
          },
          null,
          true,
          1,
          ["history.user", "comment.user", "createdByUser"]
        );
        setID(
          currentItem[0]?.ID
            ? currentItem[0]?.ID
            : rawID[0]?.ID
            ? rawID[0]?.ID + 1
            : 1
        );
        setimage(undefined);
        currentItem[0]?.uploads?.map((item) => {
          if (item.destiny === "image") setimage(item);
          return item;
        });
        setcreatedByUser(user._id);
        setStatus(firstSetStatus);
        if (
          objective &&
          objective?.pc &&
          Object.keys(objective?.pc).length === 0
        ) {
          let userPC = rawpcs.find((pc) => pc._id === user.pc);
          if (userPC) {
            setObjective({
              ...objective,
              pc: userPC._id,
              room: userPC.room,
            });
          }
        }
      }
      setBusy(false);
    })();

    // eslint-disable-next-line
  }, [isBusy]);

  useEffect(() => {
    // eslint-disable-next-line
  }, [modelSendToServer.history]);

  useEffect(() => {
    if (status) {
      statuses.forEach((item) => {
        if (item._id === status) {
          setStatusForHistory(item);
        }
      });
    }
    // eslint-disable-next-line
  }, [status]);

  useEffect(() => {
    (async () => {
      // get last status id
      let rawStatuses = await API.get(
        config.api.status,
        {},
        {
          index: "asc",
        }
      );
      if (rawStatuses?.length > 0) {
        setLastStatus(rawStatuses[rawStatuses.length - 2]._id);
        setFirstStatus(rawStatuses[0]._id);
      }
      rawStatuses.forEach((item) => {
        if (item._id === status) {
          setStatusForHistory(item);
        }
      });
    })();
    let tempPermissions = [];
    user?.role?.permissions?.forEach((permission) => {
      if (permission.name === "ticket") {
        permission.inputs.forEach((input) => {
          tempPermissions.push(input);
        });
      }
    });
    setPermissions([...tempPermissions]);
  }, []);

  const translate = (translater) => {
    try {
      return config.translate[translater][lang]
        ? config.translate[translater][lang]
        : "There is no text";
    } catch (error) {
      console.error(error);
    }
  };

  const closeTicket = () => {
    modelSendToServer.status = closeStatus;
    modelSendToServer.closedDate = new Date();
    Swal.fire({
      title: config.translate.areYouSure[lang],
      text: config.translate.youCannotRevertThisAction[lang],
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: config.translate.yes[lang],
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await UPDATE({
            modelSendToServer,
            route,
            history,
            setBusy,
            id,
            lang,
            Status: statusForHistory,
          });
          setBusy(true);
        } catch (error) {
          console.error(error);
        }
      }
    });
  };

  const commentTicket = () => {
    Swal.fire({
      title: config.translate.commentTicket[lang],
      input: "textarea",
      inputPlaceholder: config.translate.commentTicket[lang],
      showCancelButton: true,
      confirmButtonText: config.translate.save[lang],
      cancelButtonText: config.translate.cancel[lang],
      showLoaderOnConfirm: true,
      preConfirm: async (comment) => {
        modelSendToServer.comment = modelSendToServer.comment
          ? [
              ...modelSendToServer.comment,
              { user: user, date: new Date(), desc: comment },
            ]
          : [];
        try {
          await UPDATE({
            modelSendToServer,
            route,
            history,
            setBusy,
            id,
            lang,
            user,
            Status: { name: config.translate.comment },
          });
          setBusy(true);
        } catch (error) {
          console.error(error);
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  };

  useEffect(() => {
    if (objective) {
      let state =
        objective?.pc &&
        Object.keys(objective?.pc).length !== 0 &&
        objective?.problem &&
        Object.keys(objective?.problem).length !== 0
          ? true
          : false;
      setCanSendState(state);
    }
  }, [objective]);

  useEffect(() => {}, [canSendState]);

  if (isBusy) return <Loading />;
  else if (tempMode)
    return (
      <AddPage
        isBusy={isBusy}
        setBusy={setBusy}
        route={route}
        id={id}
        permissionModel="ticket"
        modelSendToServer={modelSendToServer}
        permissions={permissions}
        canSendState={canSendState}
        user={user}
        inputs={[
          {
            value: objective.pc,
            setter: (value) => {
              setObjective({
                pc: value,
                room: pcs.find((pc) => pc._id === value).room,
              });
            },
            optionListValue: pcs,
            type: "optionlist",
            usageType: "optionlist",
            label: config.translate.pcs[lang],
            permissionModel: "pc",
          },
          objective.pc
            ? {
                setter: (value) => {
                  setObjective({
                    ...objective,
                    problem: value,
                    problemRelatedList: problems.find(
                      (problem) => problem._id === value
                    )?.relatedList,
                  });
                },
                value: objective.problem,
                optionListValue: problems,
                type: "optionlist",
                usageType: "optionlist",
                label: config.translate.problem[lang],
                permissionModel: "problem",
              }
            : {
                value: objective?.problem,
                type: "optionlist",
                usageType: "optionlist",
                optionListValue: problems,
                label: config.translate.problem[lang],
                disabled: true,
                permissionModel: "problem",
              },
          objective?.problem && objective.problemRelatedList === "printer"
            ? {
                setter: (value) => {
                  setObjective({
                    ...objective,
                    printer: value,
                  });
                },
                value: objective.printer,
                optionListValue: printers,
                type: "optionlist",
                usageType: "optionlist",
                label: config.translate.printer[lang],
                permissionModel: "printer",
              }
            : null,
          objective?.problem && objective.problemRelatedList === "software"
            ? {
                setter: (value) => {
                  setObjective({
                    ...objective,
                    software: value,
                  });
                },
                value: objective.software,
                optionListValue: softwares,
                type: "optionlist",
                usageType: "optionlist",
                label: config.translate.software[lang],
                permissionModel: "software",
              }
            : null,
          objective?.problem && objective.problemRelatedList === "smb"
            ? {
                setter: (value) => {
                  setObjective({
                    ...objective,
                    smb: value,
                  });
                },
                value: objective.smb,
                optionListValue: smbs,
                type: "optionlist",
                usageType: "optionlist",
                label: config.translate.smb[lang],
                permissionModel: "smb",
              }
            : null,
          {
            value: parse(desc ? desc : ""),
            setter: setDesc,
            width: 12,
            type: "text",
            usageType: "textfield",
            label: config.translate.description[lang],
            permissionModel: "desc",
          },
        ]}
        filesComponent={[
          {
            destiny: "image",
            file: image,
            setFile: setimage,
            filesUploadLimit: 1,
            filesMaxSize: 5,
            acceptedFiles: ["image/png", "image/jpeg", "image/jpg"],
            adminMode: user.role !== "editor",
            height: 500,
            placeholder: "Upload image",
            permissionModel: "image",
          },
        ]}
      />
    );
  else
    return (
      <SinglePage
        isBusy={isBusy}
        setBusy={setBusy}
        route={route}
        id={id}
        chatID={"NQcWJwwsbwuHTeMGJoP8tvaEHgprSB29gf"}
        Status={statusForHistory}
        permissionModel="ticket"
        firstStatus={firstStatus}
        lastStatus={lastStatus}
        modelSendToServer={modelSendToServer}
        sendButtonState={sendButtonState}
        commentTicket={commentTicket}
        closeTicket={closeTicket}
        permissions={permissions}
        inputs={[
          {
            value: objective?.room,
            type: "optionlist",
            usageType: "optionlist",
            optionListValue: rooms,
            label: config.translate.room[lang],
            disabled: true,
            permissionModel: "room",
          },
          {
            value: ID,
            type: "text",
            usageType: "textfield",
            label: "ID",
            disabled: true,
            permissionModel: "ID",
          },
          {
            value: createdByUser?.name?.[lang]
              ? createdByUser?.name?.[lang]
              : "No username",
            type: "text",
            usageType: "textfield",
            label: config.translate.user[lang],
            disabled: true,
            permissionModel: "createdByUser",
          },
          closedDate
            ? {
                value: config.translate.closed[lang],
                type: "text",
                usageType: "textfield",
                label: config.translate.status[lang],
                disabled: true,
                permissionModel: "endDate",
              }
            : {
                value: status,
                setter: setStatus,
                optionListValue: statusWithoutClose,
                type: "optionlist",
                usageType: "optionlist",
                label: config.translate.status[lang],
                permissionModel: "status",
              },
          {
            value: objective?.pc,
            type: "optionlist",
            usageType: "optionlist",
            optionListValue: pcs,
            label: config.translate.pc[lang],
            disabled: true,
            permissionModel: "pc",
          },
          objective?.problem && {
            value: objective?.problem,
            type: "optionlist",
            usageType: "optionlist",
            optionListValue: problems,
            label: config.translate.problem[lang],
            disabled: true,
            permissionModel: "problem",
          },
          objective?.printer
            ? {
                value: objective?.printer,
                type: "optionlist",
                usageType: "optionlist",
                optionListValue: printers,
                label: config.translate.printer[lang],
                disabled: true,
                permissionModel: "printer",
              }
            : null,
          objective?.software
            ? {
                value: objective?.software,
                type: "optionlist",
                usageType: "optionlist",
                optionListValue: softwares,
                label: config.translate.software[lang],
                disabled: true,
                permissionModel: "software",
              }
            : null,
          objective?.smb
            ? {
                value: objective?.smb,
                type: "optionlist",
                usageType: "optionlist",
                optionListValue: smbs,
                label: config.translate.smb[lang],
                disabled: true,
                permissionModel: "smb",
              }
            : null,
          {
            value: openedDate,
            // setter: setopenedDate,
            type: "date",
            disabled: true,
            usageType: "datePicker",
            label: config.translate.openedDate[lang],
            placeholder: config.translate.openedDate[lang],
            permissionModel: "startDate",
          },
          closedDate && {
            value: closedDate,
            setter: setclosedDate,
            disabled: true,
            type: "date",
            usageType: "datePicker",
            label: config.translate.closedDate[lang],
            placeholder: config.translate.selectClosedDate[lang],
            permissionModel: "endDate",
          },
          closedDate && {
            value:
              moment(moment(closedDate) - moment(openedDate)).format("m") +
              ` ${config.translate.minutes[lang]}`,
            setter: setclosedDate,
            disabled: true,
            type: "text",
            usageType: "textfield",
            label: config.translate.lasts[lang],
            placeholder: config.translate.selectClosedDate[lang],
            permissionModel: "endDate",
          },
          desc &&
            desc.length > 1 && {
              value: parse(desc ? desc : ""),
              width: 12,
              type: "text",
              usageType: "textfield",
              label: config.translate.description[lang],
              disabled: true,
              permissionModel: "desc",
            },
        ]}
        timelineComponent={[
          History &&
            History.length > 0 &&
            Object.keys(History[0]).length > 0 && {
              placeholder: config.translate.history?.[lang],
              data: History,
              permissionModel: "history",
            },
          comment &&
            comment.length > 0 && {
              placeholder: translate("commentTicket"),
              data: comment,
              columns: commentColumns,
              permissionModel: "comment",
            },
        ]}
        filesComponent={[
          {
            destiny: "image",
            file: image,
            setFile: setimage,
            filesUploadLimit: 1,
            filesMaxSize: 5,
            acceptedFiles: ["image/png", "image/jpeg", "image/jpg"],
            adminMode: user.role !== "editor",
            height: 500,
            placeholder: "Upload image",
            permissionModel: "image",
          },
        ]}
      />
    );
}
