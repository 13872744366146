import React, { useState, useEffect } from "react";
import SinglePage from "../SinglePage";
import { useParams } from "react-router-dom";
import * as API from "../../helpers/api";
import config from "../../config";
import Loading from "../modules/loading";
import NProgress, { set } from "nprogress";
import { useUser } from "../../helpers/userContext";
import { useLang } from "../../helpers/language";
import { Center, SegmentedControl, rem } from "@mantine/core";
import {
  IconEye,
  IconCode,
  IconExternalLink,
  IconBabyCarriage,
  IconRowRemove,
  IconHttpDelete,
  IconTrash,
  IconTrashX,
  IconUserCheck,
} from "@tabler/icons-react";

export default function User() {
  const [isBusy, setBusy] = useState(true);
  let { id } = useParams();
  const route = config.api.user;
  const { lang } = useLang();

  const roleParser = (role, field) => {
    if (role === "manager") {
      return field;
    } else if (role === "editor") {
      return null;
    } else {
      return field;
    }
  };

  const workingStatuses = [
    {
      label: config.translate.userArchive,
      value: "archive",
    },
    {
      label: config.translate.interview1,
      value: "interview1",
    },
    {
      label: config.translate.interview2,
      value: "interview2",
    },
    {
      label: config.translate.interview3,
      value: "interview3",
    },
    {
      label: config.translate.interview4,
      value: "interview4",
    },
    {
      label: config.translate.working,
      value: "working",
    },
    {
      label: config.translate.maternityLeave,
      value: "maternityLeave",
    },
    {
      label: config.translate.fired,
      value: "fired",
    },
  ];

  const [index, setindex] = useState("");
  const [username, setusername] = useState("");
  const [password, setpassword] = useState("");
  const [email, setemail] = useState("");
  const [phone, setphone] = useState("");
  const [desc, setdesc] = useState("");

  const [passportNo, setPassportNo] = useState("");
  const [passportStartDate, setPassportStartDate] = useState("");
  const [passportEndDate, setPassportEndDate] = useState("");
  const [idCard, setIdCard] = useState("");
  const [idCardStartDate, setIdCardStartDate] = useState("");
  const [idCardEndDate, setIdCardEndDate] = useState("");
  const [contractStartDate, setContractStartDate] = useState("");
  const [contractEndDate, setContractEndDate] = useState("");

  const [socialCard, setSocialCard] = useState("");
  const [bankEmitet, setBankEmitet] = useState("");
  const [bankAccount, setBankAccount] = useState("");
  const [socialNetworkLink, setSocialNetworkLink] = useState("");

  const [nameEn, setNameEn] = useState("");
  const [nameRu, setNameRu] = useState("");
  const [nameHy, setNameHy] = useState("");
  const [firstNameEn, setfirstNameEn] = useState("");
  const [firstNameRu, setfirstNameRu] = useState("");
  const [firstNameHy, setfirstNameHy] = useState("");
  const [lastNameEn, setlastNameEn] = useState("");
  const [lastNameRu, setlastNameRu] = useState("");
  const [lastNameHy, setlastNameHy] = useState("");
  const [addressEn, setAddressEn] = useState("");
  const [addressRu, setAddressRu] = useState("");
  const [addressHy, setAddressHy] = useState("");
  const [registeredAddressEn, setRegisteredAddressEn] = useState("");
  const [registeredAddressRu, setRegisteredAddressRu] = useState("");
  const [registeredAddressHy, setRegisteredAddressHy] = useState("");
  const [department, setdepartment] = useState("");
  const [departments, setdepartments] = useState("");
  const [position, setposition] = useState("");
  const [positions, setpositions] = useState("");
  const [pc, setpc] = useState("");
  const [pcs, setpcs] = useState("");
  const [dateOfBirth, setdateOfBirth] = useState("");
  const [workingStatus, setworkingStatus] = useState({
    dateStarts: new Date(),
    status: "",
  });
  const [gender, setgender] = useState("");
  const [role, setrole] = useState("");
  const [roles, setroles] = useState("");
  const [image, setimage] = useState(undefined);
  const { user } = useUser();
  const [chatID, setchatID] = useState("");
  const [permissions, setPermissions] = useState([]);

  const genders = [
    {
      _id: "male",
      name: { en: "Male", ru: "Мужской", hy: "Արական" },
    },
    {
      _id: "female",
      name: { en: "Female", ru: "Женский", hy: "Իգական" },
    },
  ];

  const modelSendToServer = {
    name: {
      en: firstNameEn + " " + lastNameEn,
      ru: firstNameRu + " " + lastNameRu,
      hy: firstNameHy + " " + lastNameHy,
    },
    firstName: {
      en: firstNameEn,
      ru: firstNameRu,
      hy: firstNameHy,
    },
    lastName: {
      en: lastNameEn,
      ru: lastNameRu,
      hy: lastNameHy,
    },
    address: {
      en: addressEn,
      ru: addressRu,
      hy: addressHy,
    },
    registeredAddress: {
      en: registeredAddressEn,
      ru: registeredAddressRu,
      hy: registeredAddressHy,
    },
    username,
    password,
    desc,
    email,
    phone,
    passportNo,
    passportStartDate,
    passportEndDate,
    idCard,
    idCardStartDate,
    idCardEndDate,
    contractStartDate,
    contractEndDate,
    socialCard,
    bankEmitet,
    bankAccount,
    socialNetworkLink,
    dateOfBirth,
    gender,
    role: role || null,
    pc: pc || null,
    index,
    chatID,
    workingStatus,
    department: department || null,
    position: position || null,
  };

  useEffect(() => {
    NProgress.start();
    (async () => {
      let rawDepartments = await API.get(config.api.department);
      if (rawDepartments) setdepartments(rawDepartments);
      let rawPositions = await API.get(config.api.position);
      if (rawPositions) setpositions(rawPositions);
      let rawPCs = await API.get(config.api.pc);
      if (rawPCs) setpcs(rawPCs);
      let rawRoles = await API.get(config.api.role);
      if (rawRoles) setroles(rawRoles);

      if (id !== "add") {
        let currentItem = await API.get(
          route,
          {
            _id: id,
          },
          {},
          true,
          1
        );

        setindex(currentItem[0]?.index);
        setgender(currentItem[0]?.gender);

        setdesc(currentItem[0]?.desc);
        setrole(currentItem[0]?.role);
        setusername(currentItem[0]?.username);
        setchatID(currentItem[0]?.chatID);
        setpassword(currentItem[0]?.password);
        setemail(currentItem[0]?.email);
        setphone(currentItem[0]?.phone?.replace(/\s/g, "").replace(/^0+/, ""));

        setPassportNo(currentItem[0]?.passportNo);
        setPassportStartDate(currentItem[0]?.passportStartDate);
        setPassportEndDate(currentItem[0]?.passportEndDate);
        setIdCard(currentItem[0]?.idCard);
        setIdCardStartDate(currentItem[0]?.idCardStartDate);
        setIdCardEndDate(currentItem[0]?.idCardEndDate);

        setContractStartDate(currentItem[0]?.contractStartDate);
        setContractEndDate(currentItem[0]?.contractEndDate);
        setSocialCard(currentItem[0]?.socialCard);
        setBankEmitet(currentItem[0]?.bankEmitet);
        setBankAccount(currentItem[0]?.bankAccount);
        setSocialNetworkLink(currentItem[0]?.socialNetworkLink);

        setNameEn(currentItem[0]?.name?.en);
        setNameRu(currentItem[0]?.name?.ru);
        setNameHy(currentItem[0]?.name?.hy);
        setfirstNameEn(currentItem[0]?.firstName?.en);
        setfirstNameRu(currentItem[0]?.firstName?.ru);
        setfirstNameHy(currentItem[0]?.firstName?.hy);
        setlastNameEn(currentItem[0]?.lastName?.en);
        setlastNameRu(currentItem[0]?.lastName?.ru);
        setlastNameHy(currentItem[0]?.lastName?.hy);
        setAddressEn(currentItem[0]?.address?.en);
        setAddressRu(currentItem[0]?.address?.ru);
        setAddressHy(currentItem[0]?.address?.hy);
        setRegisteredAddressEn(currentItem[0]?.registeredAddress?.en);
        setRegisteredAddressRu(currentItem[0]?.registeredAddress?.ru);
        setRegisteredAddressHy(currentItem[0]?.registeredAddress?.hy);
        setworkingStatus(currentItem[0]?.workingStatus);
        currentItem[0]?.dateOfBirth &&
          setdateOfBirth(currentItem[0]?.dateOfBirth);

        setdepartment(currentItem[0]?.department);
        setposition(currentItem[0]?.position);
        setpc(currentItem[0]?.pc);
        setrole(currentItem[0]?.role);

        setimage(undefined);
        currentItem[0]?.uploads?.map((item) => {
          if (item.destiny === "image") setimage(item);
          return item;
        });
        genders.forEach((item) => {
          if (item._id === currentItem[0]?.gender) {
            setgender(item._id);
          }
        });
      }
      setBusy(false);
    })();
    // eslint-disable-next-line
  }, [isBusy]);

  useEffect(() => {
    let tempPermissions = [];
    user?.role?.permissions?.forEach((permission) => {
      if (permission.name === "ticket") {
        permission.inputs.forEach((input) => {
          tempPermissions.push(input);
        });
      }
    });
    setPermissions([...tempPermissions]);
  }, []);

  if (isBusy) return <Loading />;
  else
    return (
      <SinglePage
        isBusy={isBusy}
        setBusy={setBusy}
        route={route}
        chatID={chatID}
        id={id}
        permissionModel="user"
        permissions={permissions}
        modelSendToServer={modelSendToServer}
        inputs={[
          {
            value: chatID,
            setter: setchatID,
            type: "text",
            usageType: "textfield",
            label: config.translate.chatID[lang],
            placeholder: config.translate.chatID[lang],
            permissionModel: "chatID",
          },
          {
            value: index,
            setter: setindex,
            type: "number",
            usageType: "number",
            label: config.translate.index[lang],
            placeholder: config.translate.index[lang],
            permissionModel: "index",
          },
          {
            value: role,
            setter: setrole,
            optionListValue: (roles || []).map((role) => {
              return {
                label: roleParser(user.role, role.name[lang]),
                value: role._id,
              };
            }),
            type: "optionlist",
            usageType: "optionlist",
            label: config.translate.role[lang],
            permissionModel: "role",
          },
          {
            value: username,
            setter: setusername,
            type: "text",
            usageType: "textfield",
            label: config.translate.login[lang],
            placeholder: config.translate.login[lang],
            permissionModel: "username",
          },
          {
            value: password,
            setter: setpassword,
            type: "password",
            usageType: "password",
            label: config.translate.password[lang],
            placeholder: config.translate.password[lang],
            permissionModel: "password",
          },
          {
            value: department,
            setter: setdepartment,
            optionListValue: (departments || []).map((department) => {
              return {
                label: department.name[lang],
                value: department._id,
              };
            }),
            type: "optionlist",
            usageType: "optionlist",
            label: config.translate.department[lang],
            permissionModel: "department",
          },
          {
            value: position,
            setter: setposition,
            optionListValue: (positions || []).map((position) => {
              return {
                label: position.name[lang],
                value: position._id,
              };
            }),
            type: "optionlist",
            usageType: "optionlist",
            label: config.translate.position[lang],
            permissionModel: "position",
          },
          {
            value: pc,
            setter: setpc,
            optionListValue: pcs,
            type: "optionlist",
            usageType: "optionlist",
            label: config.translate.pc[lang],
            permissionModel: "pc",
          },
          {
            value: firstNameEn,
            setter: setfirstNameEn,
            type: "text",
            usageType: "textfield",
            label: config.translate.firstName[lang],
            placeholder: config.translate.inputInLatin[lang],
            lang: "en",
            permissionModel: "firstName",
          },
          {
            value: lastNameEn,
            setter: setlastNameEn,
            type: "text",
            usageType: "textfield",
            label: config.translate.lastName[lang],
            placeholder: config.translate.inputInLatin[lang],
            permissionModel: "lastName",
            lang: "en",
          },
          {
            value: firstNameRu,
            setter: setfirstNameRu,
            type: "text",
            usageType: "textfield",
            label: config.translate.firstName[lang],
            placeholder: config.translate.inputInRussian[lang],
            permissionModel: "firstName",
            lang: "ru",
          },
          {
            value: lastNameRu,
            setter: setlastNameRu,
            type: "text",
            usageType: "textfield",
            label: config.translate.lastName[lang],
            placeholder: config.translate.inputInRussian[lang],
            permissionModel: "lastName",
            lang: "ru",
          },
          {
            value: firstNameHy,
            setter: setfirstNameHy,
            type: "text",
            usageType: "textfield",
            label: config.translate.firstName[lang],
            placeholder: config.translate.inputInArmenian[lang],
            lang: "hy",
            permissionModel: "firstName",
          },
          {
            value: lastNameHy,
            setter: setlastNameHy,
            type: "text",
            usageType: "textfield",
            label: config.translate.lastName[lang],
            placeholder: config.translate.inputInArmenian[lang],
            permissionModel: "lastName",
            lang: "hy",
          },
          {
            value: addressHy,
            setter: setAddressHy,
            type: "text",
            usageType: "textfield",
            label: "Հասցե",
            placeholder: config.translate.inputInArmenian[lang],
            permissionModel: "address",
            lang: "hy",
          },
          {
            value: addressRu,
            setter: setAddressRu,
            type: "text",
            usageType: "textfield",
            label: "Адрес",
            placeholder: config.translate.inputInRussian[lang],
            permissionModel: "address",
            lang: "ru",
          },
          {
            value: addressEn,
            setter: setAddressEn,
            type: "text",
            usageType: "textfield",
            label: "Address",
            placeholder: config.translate.inputInLatin[lang],
            permissionModel: "address",
            lang: "en",
          },
          {
            value: registeredAddressHy,
            setter: setRegisteredAddressHy,
            type: "text",
            usageType: "textfield",
            label: config.translate.registeredAddress[lang],
            placeholder: config.translate.inputInArmenian[lang],
            permissionModel: "registeredAddress",
            lang: "hy",
          },
          {
            value: registeredAddressRu,
            setter: setRegisteredAddressRu,
            type: "text",
            usageType: "textfield",
            label: config.translate.registeredAddress[lang],
            placeholder: config.translate.inputInRussian[lang],
            permissionModel: "registeredAddress",
            lang: "ru",
          },
          {
            value: registeredAddressEn,
            setter: setRegisteredAddressEn,
            type: "text",
            usageType: "textfield",
            label: config.translate.registeredAddress[lang],
            placeholder: config.translate.inputInLatin[lang],
            permissionModel: "registeredAddress",
            lang: "en",
          },
          {
            value: dateOfBirth,
            setter: setdateOfBirth,
            type: "date",
            usageType: "datePicker",
            label: config.translate.dateOfBirth[lang],
            placeholder: config.translate.dateOfBirth[lang],
            permissionModel: "dateOfBirth",
          },
          {
            value: gender,
            setter: setgender,
            optionListValue: genders,
            type: "text",
            usageType: "optionlist",
            label: config.translate.gender[lang],
            permissionModel: "gender",
          },
          {
            value: email,
            setter: setemail,
            type: "text",
            usageType: "email",
            label: config.translate.email[lang],
            permissionModel: "email",
          },
          {
            value: phone,
            setter: (phoneNumber) => {
              setphone(
                phoneNumber
                  .replace(/^0+/, "")
                  .replace(/-/g, "")
                  .replace(/\s/g, "")
                  .replace(/\(/g, "")
                  .replace(/\)/g, "")
              );
            },
            type: "text",
            usageType: "phone",
            label: config.translate.phone[lang],
            permissionModel: "phone",
          },
          {
            value: passportNo,
            setter: setPassportNo,
            type: "text",
            usageType: "textfield",
            label: config.translate.passportNo[lang],
            permissionModel: "passportNo",
          },
          {
            value: passportStartDate,
            setter: setPassportStartDate,
            type: "date",
            usageType: "datePicker",
            label: config.translate.passportStartDate[lang],
            permissionModel: "passportStartDate",
          },
          {
            value: passportEndDate,
            setter: setPassportEndDate,
            type: "date",
            usageType: "datePicker",
            label: config.translate.passportEndDate[lang],
            permissionModel: "passportEndDate",
          },
          {
            value: idCard,
            setter: setIdCard,
            type: "text",
            usageType: "idCard",
            label: config.translate.idCart[lang],
            permissionModel: "idCard",
          },
          {
            value: idCardStartDate,
            setter: setIdCardStartDate,
            type: "date",
            usageType: "datePicker",
            label: config.translate.idCardStartDate[lang],
            permissionModel: "idCardStartDate",
          },
          {
            value: idCardEndDate,
            setter: setIdCardEndDate,
            type: "date",
            usageType: "datePicker",
            label: config.translate.idCardEndDate[lang],
            permissionModel: "idCardEndDate",
          },
          {
            value: contractStartDate,
            setter: setContractStartDate,
            type: "date",
            usageType: "datePicker",
            label: config.translate.contractStartDate[lang],
            permissionModel: "contractStartDate",
          },
          {
            value: contractEndDate,
            setter: setContractEndDate,
            type: "date",
            usageType: "datePicker",
            label: config.translate.contractEndDate[lang],
            permissionModel: "contractEndDate",
          },
          {
            value: socialCard,
            setter: setSocialCard,
            type: "text",
            usageType: "textfield",
            label: config.translate.socialCart[lang],
            permissionModel: "socialCard",
          },
          {
            value: bankEmitet,
            setter: setBankEmitet,
            type: "text",
            usageType: "bank",
            label: config.translate.bankEmitet[lang],
            permissionModel: "bankEmitet",
          },
          {
            value: bankAccount,
            setter: setBankAccount,
            type: "text",
            usageType: "bank",
            label: config.translate.bankAccount[lang],
            permissionModel: "bankAccount",
          },
          {
            value: socialNetworkLink,
            setter: setSocialNetworkLink,
            type: "text",
            usageType: "url",
            label: config.translate.socialNetworkLink[lang],
            permissionModel: "socialNetworkLink",
          },
          {
            value: workingStatus,
            setter: setworkingStatus,
            optionListValue: workingStatuses,
            type: "optionlist",
            usageType: "optionlist",
            label: config.translate.workingStatus[lang],
            permissionModel: "workingStatus",
          },
          {
            value: desc,
            setter: setdesc,
            type: "text",
            usageType: "textarea-simple",
            label: config.translate.description[lang],
            placeholder: config.translate.description[lang],
            permissionModel: "desc",
          },
        ]}
        filesComponent={[
          {
            destiny: "image",
            placeholder: config.translate.image[lang],
            file: image,
            setFile: setimage,
            filesUploadLimit: 1,
            filesMaxSize: 5,
            adminMode: user.role !== "editor",
            height: 500,
            width: 500,
            permissionModel: "image",
          },
        ]}
      />
    );
}
