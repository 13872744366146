import React, { useMemo } from "react";
import { ListPage } from "../ListPage";
import { useUser } from "../../helpers/userContext";
import { useLang } from "../../helpers/language";
import parse from "html-react-parser";
import config from "../../config";

export default function Monitors() {
  const { user } = useUser();
  const { lang } = useLang();
  const columns = [
    // index
    {
      accessorKey: "index",
      permissionModel: "index",
      header: "ID",
      size: 30,
    },
    {
      accessorFn: (row) => `${row?.name}`,
      permissionModel: "name",
      header: config.translate.name[lang],
      Cell: (
        RecievedObject,
        cell = RecievedObject.renderedCellValue,
        row = RecievedObject.row.original
      ) => {
        let result = parse(row?.name ? row.name : "...");
        return result.length > 20 ? result.slice(0, 20) + "..." : result;
      },
    },
    {
      accessorFn: (row) => `${row?.pc?.name}`,
      permissionModel: "pc",
      header: config.translate.pc[lang],
      size: 50,
      Cell: (
        RecievedObject,
        cell = RecievedObject.renderedCellValue,
        row = RecievedObject.row.original
      ) => {
        let result = parse(row?.pc?.name ? row.pc?.name : "...");
        return result.length > 20 ? result.slice(0, 20) + "..." : result;
      },
    },
  ];
  return (
    <ListPage
      route={config.api.monitor}
      query={{}}
      sorting={{ index: "asc" }}
      permissionModel={"monitor"}
      uploadType={true}
      limit={null}
      populate={["pc"]}
      columns={columns}
    />
  );
}
