import React, { useMemo } from "react";
import { ListPage } from "../ListPage";
import config from "../../config";
import { useUser } from "../../helpers/userContext";
import { useLang } from "../../helpers/language";
import parse from "html-react-parser";

export default function Pcs() {
  const { user } = useUser();
  const { lang } = useLang();

  const columns = [
    {
      accessorFn: (row) => `${row.name}`,
      permissionModel: "name",
      header: config.translate.name[lang],
      size: 50,
      Cell: (
        RecievedObject,
        cell = RecievedObject.renderedCellValue,
        row = RecievedObject.row.original
      ) => {
        let result = parse(row.name ? row.name : "no description");
        return result.length > 20 ? result.slice(0, 20) + "..." : result;
      },
    },
    {
      accessorKey: "ip",
      permissionModel: "ip",
      header: "IP",
      enableClickToCopy: true,
      size: 130,
      Cell: (
        RecievedObject,
        cell = RecievedObject.renderedCellValue,
        row = RecievedObject.row.original
      ) => {
        let result = parse(row.ip ? row.ip : "...");
        return result.length > 20 ? result.slice(0, 20) + "..." : result;
      },
    },
    {
      accessorKey: "cpu",
      permissionModel: "cpu",
      header: "CPU",
      enableClickToCopy: true,
      size: 140,
      Cell: (
        RecievedObject,
        cell = RecievedObject.renderedCellValue,
        row = RecievedObject.row.original
      ) => {
        let result = parse(row.cpu ? row.cpu : "...");
        return result.length > 20 ? result.slice(0, 20) + "..." : result;
      },
    },
    {
      accessorKey: "gpu",
      permissionModel: "gpu",
      header: "GPU",
      enableClickToCopy: true,
      size: 210,
      Cell: (
        RecievedObject,
        cell = RecievedObject.renderedCellValue,
        row = RecievedObject.row.original
      ) => {
        let result = parse(row.gpu ? row.gpu : "...");
        return result.length > 30 ? result.slice(0, 30) + "..." : result;
      },
    },
    {
      accessorKey: "ram",
      permissionModel: "ram",
      header: "RAM",
      enableClickToCopy: true,
      size: 50,
      Cell: (
        RecievedObject,
        cell = RecievedObject.renderedCellValue,
        row = RecievedObject.row.original
      ) => {
        let result = parse(row.ram ? row.ram : "...");
        return result.length > 20 ? result.slice(0, 20) + "..." : result;
      },
    },
    {
      accessorFn: (row) => `${row?.room?.name}`,
      permissionModel: "room",
      header: config.translate.room[lang],
      size: 100,
      Cell: (
        RecievedObject,
        cell = RecievedObject.renderedCellValue,
        row = RecievedObject.row.original
      ) => {
        let result = parse(row?.room?.name ? row.room.name : "...");
        return result.length > 20 ? result.slice(0, 20) + "..." : result;
      },
    },
  ];

  return (
    <ListPage
      route={config.api.pc}
      query={{}}
      sorting={{ ip: "asc" }}
      permissionModel={"pc"}
      uploadType={true}
      limit={10}
      populate={["room"]}
      columns={columns}
    />
  );
}
