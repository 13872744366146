import React, { useState, useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { ChromePicker } from "react-color";
import Swal from "sweetalert2";
import { isIterable } from "../../helpers/global";
import parse from "html-react-parser";
import { useLang } from "../../helpers/language";
import config from "../../config";
import { Link } from "react-router-dom";
import { usePermissions } from "../../helpers/permissionsContext";
import {
  Input,
  PasswordInput,
  NumberInput,
  useMantineTheme,
  useMantineColorScheme,
  SegmentedControl,
  Card,
  Textarea,
  Select,
  Radio,
  Group,
} from "@mantine/core";
import dayjs from "dayjs";
import { DateInput, DateInputProps } from "@mantine/dates";
import {
  IconAt,
  IconPhone,
  IconWorldWww,
  IconShare3,
  IconCalendar,
  IconCashBanknote,
  IconBuildingBank,
  IconIdBadge,
  IconIdBadge2,
} from "@tabler/icons-react";
import { IMaskInput } from "react-imask";
// import Select from "react-select";
import { IconFaceId } from "@tabler/icons-react";
import Role from "../role/role";
import { IconFaceIdError } from "@tabler/icons-react";

let lastTheme = null;
let lightEditorTiming = (Math.random() * 100).toString() + 1;
let darkEditorTiming = (Math.random() * 100).toString();
let timeout = null;

const CustomInput = ({
  value,
  usageType,
  setter,
  apiurl,
  api,
  language,
  type,
  id,
  label,
  disabled,
  placeholder,
  pattern,
  required,
  optionListValue,
  field,
  radioValue,
  defaultValue,
  pricesetter,
  timeInterval,
  settimeInterval,
  trim,
  tolowercase,
  classNames,
  servercall,
  width,
  trimStart,
  price,
  description,
  leftIcon,
  rightIcon,
  crud,
  user,
}) => {
  const [showColorPicker, setshowColorPicker] = useState(false);
  const [bloko, setbloko] = useState("");
  const { lang } = useLang();
  const { colorScheme } = useMantineColorScheme();
  const Theme = useMantineTheme();
  const { permissions } = usePermissions();
  const [searchValue, setSearchValue] = useState("");

  const handleChange = async (e) => {
    let value = e.target?.value;
    if (trim) {
      value = value.trim();
    }
    if (tolowercase) value = value.toLowerCase();
    if (trimStart) value = value.trimStart();
    if (servercall && api && apiurl) {
      // Init a timeout variable to be used below
      clearTimeout(timeout);
      timeout = setTimeout(async function () {
        if (type === "email") {
          let res = await api.get(apiurl, {
            email: value,
          });
          if (res[0]) {
            Swal.fire(config.swal.emailIsBusy[language]);
            setter("");
            return;
          }
        } else {
          let res = await api.get(apiurl, {
            username: value,
          });
          if (res[0]) {
            Swal.fire(config.swal.usernameIsBusy[language]);
            setter("");
            return;
          }
        }
      }, 1000);
    }
    setter(value);
  };

  const handlePriceChange = (e) => {
    pricesetter(e?.target?.value);
  };

  const handleTimeIntervalChange = (e) => {
    settimeInterval(e?.target?.value);
  };

  const handleTextFieldChange = (data) => {
    setter(data);
  };

  const handleColorChange = (color) => {
    setter(color.hex);
  };

  const toggleShowColorPicker = () => {
    setshowColorPicker(!showColorPicker);
  };
  const editorRef = useRef(null);

  if (usageType === "optionlist") {
    console.log("optionListValue", optionListValue);
    console.log("value", value);
    console.log(
      "ooooooooooooo",
      optionListValue.filter(
        (item) => item.value === value || item._id === value
      )
    );
  }

  if ((crud && crud?.update) || user?.ananunaki) {
    if (usageType === "textfield") {
      return (
        <Input.Wrapper label={label}>
          <Input
            placeholder={placeholder}
            onChange={handleChange}
            value={value || ""}
            disabled={disabled}
            leftSection={leftIcon ? leftIcon : null}
            rightSection={rightIcon ? rightIcon : null}
          />
        </Input.Wrapper>
      );
    } else if (usageType === "idCard") {
      return (
        <Input.Wrapper label={label}>
          <Input
            placeholder={placeholder}
            onChange={handleChange}
            value={value || ""}
            disabled={disabled}
            leftSection={<IconIdBadge2 size={16} />}
          />
        </Input.Wrapper>
      );
    } else if (usageType === "bank") {
      return (
        <Input.Wrapper label={label}>
          <Input
            placeholder={placeholder}
            onChange={handleChange}
            value={value || ""}
            disabled={disabled}
            leftSection={<IconBuildingBank size={16} />}
          />
        </Input.Wrapper>
      );
    } else if (usageType === "email") {
      return (
        <Input.Wrapper label={label}>
          <Input
            placeholder={placeholder}
            onChange={handleChange}
            value={value || ""}
            leftSection={<IconAt size={16} />}
            disabled={disabled}
          />
        </Input.Wrapper>
      );
    } else if (usageType === "phone") {
      return (
        <Input.Wrapper label={label}>
          <Input
            placeholder={placeholder}
            onChange={handleChange}
            value={value || ""}
            leftSection={<IconPhone size={16} />}
            component={IMaskInput}
            mask="+374 (00) 00-00-00"
            disabled={disabled}
          />
        </Input.Wrapper>
      );
    } else if (usageType === "password") {
      return (
        <Input.Wrapper label={label}>
          <PasswordInput
            placeholder={placeholder}
            id="your-password"
            value={value || ""}
            onChange={handleChange}
            disabled={disabled}
          />
        </Input.Wrapper>
      );
    } else if (usageType === "textarea-simple") {
      return (
        <Textarea
          placeholder={placeholder}
          label={placeholder}
          autosize
          minRows={2}
          onChange={handleChange}
          value={value || ""}
        />
      );
    } else if (usageType === "textarea") {
      let lightEdotor = (
        <Input.Wrapper label={label}>
          <Editor
            id={lightEditorTiming}
            key={lightEditorTiming}
            disabled={disabled}
            onInit={(evt, editor) => (editorRef.current = editor)}
            init={{
              height: "50vh",
              width: "100%",
              strict_loading_mode: true,
              statusbar: false,
              branding: false,
              placeholder: placeholder,
              fontsize_formats: "8px 10px 12px 14px 18px 24px 36px",
              menubar: "file edit insert view format table",
              plugins: [
                "charmap advlist autolink lists link image charmap print preview anchor",
                "searchreplace visualblocks code fullscreen",
                "insertdatetime media table paste code help wordcount code",
              ],
              formatpainter_blacklisted_formats:
                "link,address,removeformat,formatpainter_removeformat",
              toolbar:
                // eslint-disable-next-line
                "charmap | undo redo | formatselect | bold italic backcolor | \
                    alignleft aligncenter alignright alignjustify | \
                    bullist numlist outdent indent | removeformat | help",
            }}
            value={value}
            onEditorChange={handleTextFieldChange}
          />
        </Input.Wrapper>
      );
      let darkEditor = (
        <Input.Wrapper label={label}>
          <Editor
            id={darkEditorTiming}
            key={darkEditorTiming}
            disabled={disabled}
            onInit={(evt, editor) => (editorRef.current = editor)}
            init={{
              skin: `oxide-dark`,
              content_css: `dark`,
              height: "50vh",
              width: "100%",
              strict_loading_mode: true,
              statusbar: false,
              branding: false,
              placeholder: placeholder,
              fontsize_formats: "8px 10px 12px 14px 18px 24px 36px",
              menubar: "file edit insert view format table",
              plugins: [
                "charmap advlist autolink lists link image charmap print preview anchor",
                "searchreplace visualblocks code fullscreen",
                "insertdatetime media table paste code help wordcount code",
              ],
              formatpainter_blacklisted_formats:
                "link,address,removeformat,formatpainter_removeformat",
              toolbar:
                // eslint-disable-next-line
                "charmap | undo redo | formatselect | bold italic backcolor | \
                    alignleft aligncenter alignright alignjustify | \
                    bullist numlist outdent indent | removeformat | help",
            }}
            value={value}
            onEditorChange={handleTextFieldChange}
          />
        </Input.Wrapper>
      );
      if (colorScheme === "light" && lastTheme === "light") return lightEdotor;
      else if (colorScheme === "dark" && lastTheme === "dark")
        return darkEditor;
      else if (colorScheme === "light" && lastTheme === "dark") {
        lastTheme = colorScheme;
        return lightEdotor;
      } else if (colorScheme === "dark" && lastTheme === "light") {
        lastTheme = colorScheme;
        return darkEditor;
      } else if (!lastTheme) {
        lastTheme = colorScheme;
        if (colorScheme === "light") return lightEdotor;
        else return darkEditor;
      }
    } else if (usageType === "optionlist") {
      if (optionListValue && Array.isArray(optionListValue)) {
        return (
          <Input.Wrapper label={label}>
            <Select
              placeholder={placeholder}
              disabled={disabled}
              searchable
              allowDeselect={false}
              data={optionListValue.flatMap((item) => ({
                label:
                  item.name?.[lang] ||
                  item.label?.[lang] ||
                  item.label ||
                  item.name ||
                  "no data provided 2",
                value:
                  item._id ||
                  item.value ||
                  item.name[lang] ||
                  item.title[lang] ||
                  item.name ||
                  item.title ||
                  "no data provided",
              }))} // data should be an array of objects with label and value
              value={Array.isArray(value) ? value[0] : value}
              onChange={setter}
              nothingFoundMessage="Nothing found..."
            />
          </Input.Wrapper>
        );
      }
      return null;
    } else if (usageType === "datePicker") {
      let localValue;
      if (value) {
        localValue = JSON.parse(JSON.stringify(value));
        localValue = new Date(localValue);
      } else {
        localValue = null;
      }
      return (
        <DateInput
          value={localValue}
          onChange={setter}
          valueFormat="DD/MM/YYYY"
          label={label}
          placeholder={placeholder}
          leftSection={<IconCalendar size={16} />}
          disabled={disabled}
        />
      );
    } else if (usageType === "number") {
      return (
        <NumberInput
          label={label}
          description={description}
          placeholder={placeholder}
          value={value}
          onChange={setter}
          disabled={disabled}
        />
      );
    } else if (usageType === "url") {
      return (
        <Input.Wrapper label={label}>
          <Input
            placeholder={placeholder}
            onChange={handleChange}
            value={value || ""}
            leftSection={<IconWorldWww size={16} />}
            component={IMaskInput}
            mask="https://www.***************************************************"
            disabled={disabled}
          />
        </Input.Wrapper>
      );
    } else if (usageType === "boolean") {
      return (
        <div key={id} className="form-element">
          <label htmlFor={label}>{label}</label>
          {value ? (
            <p>
              <i className="fas fa-thumbs-up"></i>
            </p>
          ) : (
            <p>
              <i className="fas fa-exclamation-circle"></i>
            </p>
          )}
        </div>
      );
    } else if (usageType === "color-picker") {
      return (
        <div key={id} className="form-element">
          <label htmlFor={label}>{label}</label>
          <div className="input-group">
            <div className="input-group-btn">
              <button
                className="btn btn-info btn-flat"
                onClick={toggleShowColorPicker}
              >
                Color Picker
              </button>
            </div>
            {/* <p >color test</p> */}
            <input
              value={value || ""}
              onChange={handleChange}
              type={type}
              className="form-control"
              id={label}
              placeholder={placeholder}
            />
            <div className="input-group-btn">
              <button
                className="btn btn-flat"
                style={{ backgroundColor: value }}
              >
                Color Example
              </button>
            </div>
          </div>
          {showColorPicker && <ChromePicker onChange={handleColorChange} />}
        </div>
      );
    } else if (usageType === "radio") {
      return (
        <Input.Wrapper label={label} key={new Date().getTime()}>
          <Card shadow="xs" padding="sm" radius="md">
            {/* <SegmentedControl
              size="xl"
              onChange={setter}
              data={radioValue && radioValue.length > 0 ? radioValue : []}
              value={value}
              // classNames={classes}
              style={{
                flexWrap: "wrap",
                width: "100%",
              }}
            /> */}
            <Radio.Group
              name={config.translate.workingStatus[lang]}
              onChange={(e) => setter(e.target.value)}
            >
              <Group mt="xs">
                {radioValue && radioValue.length > 0
                  ? radioValue.map((val, index) => {
                      return (
                        <Radio
                          key={index}
                          value={val.value}
                          label={val.label}
                          checked={true}
                        />
                      );
                    })
                  : null}
              </Group>
            </Radio.Group>
          </Card>
        </Input.Wrapper>
      );
    } else {
      return <h1 key={id}>There is no content to show in input</h1>;
    }
  } else {
    return (
      <Input.Wrapper label={label}>
        <Input
          placeholder={placeholder}
          value={
            usageType === "password"
              ? "********"
              : usageType === "textarea"
              ? parse(value ? value : "")
              : usageType === "idCard"
              ? value || ""
              : usageType === "bank"
              ? value || ""
              : usageType === "email"
              ? value || ""
              : usageType === "phone"
              ? "+374" + value || ""
              : usageType === "textarea-simple"
              ? parse(value ? value : "")
              : usageType === "datePicker"
              ? value
                ? dayjs(value).format("DD/MM/YYYY")
                : ""
              : usageType === "number"
              ? value || ""
              : usageType === "url"
              ? value || ""
              : usageType === "textfield"
              ? parse(value ? value : "")
              : usageType === "optionlist"
              ? value?.name?.[lang] ||
                value?.label?.[lang] ||
                value?.label ||
                value?.name ||
                value
                ? optionListValue.filter(
                    (item) => item.value === value || item._id === value
                  )[0]?.name?.[lang] ||
                  optionListValue.filter(
                    (item) => item.value === value || item._id === value
                  )[0]?.name ||
                  optionListValue.filter(
                    (item) => item.value === value || item._id === value
                  )[0]?.label?.[lang] ||
                  optionListValue.filter(
                    (item) => item.value === value || item._id === value
                  )[0]?.label ||
                  "no data provided"
                : value || "no data provided"
              : ""
          }
          disabled={true}
          leftSection={leftIcon ? leftIcon : null}
          rightSection={rightIcon ? rightIcon : null}
        />
      </Input.Wrapper>
    );
  }
};

export default CustomInput;
