import React, { useMemo } from "react";
import { ListPage } from "../ListPage";
import config from "../../config";
import { useUser } from "../../helpers/userContext";
import { useLang } from "../../helpers/language";
import parse from "html-react-parser";

export default function Departments() {
  const { user } = useUser();
  const { lang } = useLang();
  const columns = [
    {
      accessorKey: "index",
      permissionModel: "index",
      header: config.translate.index[lang],
      size: 30,
    },
    {
      accessorFn: (row) => `${row.name.en} ${row.name.ru} ${row.name.hy}`,
      permissionModel: "name",
      size: 420,
      grow: true,
      header: config.translate.name[lang],
      Cell: (
        RecievedObject,
        cell = RecievedObject.renderedCellValue,
        row = RecievedObject.row.original
      ) => {
        let result = parse(row.name[lang] ? row.name[lang] : "...");
        return result.length > 80 ? result.slice(0, 80) + "..." : result;
      },
    },
    {
      accessorFn: (row) =>
        `${row.leader?.name?.en} ${row.leader?.name?.ru} ${row.leader?.name?.hy}`,
      permissionModel: "leader",
      header: config.translate.leader[lang],
      Cell: (
        RecievedObject,
        cell = RecievedObject.renderedCellValue,
        row = RecievedObject.row.original
      ) => {
        let result = parse(
          row.leader
            ? row.leader.name[lang]
              ? row.leader.name[lang]
              : "..."
            : "no data"
        );
        return result.length > 20 ? result.slice(0, 20) + "..." : result;
      },
    },
  ];
  return (
    <ListPage
      route={config.api.department}
      query={{}}
      sorting={{ index: "asc" }}
      permissionModel={"department"}
      uploadType={true}
      limit={null}
      populate={["leader"]}
      columns={columns}
      enableFacetedValues={true}
    />
  );
}
