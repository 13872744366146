import React, { useState, useEffect } from "react";
import SinglePage from "../SinglePage";
import { useParams } from "react-router-dom";
import { useUser } from "../../helpers/userContext";
import * as API from "../../helpers/api";
import config from "../../config";
import Loading from "../modules/loading";
import { useLang } from "../../helpers/language";
import parse from "html-react-parser";
import { Image, useMantineColorScheme } from "@mantine/core";
import { parsePhoneNumber } from "../../helpers/parsePhoneNumber";
import NProgress from "nprogress";

export default function Voltage() {
  const [isBusy, setBusy] = useState(true);
  let id = useParams().id;
  const { user } = useUser();
  const route = config.api.voltage;
  const { lang } = useLang();
  const { colorScheme } = useMantineColorScheme();

  const [index, setindex] = useState("");
  const [nameEn, setNameEn] = useState("");
  const [nameRu, setNameRu] = useState("");
  const [nameHy, setNameHy] = useState("");
  const [deadline, setDeadline] = useState({
    project: 0,
    step1: 0,
    step2: 0,
    step3: 0,
    step4: 0,
    step5: 0,
    step6: 0,
    step7: 0,
    step8: 0,
    step9: 0,
    step10: 0,
    step11: 0,
    step12: 0,
    step13: 0,
    step14: 0,
    step15: 0,
    step16: 0,
    step17: 0,
    step18: 0,
    step19: 0,
  });

  const [usersStep2, setUsersStep2] = useState([]);
  const [usersStep4, setUsersStep4] = useState([]);
  const [usersStep11, setusersStep11] = useState([]);
  const [usersStep12, setusersStep12] = useState([]);
  const [usersStep13, setusersStep13] = useState([]);
  const [usersStep16, setusersStep16] = useState([]);
  const [usersStep17, setusersStep17] = useState([]);
  const [usersStep18, setusersStep18] = useState([]);
  const [usersStep19, setusersStep19] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [permissions, setPermissions] = useState([]);

  const columns = [
    {
      accessorFn: (row) =>
        `${row.firstName.en} ${row.firstName.ru} ${row.firstName.hy}`,
      header: config.translate.firstName[lang],
      filterVariant: "multi-select",
      Cell: (
        RecievedObject,
        cell = RecievedObject.renderedCellValue,
        row = RecievedObject.row.original
      ) => {
        let result = parse(row.firstName[lang] ? row.firstName[lang] : "...");
        return result.length > 20 ? result.slice(0, 20) + "..." : result;
      },
    },
    {
      accessorFn: (row) =>
        `${row.lastName.en} ${row.lastName.ru} ${row.lastName.hy}`,
      header: config.translate.lastName[lang],
      filterVariant: "multi-select",
      Cell: (
        RecievedObject,
        cell = RecievedObject.renderedCellValue,
        row = RecievedObject.row.original
      ) => {
        let result = parse(row.lastName[lang] ? row.lastName[lang] : "...");
        return result.length > 20 ? result.slice(0, 20) + "..." : result;
      },
    },
    {
      accessorKey: "phone",
      enableColumnActions: false,
      enableColumnDragging: false,
      header: config.translate.more[lang],
      Cell: (
        RecievedObject,
        cell = RecievedObject.renderedCellValue,
        row = RecievedObject.row.original
      ) => {
        let result = "";
        if (row.phone && row.phone.length > 0) {
          result = (
            <div
              className="icon-comment-wrapper"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <a href={`tel:${parsePhoneNumber(row.phone)}`}>
                <Image
                  w="20"
                  h="20"
                  className="icon-comment"
                  src="/assets/phone-call-svgrepo-com.svg"
                  alt="phone"
                  style={{ filter: colorScheme === "dark" ? "invert(1)" : "" }}
                />
              </a>
              <a href={`mailto:${row.email}`}>
                <Image
                  w="20"
                  h="20"
                  className="icon-comment"
                  src="/assets/email-svgrepo-com.svg"
                  alt="email"
                  style={{ filter: colorScheme === "dark" ? "invert(1)" : "" }}
                />
              </a>
              <a
                href={`https://chat.apit.am/direct/${row.chatID}`}
                target="_blank"
                rel="noreferrer"
              >
                <Image
                  w="20"
                  h="20"
                  className="icon-comment"
                  src="/assets/message-circle-chat-svgrepo-com.svg"
                  alt="chat"
                  style={{ filter: colorScheme === "dark" ? "invert(1)" : "" }}
                />
              </a>
            </div>
          );
        }
        return result;
      },
    },
  ];

  const modelSendToServer = {
    index,
    name: {
      en: nameEn,
      ru: nameRu,
      hy: nameHy,
    },
    deadline,
    users: [
      {
        users: usersStep2 ? usersStep2.map((user) => user._id) : [],
        step: 2,
      },
      {
        users: usersStep4 ? usersStep4.map((user) => user._id) : [],
        step: 4,
      },
      {
        users: usersStep11 ? usersStep11.map((user) => user._id) : [],
        step: 11,
      },
      {
        users: usersStep12 ? usersStep12.map((user) => user._id) : [],
        step: 12,
      },
      {
        users: usersStep13 ? usersStep13.map((user) => user._id) : [],
        step: 13,
      },
      {
        users: usersStep16 ? usersStep16.map((user) => user._id) : [],
        step: 16,
      },
      {
        users: usersStep17 ? usersStep17.map((user) => user._id) : [],
        step: 17,
      },
      {
        users: usersStep18 ? usersStep18.map((user) => user._id) : [],
        step: 18,
      },
      {
        users: usersStep19 ? usersStep19.map((user) => user._id) : [],
        step: 19,
      },
    ],
  };

  useEffect(() => {
    (async () => {
      NProgress.start();
      // all users
      let rawusers = await API.get(config.api.user, {
        workingStatus: "working",
      });
      setAllUsers(rawusers);

      if (id !== "add") {
        let currentItem = await API.get(
          route,
          {
            _id: id,
          },
          {},
          {},
          {},
          ["users.users"]
        );
        setindex(currentItem[0]?.index);
        setNameEn(currentItem[0]?.name?.en);
        setNameRu(currentItem[0]?.name?.ru);
        setNameHy(currentItem[0]?.name?.hy);
        setDeadline(currentItem[0]?.deadline);
        setUsersStep2(currentItem[0]?.users[0]?.users);
        setUsersStep4(currentItem[0]?.users[1]?.users);
        setusersStep11(currentItem[0]?.users[2]?.users);
        setusersStep12(currentItem[0]?.users[3]?.users);
        setusersStep13(currentItem[0]?.users[4]?.users);
        setusersStep16(currentItem[0]?.users[5]?.users);
        setusersStep17(currentItem[0]?.users[6]?.users);
        setusersStep18(currentItem[0]?.users[7]?.users);
        setusersStep19(currentItem[0]?.users[8]?.users);
      }
      setBusy(false);
    })();
    // eslint-disable-next-line
  }, [isBusy]);
  useEffect(() => {
    let tempPermissions = [];
    user?.role?.permissions?.forEach((permission) => {
      if (permission.name === "ticket") {
        permission.inputs.forEach((input) => {
          tempPermissions.push(input);
        });
      }
    });
    setPermissions([...tempPermissions]);
  }, []);
  if (isBusy) return <Loading />;
  else
    return (
      <SinglePage
        isBusy={isBusy}
        setBusy={setBusy}
        route={route}
        id={id}
        permissionModel="voltage"
        modelSendToServer={modelSendToServer}
        permissions={permissions}
        inputs={[
          {
            value: index,
            setter: setindex,
            type: "number",
            usageType: "number",
            label: config.translate.index[lang],
            placeholder: config.translate.index[lang],
            permissionModel: "index",
          },
          {
            value: nameEn,
            setter: setNameEn,
            type: "text",
            usageType: "textfield",
            label: config.translate.name[lang],
            placeholder: config.translate.inputInLatin[lang],
            lang: "en",
            permissionModel: "name",
          },
          {
            value: nameRu,
            setter: setNameRu,
            type: "text",
            usageType: "textfield",
            label: config.translate.name[lang],
            placeholder: config.translate.inputInRussian[lang],
            lang: "ru",
            permissionModel: "name",
          },
          {
            value: nameHy,
            setter: setNameHy,
            type: "text",
            usageType: "textfield",
            label: config.translate.name[lang],
            placeholder: config.translate.inputInArmenian[lang],
            lang: "hy",
            permissionModel: "name",
          },
          {
            value: deadline?.project,
            setter: (e) => setDeadline({ ...deadline, project: e }),
            type: "number",
            usageType: "number",
            label: config.translate.projectDeadline[lang],
            placeholder: config.translate.projectDeadline[lang],
            permissionModel: "deadline",
          },
          {
            value: deadline?.step1,
            setter: (e) => setDeadline({ ...deadline, step1: e }),
            type: "number",
            usageType: "number",
            label:
              config.steps[0].name[lang] +
              " " +
              config.translate.deadline[lang],
            permissionModel: "deadline",
          },
          {
            value: deadline?.step2,
            setter: (e) => setDeadline({ ...deadline, step2: e }),
            type: "number",
            usageType: "number",
            label:
              config.steps[1].name[lang] +
              " " +
              config.translate.deadline[lang],
            permissionModel: "deadline",
          },
          {
            value: deadline?.step3,
            setter: (e) => setDeadline({ ...deadline, step3: e }),
            type: "number",
            usageType: "number",
            label:
              config.steps[2].name[lang] +
              " " +
              config.translate.deadline[lang],
            permissionModel: "deadline",
          },
          {
            value: deadline?.step4,
            setter: (e) => setDeadline({ ...deadline, step4: e }),
            type: "number",
            usageType: "number",
            label:
              config.steps[3].name[lang] +
              " " +
              config.translate.deadline[lang],
            permissionModel: "deadline",
          },
          {
            value: deadline?.step5,
            setter: (e) => setDeadline({ ...deadline, step5: e }),
            type: "number",
            usageType: "number",
            label:
              config.steps[4].name[lang] +
              " " +
              config.translate.deadline[lang],
            permissionModel: "deadline",
          },
          {
            value: deadline?.step6,
            setter: (e) => setDeadline({ ...deadline, step6: e }),
            type: "number",
            usageType: "number",
            label:
              config.steps[5].name[lang] +
              " " +
              config.translate.deadline[lang],
            permissionModel: "deadline",
          },
          {
            value: deadline?.step7,
            setter: (e) => setDeadline({ ...deadline, step7: e }),
            type: "number",
            usageType: "number",
            label:
              config.steps[6].name[lang] +
              " " +
              config.translate.deadline[lang],
            permissionModel: "deadline",
          },
          {
            value: deadline?.step8,
            setter: (e) => setDeadline({ ...deadline, step8: e }),
            type: "number",
            usageType: "number",
            label:
              config.steps[7].name[lang] +
              " " +
              config.translate.deadline[lang],
            permissionModel: "deadline",
          },
          {
            value: deadline?.step9,
            setter: (e) => setDeadline({ ...deadline, step9: e }),
            type: "number",
            usageType: "number",
            label:
              config.steps[8].name[lang] +
              " " +
              config.translate.deadline[lang],
            permissionModel: "deadline",
          },
          {
            value: deadline?.step10,
            setter: (e) => setDeadline({ ...deadline, step10: e }),
            type: "number",
            usageType: "number",
            label:
              config.steps[9].name[lang] +
              " " +
              config.translate.deadline[lang],
            permissionModel: "deadline",
          },
          {
            value: deadline?.step11,
            setter: (e) => setDeadline({ ...deadline, step11: e }),
            type: "number",
            usageType: "number",
            label:
              config.steps[10].name[lang] +
              " " +
              config.translate.deadline[lang],
            permissionModel: "deadline",
          },
          {
            value: deadline?.step12,
            setter: (e) => setDeadline({ ...deadline, step12: e }),
            type: "number",
            usageType: "number",
            label:
              config.steps[11].name[lang] +
              " " +
              config.translate.deadline[lang],
            permissionModel: "deadline",
          },
          {
            value: deadline?.step13,
            setter: (e) => setDeadline({ ...deadline, step13: e }),
            type: "number",
            usageType: "number",
            label:
              config.steps[12].name[lang] +
              " " +
              config.translate.deadline[lang],
            permissionModel: "deadline",
          },
          {
            value: deadline?.step14,
            setter: (e) => setDeadline({ ...deadline, step14: e }),
            type: "number",
            usageType: "number",
            label:
              config.steps[13].name[lang] +
              " " +
              config.translate.deadline[lang],
            permissionModel: "deadline",
          },
          {
            value: deadline?.step15,
            setter: (e) => setDeadline({ ...deadline, step15: e }),
            type: "number",
            usageType: "number",
            label:
              config.steps[14].name[lang] +
              " " +
              config.translate.deadline[lang],
            permissionModel: "deadline",
          },
          {
            value: deadline?.step16,
            setter: (e) => setDeadline({ ...deadline, step16: e }),
            type: "number",
            usageType: "number",
            label:
              config.steps[15].name[lang] +
              " " +
              config.translate.deadline[lang],
            permissionModel: "deadline",
          },
          {
            value: deadline?.step17,
            setter: (e) => setDeadline({ ...deadline, step17: e }),
            type: "number",
            usageType: "number",
            label:
              config.steps[16].name[lang] +
              " " +
              config.translate.deadline[lang],
            permissionModel: "deadline",
          },
          {
            value: deadline?.step18,
            setter: (e) => setDeadline({ ...deadline, step18: e }),
            type: "number",
            usageType: "number",
            label:
              config.steps[17].name[lang] +
              " " +
              config.translate.deadline[lang],
            permissionModel: "deadline",
          },
          {
            value: deadline?.step19,
            setter: (e) => setDeadline({ ...deadline, step19: e }),
            type: "number",
            usageType: "number",
            label:
              config.steps[18].name[lang] +
              " " +
              config.translate.deadline[lang],
            permissionModel: "deadline",
          },
        ]}
        cartComponent={[
          {
            placeholder:
              config.translate.users[lang] +
              " - " +
              config.steps[1].name[lang] +
              " step2",
            value: usersStep2,
            setter: setUsersStep2,
            data: allUsers,
            columns: columns,
            width: "220px",
            permissionModel: "users",
          },
          {
            placeholder:
              config.translate.users[lang] +
              " - " +
              config.steps[3].name[lang] +
              " step4",
            value: usersStep4,
            setter: setUsersStep4,
            data: allUsers,
            columns: columns,
            width: "220px",
          },
          {
            placeholder:
              config.translate.users[lang] +
              " - " +
              config.steps[10].name[lang] +
              " step11",
            value: usersStep11,
            setter: setusersStep11,
            data: allUsers,
            columns: columns,
            width: "220px",
            permissionModel: "users",
          },
          {
            placeholder:
              config.translate.users[lang] +
              " - " +
              config.steps[11].name[lang] +
              " step12",
            value: usersStep12,
            setter: setusersStep12,
            data: allUsers,
            columns: columns,
            width: "220px",
            permissionModel: "users",
          },
          {
            placeholder:
              config.translate.users[lang] +
              " - " +
              config.steps[12].name[lang] +
              " step13",
            value: usersStep13,
            setter: setusersStep13,
            data: allUsers,
            columns: columns,
            width: "220px",
            permissionModel: "users",
          },
          {
            placeholder:
              config.translate.users[lang] +
              " - " +
              config.steps[15].name[lang] +
              " step16",
            value: usersStep16,
            setter: setusersStep16,
            data: allUsers,
            columns: columns,
            width: "220px",
            permissionModel: "users",
          },
          {
            placeholder:
              config.translate.users[lang] +
              " - " +
              config.steps[16].name[lang] +
              " step17",
            value: usersStep17,
            setter: setusersStep17,
            data: allUsers,
            columns: columns,
            width: "220px",
            permissionModel: "users",
          },
          {
            placeholder:
              config.translate.users[lang] +
              " - " +
              config.steps[17].name[lang] +
              " step18",
            value: usersStep18,
            setter: setusersStep18,
            data: allUsers,
            columns: columns,
            width: "220px",
            permissionModel: "users",
          },
          {
            placeholder:
              config.translate.users[lang] +
              " - " +
              config.steps[18].name[lang] +
              " step19",
            value: usersStep19,
            setter: setusersStep19,
            data: allUsers,
            columns: columns,
            width: "220px",
            permissionModel: "users",
          },
        ]}
      />
    );
}
