import React, { useMemo } from "react";
import { ListPage } from "../ListPage";
import config from "../../config";
import { useUser } from "../../helpers/userContext";
import { useLang } from "../../helpers/language";
import parse from "html-react-parser";
import {
  Avatar,
  Box,
  Button,
  Flex,
  Menu,
  Text,
  Title,
  Table,
  Input,
  Checkbox,
} from "@mantine/core";

export default function Permissions() {
  const { user } = useUser();
  const { lang } = useLang();

  const columns = [
    {
      accessorKey: "name",
      header: "Name",
      permissionModel: "name",
      enableClickToCopy: true,
      Cell: (
        RecievedObject,
        cell = RecievedObject.renderedCellValue,
        row = RecievedObject.row.original
      ) => {
        let result = parse(row.name ? row.name : "...");
        return result;
      },
    },
    {
      accessorKey: "crud.create",
      header: "Create",
      permissionModel: "create",
      Cell: (
        RecievedObject,
        cell = RecievedObject.renderedCellValue,
        row = RecievedObject.row.original
      ) => {
        let result = parse(
          row.crud && row.crud.create ? row.crud.create : "no description"
        );
        return result;
      },
    },
    {
      accessorKey: "crud.read",
      header: "Read",
      permissionModel: "read",
      Cell: (
        RecievedObject,
        cell = RecievedObject.renderedCellValue,
        row = RecievedObject.row.original
      ) => {
        let result = parse(
          row.crud && row.crud.read ? row.crud.read : "no description"
        );
        return result;
      },
    },
    {
      accessorKey: "crud.update",
      header: "Update",
      permissionModel: "update",
      Cell: (
        RecievedObject,
        cell = RecievedObject.renderedCellValue,
        row = RecievedObject.row.original
      ) => {
        let result = parse(
          row.crud && row.crud.update ? row.crud.update : "no description"
        );
        return result;
      },
    },
    {
      accessorKey: "crud.delete",
      header: "Delete",
      permissionModel: "delete",
      Cell: (
        RecievedObject,
        cell = RecievedObject.renderedCellValue,
        row = RecievedObject.row.original
      ) => {
        let result = parse(
          row.crud && row.crud.delete ? row.crud.delete : "no description"
        );
        return result;
      },
    },
  ];

  let renderDetailPanel = ({ row }) => {
    return (
      <Box
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: "16px",
          padding: "16px",
        }}
      >
        <Table>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Name</Table.Th>
              <Table.Th>Create</Table.Th>
              <Table.Th>Read</Table.Th>
              <Table.Th>Update</Table.Th>
              <Table.Th>Delete</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {row.original?.inputs?.map((input, index) => {
              return (
                <Table.Tr key={index} mt={5} mb={5}>
                  <Table.Td>{input.name}</Table.Td>
                  <Table.Td>
                    {input.create !== "undefined" && (
                      <Checkbox checked={input.create} />
                    )}
                  </Table.Td>
                  <Table.Td>
                    <Checkbox checked={input.read} />
                  </Table.Td>
                  <Table.Td>
                    <Checkbox checked={input.update} />
                  </Table.Td>
                  <Table.Td>
                    {input.delete !== "undefined" && (
                      <Checkbox checked={input.delete} />
                    )}
                  </Table.Td>
                </Table.Tr>
              );
            })}
          </Table.Tbody>
        </Table>
      </Box>
    );
  };

  return (
    <ListPage
      route={config.api.permission}
      query={{}}
      sorting={{ ip: "asc" }}
      permissionModel={"permission"}
      uploadType={true}
      limit={null}
      columns={columns}
      renderDetailPanel={renderDetailPanel}
      enableRowActions={false}
    />
  );
}
