import React, { useState, useEffect } from "react";
import SinglePage from "../SinglePage";
import { useParams } from "react-router-dom";
import * as API from "../../helpers/api";
import config from "../../config";
import Loading from "../modules/loading";
import { useLang } from "../../helpers/language";
import { useUser } from "../../helpers/userContext";
import NProgress from "nprogress";

export default function SMB() {
  const [isBusy, setBusy] = useState(true);
  let id = useParams().id;
  const route = config.api.smb;
  const { lang } = useLang();
  const { user } = useUser();

  const [index, setindex] = useState("");
  const [name, setName] = useState("");
  const [permissions, setPermissions] = useState([]);

  const modelSendToServer = {
    index,
    name,
  };

  useEffect(() => {
    (async () => {
      NProgress.start();
      if (id !== "add") {
        let currentItem = await API.get(route, {
          _id: id,
        });
        setindex(currentItem[0]?.index);
        setName(currentItem[0]?.name);
      }
      setBusy(false);
    })();
    // eslint-disable-next-line
  }, [isBusy]);

  useEffect(() => {
    let tempPermissions = [];
    user?.role?.permissions?.forEach((permission) => {
      if (permission.name === "ticket") {
        permission.inputs.forEach((input) => {
          tempPermissions.push(input);
        });
      }
    });
    setPermissions([...tempPermissions]);
  }, []);

  if (isBusy) return <Loading />;
  else
    return (
      <SinglePage
        isBusy={isBusy}
        setBusy={setBusy}
        route={route}
        permissionModel="smb"
        id={id}
        permissions={permissions}
        modelSendToServer={modelSendToServer}
        inputs={[
          {
            value: index,
            setter: setindex,
            type: "number",
            usageType: "number",
            label: config.translate.index[lang],
            placeholder: "Index",
            permissionModel: "index",
          },
          {
            value: name,
            setter: setName,
            type: "text",
            usageType: "textfield",
            label: "Name",
            placeholder: config.translate.inputInLatin[lang],
            permissionModel: "name",
          },
        ]}
      />
    );
}
