import React, { useState } from "react";
import {
  Box,
  Button,
  Collapse,
  Group,
  ScrollArea,
  Spoiler,
  Stepper,
  rem,
  useMantineTheme,
  Indicator,
  useMantineColorScheme,
} from "@mantine/core";
import {
  IconCheck,
  IconChecks,
  IconCircleCheck,
  IconUserCheck,
} from "@tabler/icons-react";
import config from "../../../config";

function CustomStepper({
  localSteps,
  activeStep,
  handleStepChange,
  step,
  lang,
  user,
  modelSendToServer,
  permissions,
  toggleInner,
  openedInner,
}) {
  const theme = useMantineTheme();
  let firstTimeClickedOnStepButton = 0;

  return (
    <Stepper
      active={activeStep - 1}
      onStepClick={(selectedStepIndex) => {
        handleStepChange(localSteps[selectedStepIndex]);
        if (step === selectedStepIndex + 1) {
          if (firstTimeClickedOnStepButton === 0) {
            firstTimeClickedOnStepButton = 1;
            toggleInner.toggle();
          } else if (firstTimeClickedOnStepButton === 1) toggleInner.toggle();
        } else {
          firstTimeClickedOnStepButton = 0;
          toggleInner.open();
        }
      }}
      w={"100%"}
      radius="md"
      styles={{
        stepBody: {
          display: "none",
        },
        step: {
          padding: "1px",
          borderRadius: rem(10),
        },
        stepIcon: {
          borderWidth: rem(1),
        },
        separator: {
          marginLeft: "1vw",
          marginRight: "1vw",
          height: rem(3),
          width: rem(10),
        },
      }}
      completedIcon={
        activeStep === localSteps.length ? <IconChecks /> : <IconCheck />
      }
      errorIcon={<IconCircleCheck />}
    >
      {localSteps.map((localStep, index) => {
        let stepDoneColor = theme.colors.green[6];
        let stepDoneSelected = theme.colors.blue[7];
        let CompletedIcon = <>{index + 1}</>;

        if (
          modelSendToServer.steps?.[index]?.bypass &&
          modelSendToServer.steps?.[index]?.history?.length > 0
        ) {
          stepDoneColor = theme.colors.orange[5];
          if (
            modelSendToServer.steps?.[index]?.history?.slice(-1)[0]?.status ===
            "inProgress"
          ) {
            stepDoneColor = theme.colors.orange[7];
            CompletedIcon = (
              <Indicator offset={-10} inline processing color="red" size={12}>
                {index + 1}
              </Indicator>
            );
          }
        } else if (modelSendToServer.steps?.[index]?.history?.length > 0) {
          if (
            modelSendToServer.steps?.[index]?.history?.slice(-1)[0]?.status ===
            "inProgress"
          ) {
            stepDoneColor = theme.colors.blue[7];
            CompletedIcon = (
              <Indicator offset={-10} inline processing color="red" size={12}>
                {index + 1}
              </Indicator>
            );
          }
        }

        return permissions?.map((input) => {
          if ("step" + localStep._id === input.name) {
            if (input.create || input.update || input.delete || input.read) {
              if (step === index + 1) {
                return (
                  <Stepper.Step
                    key={index}
                    icon={CompletedIcon}
                    completedIcon={CompletedIcon}
                    label={`${config.translate.step[lang]} - ${localStep._id}`}
                    description={localStep.name[lang]}
                    styles={{
                      step: {
                        backgroundColor: stepDoneSelected,
                      },
                    }}
                  />
                );
              } else {
                return (
                  <Stepper.Step
                    key={index}
                    color={stepDoneColor}
                    icon={CompletedIcon}
                    completedIcon={CompletedIcon}
                    label={`${config.translate.step[lang]} - ${localStep._id}`}
                    description={localStep.name[lang]}
                  />
                );
              }
            }
          }
        });
      })}
    </Stepper>
  );
}

export default CustomStepper;
