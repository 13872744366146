import React, { useMemo } from "react";
import { ListPage } from "../ListPage";
import config from "../../config";
import { useUser } from "../../helpers/userContext";
import { useLang } from "../../helpers/language";
import parse from "html-react-parser";

export default function Positions() {
  const { user } = useUser();
  const { lang } = useLang();
  const columns = [
    {
      accessorKey: "index",
      permissionModel: "index",
      header: "Index",
      size: 50,
      grow: false,
    },
    {
      accessorFn: (row) => `${row.name.en} ${row.name.ru} ${row.name.hy}`,
      permissionModel: "name",
      grow: true,
      header: config.translate.name[lang],
      Cell: (
        RecievedObject,
        cell = RecievedObject.renderedCellValue,
        row = RecievedObject.row.original
      ) => {
        let result = parse(row.name[lang] && row.name[lang]);
        return result;
      },
    },
  ];
  return (
    <ListPage
      route={config.api.position}
      query={{}}
      sorting={{ index: "asc" }}
      permissionModel={"position"}
      uploadType={true}
      limit={null}
      columns={columns}
      pageSize={100}
      enableFacetedValues={true}
    />
  );
}
